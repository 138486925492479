import {grey} from "@mui/material/colors";
import {Grid, IconButton} from "@mui/material";
import {PropsWithChildren} from "react";
import {css} from "@emotion/css";
import {Item, RefreshRouteItems} from "./RouteItems";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";

export function RouteItemRow(props: PropsWithChildren<{
    icon: any;
    title: any;
    item: Item | null;
    next: Item | null;
    prev: Item | null;
}>) {
    const snack = useSnackbar();

    return (
        <div style={{fontSize: "0.8rem"}}>
            <div style={{paddingLeft: 12, backgroundColor: grey["100"], paddingBottom: 4, paddingTop: 4}}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <div style={{fontSize: "1.1rem", height: "1em", overflow: "hidden"}}>
                            {props.icon}
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div style={{paddingBottom: 4, paddingTop: 4}}>
                            {props.title}
                        </div>
                    </Grid>
                    <Grid item>
                        {props.prev && <IconButton size="small" onClick={async () => {
                            if(!props.item) return;
                            if(!props.prev) return;

                            let maxSortOrder = props.prev.children[0].sortOrder || 0;

                            try {
                                await api.logistics.updateRouteItems(props.item.children.map(c => {
                                    return Object.assign({}, c, {
                                        sortOrder: maxSortOrder - 1,
                                    })
                                }))
                                RefreshRouteItems.emit(null);
                            } catch (e: any) {
                                snack.error(e.toString());
                            }
                        }}>
                            <KeyboardArrowUp />
                        </IconButton>}
                        {props.next && <IconButton size="small" onClick={async () => {
                            if(!props.item) return;
                            if(!props.next) return;

                            let minSortOrder = props.next.children[props.next.children.length-1].sortOrder || 0;

                            try {
                                await api.logistics.updateRouteItems(props.item.children.map(c => {
                                    return Object.assign({}, c, {
                                        sortOrder: minSortOrder + 1,
                                    })
                                }))
                                RefreshRouteItems.emit(null);
                            } catch (e: any) {
                                snack.error(e.toString());
                            }
                        }}>
                            <KeyboardArrowDown />
                        </IconButton>}
                    </Grid>
                </Grid>
            </div>
            <div style={{paddingLeft: 37}} className={rowItemsClass}>
                {props.children}
            </div>
        </div>
    )
}

const rowItemsClass = css({
    "& table": {
        width: "100%",
        "& td:nth-child(1)": {
            width: 200,
        },
        "& td:nth-child(2)": {
            width: 80,
            textAlign: "right",
        },
        "& td:nth-child(4)": {
            textAlign: "right",

            "& .actions": {
                display: "inline-flex",
                height: 16,
                alignItems: "center",
            }
        },
    }
})