import {Delivery, DeliveryItem, PurchaseOrder, PurchaseOrderItem} from "../../../api/Logistics";
import {forwardRef} from "react";
import {Page, pdfTableStyle} from "../../../misc/pdf/parts/Page";
import moment from "moment";
import {DataSplitInfo, PageRenderProvider} from "../../../misc/pdf/parts/PageContext";
import {PDF} from "../../../misc/pdf/parts/PDF";
import {Project} from "../../../api/Projects";
import {table} from "../../../misc/pdf/parts/TableStyle";
import {PDFHeader} from "../../../misc/pdf/parts/PDFHeader";
import {useReadyCheck} from "../../../misc/pdf/UseReadyCheck";
import {buildSections, Row, usePickingSlipData} from "./PickingSlipPDF";

export const PackingSlipPDF = forwardRef(function PackingSlipPDF(props: {
    value: Delivery | PurchaseOrder;
    items: (DeliveryItem | PurchaseOrderItem)[]
    project: Project;
    onReady(): void;
}, ref: any) {
    const rows = usePickingSlipData({
        items: props.items,
    })

    const ready = useReadyCheck(props.onReady)

    return (
        <div ref={ref}>
            <PDF>
                <PageRenderProvider data={rows} onReady={ready.section()}>
                    {(info: DataSplitInfo<Row>) => {

                        const rowsByHeader = buildSections(info.rows)

                        return (
                            <Page orientation="portrait">
                                <div style={{flex: 1}}>
                                {info.pageIndex === 0 && <>
                                    <PDFHeader noAddress title="Packing Slip"/>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "0.8rem",
                                        width: "100%"
                                    }}>
                                        <table className={table}>
                                            <tr>
                                                <td>Project</td>
                                            </tr>
                                            <tr>
                                                <td>{props.project.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Ship To</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div>{props.project.addressStreet}</div>
                                                    <div>{props.project.addressCity}, {props.project.addressProvince}</div>
                                                    <div>{props.project.addressPostal}</div>
                                                </td>
                                            </tr>
                                        </table>
                                        <div>
                                            <table className={table}>
                                                <tr>
                                                <td>Delivery Date</td>
                                                    <td>S.O. No.</td>
                                                </tr>
                                                <tr>
                                                    <td>{moment().format("MM/DD/YYYY")}</td>
                                                    <td>{props.value.id}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Site Contact
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div>{props.project.onsiteContactName}</div>
                                                        <div>{props.project.onsiteContactPhone}</div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div style={{height: 16}}/>
                                </>}

                                {rowsByHeader.map(section => <>
                                    <div style={{height: 16}}/>
                                    <table className={pdfTableStyle}>
                                        <thead>
                                        <tr>
                                            <th style={{textAlign: "left"}} colSpan={3}>{section.header}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {section.rows.map((i, index) => {
                                            if (i.type === "header") {
                                                return null;
                                            }

                                            const qtyWidth = 60;

                                            if(!i.openings) {
                                                return (
                                                    <tr key={index.toString()}>
                                                        <td style={{textAlign: "right", width: qtyWidth}}>{i.qty}</td>
                                                        <td colSpan={2}>{i.description}</td>
                                                    </tr>
                                                )
                                            }

                                            return (
                                                <tr key={index.toString()}>
                                                    <td style={{textAlign: "right", width: qtyWidth}}>{i.qty}</td>
                                                    <td>{i.description}</td>
                                                    <td>{i.openings.join(", ")}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </>)}

                                {info.isLastPage && "customerNote" in props.value && <div>
                                    <div style={{marginTop: 30, fontWeight: "700"}}>Notes</div>
                                    <p style={{whiteSpace: "pre-wrap", lineHeight: "1.4em", margin: 0}}>
                                        {props.value.customerNote || <>&nbsp;</>}
                                    </p>

                                </div>}

                                {info.isLastPage && <div style={{paddingTop: 15, display: "flex", justifyContent: "flex-end"}}>
                                    <div style={{width: 400, height: 80, border: "2px solid black", position: "relative"}}>
                                        <div style={{position: "absolute", top: -16, left: 12, padding: 8, paddingTop: 4, backgroundColor: "white"}}>Signature</div>
                                    </div>
                                </div>}
                            </div>
                        </Page>)
                }}
            </PageRenderProvider>
            </PDF>
        </div>
    )
});