import {Checkbox} from "@mui/material";
import {css} from "@emotion/css";
import {deepOrange, grey} from "@mui/material/colors";
import {Status} from "./ReceivePO";

export type TItem = {
    product: number;
    qty: number;
    code: string;
    description: string
    dimensions?: string;
    toWarehouse?: boolean
}

export function ReceiveItem<T extends TItem>(props: {
    value?: Status | null;
    item: T
    pickup?: boolean;
    onStatusChange?(fx: Status): void;
    onEdit?(item: T): void;
}) {
    const {item, value} = props;

    return (
        <tr className={rowStyle + " " + (!props.onEdit ? disabledStyle : "")}
            onContextMenu={e => {
                e.preventDefault();
                if(props.onEdit) props.onEdit(item);
            }}
            onClick={() => {
                if(!props.onStatusChange) return;

                if(!value) {
                    props.onStatusChange({
                        product: item.product,
                        productName: item.description,
                        status: "ok",
                        received: item.qty,
                    });
                } else if(value.status === "ok") {
                    if(props.pickup) {
                        props.onStatusChange(Object.assign({}, value, {
                            status: "back-order",
                            received: 0,
                            damaged: null,
                        }));
                    } else {
                        props.onStatusChange(Object.assign({}, value, {
                            status: "damaged",
                            received: 0,
                            damaged: props.item.qty,
                        }));
                    }
                } else {
                    props.onStatusChange({
                        product: props.item.product,
                        productName: item.description,
                        status: "ok",
                        received: item.qty,
                    });
                }
            }}>
            <td>
                <div>{item.description}</div>
                <div>{item.code || item.dimensions}</div>
                {value?.status === "back-order" && <div className={itemDetails}>Received {value.received} ({item.qty - value.received} on back order)</div>}
                {value?.status === "damaged" && <div className={itemDetails}>{value.received} correct, {value.damaged} damaged </div>}
            </td>
            <td style={{textAlign: "right", width: 40}}>
                <div style={{width: 40}}>
                    {item.qty}
                </div>
            </td>
            <td style={{width: 54}}>
                <div style={{width: 54}}>
                    {!!props.onStatusChange && <>
                        {(!value || value?.status === "ok") ?
                            <Checkbox checked={value?.status === "ok" || false} /> :
                            <Checkbox color="error" indeterminate={true} />
                        }
                    </>}
                </div>
            </td>
        </tr>
    )
}

const itemDetails = css({
    fontSize: "0.9rem",
    color: deepOrange["700"],
})

const rowStyle = css({
    fontSize: "1.3rem",

    "&:nth-child(even)": {
        backgroundColor: grey["100"],
    },

    "& > td": {
        paddingTop: 4,
        paddingBottom: 4,
    },

    "& > td:nth-child(1)": {
        paddingLeft: 4,
        paddingRight: 4,
    }
})

const disabledStyle = css({
    backgroundColor: grey["100"],
})