import {AutoComplete} from "../openings/AutoComplete";
import {api} from "../../../../api/API";
import {Opening} from "../../../../api/Openings";
import {useProjectId} from "../ProjectName";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {useIsQuote} from "../../quote/QuoteContainer";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {useAlternative} from "../../quote/alternative/Alternative";
import {contains} from "nate-react-api-helpers";

export function OpeningLookup(props: {
    onSelect(value: Opening|QuoteOpening): void;
    ignore: number[]
    backgroundColor?: string;
    hwGroup: number;
}) {
    const project = useProjectId();
    const isQuote = useIsQuote();
    const alternative = useAlternative();

    if(!props.hwGroup) {
        return <div style={{fontWeight: "normal", color: grey["600"]}}>Save group to add more openings</div>
    }

    return (
        <AutoComplete<Opening|QuoteOpening>
            search={async (search) => {
                if(isQuote) {
                    const rs = await api.quoteOpenings.list({
                        project: project,
                        type: "add-hw-find-opening-number",
                        search: search,
                        alternative: alternative,
                        hwGroup: props.hwGroup,
                    })

                    return rs.data.filter(d => !contains(props.ignore, d.id))
                }

                const rs = await api.openings.list({
                    project: project,
                    type: "add-hw-find-opening-number",
                    hwGroup: props.hwGroup,
                    search: search,
                })

                return rs.data.filter(d => !contains(props.ignore, d.id))
            }}
            className={lookupClass}
            popperClassName={popperClass}
            value={null}
            equals={(a, b) => a.id === b.id}
            makeLabel={a => a.name}
            onSelect={props.onSelect}
            useNativeInput
            placeholder="Enter opening number to add to group"
            style={{
                backgroundColor: props.backgroundColor,
            }}
        />
    )
}

const popperClass = css({
    "& .MuiMenuItem-root": {
        fontSize: "0.8rem",
    }
})

const lookupClass = css({
    fontSize: "0.8rem",
    marginLeft: -4,

    "&::placeholder": {
        color: grey["500"],
        fontStyle: "italic",
    }
})