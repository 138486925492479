import {Option} from "./scroller/Table";
import {useEffect, useRef, useState} from "react";
import {first, useAsync2} from "nate-react-api-helpers";
import {Popover} from "./Popover";
import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Clear from "@mui/icons-material/Clear";
import {css} from "@emotion/css";
import {Paginated} from "../api/Users";

export function select2list(list: Option[]) {
    return async (input: string) => {
        if(!input) return list;

        return list.filter(l => l.display.toLowerCase().indexOf(input.toLowerCase()) === 0);
    }
}

export function paginated2options<T>(display: keyof T, value: keyof T, callback: (search: string) => Promise<Paginated<T>>) {
    return async (search: string) => {
        const list = await callback(search);
        return list.data.map(d => ({
            display: d[display],
            value: d[value],
        }) as any)
    }
}

export function Select2(props: {
    label: string;
    lookup: (input: string) => Promise<Option[]>
    initialValue: string;
    initialDisplay?: string;
    size?: any;
    disabled?: boolean
    onChange(value: Option): void;
}) {
    const [anchor, setAnchor] = useState<HTMLDivElement|null>(null);
    const [open, setOpen] = useState(false);

    const [display, setDisplay] = useState(props.initialDisplay || "");

    const {initialValue, initialDisplay} = props;
    const lookupRef = useRef(props.lookup);

    useEffect(() => {
        if(initialDisplay) return;
        let cancelled = false;

        const update = async () => {
            const list = await lookupRef.current("")
            if(cancelled) return;

            setDisplay(first(list, l => l.value === initialValue)?.display || "")
        }

        update();

        return () => {
            cancelled = true;
        }
    }, [initialValue, initialDisplay])

    return (
        <div ref={setAnchor}>
            <TextField disabled={props.disabled} fullWidth size={props.size || "small"} label={props.label} value={display}
                onClick={() => {
                    if(props.disabled) return;
                    setOpen(true)
                }} style={{cursor: "pointer"}}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton disabled={props.disabled} onClick={() => setOpen(!open)}>
                            <ArrowDropDown />
                        </IconButton>
                    </InputAdornment>
                }} />
            <Select2Popout open={open} lookup={props.lookup} onChange={props.onChange}
                           onClose={() => setOpen(false)} initialValue={props.initialValue} anchor={anchor} />
        </div>
    )
}

export function Select2Popout(props: {
    open: boolean;
    lookup: (input: string) => Promise<Option[]>
    onChange(value: Option): void;
    onClear?(): void;
    onClose(): void;
    initialValue: string;
    anchor: any;
}) {
    const {open} = props;

    const [search, setSearch] = useState("");
    const [value, setValue] = useState(props.initialValue);

    const lookupRef = useRef(props.lookup);
    lookupRef.current = props.lookup;

    const list = useAsync2((input: {search: string, open: boolean}) => {
        if(!input.open) return Promise.resolve([]);
        return lookupRef.current(input.search)
    }, {search: search, open}, [search, props.lookup, open]);

    // short delay to prevent the loading indicator from showing for Promise.resolve() lists
    const [delayDone, setDelayDone] = useState(false);

    useEffect(() => {
        if(!open) {
            setDelayDone(false);
            return;
        }

        const tm = setTimeout(() => setDelayDone(true), 500)
        return () => clearTimeout(tm);
    }, [open])

    const showPopupContent = !list.loading || delayDone;

    if(!props.anchor || !open || !showPopupContent) return null;

    return (
        <Popover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            anchorEl={props.anchor}
            open
            style={{fontSize: "0.5rem"}}
            onClose={props.onClose}
        >
            <TextField fullWidth ref={r => {
                if(!r) return;
                setTimeout(() => {
                    r.getElementsByTagName("input")[0].focus();
                }, 100);
            }}
                       size="small" value={search}
                       onChange={e => setSearch(e.currentTarget.value)}
                       placeholder="Search"
                       classes={{
                           root: searchRoot,
                       }}
                       InputProps={{
                           endAdornment: <InputAdornment position="end">
                               <IconButton onClick={() => setSearch("")}>
                                   <Clear />
                               </IconButton>
                           </InputAdornment>
                       }} />
            {list.loading && <CircularProgress />}
            {list.error && <Typography color="red">{list.error}</Typography>}
            <div style={{height: 8}} />
            {list.result?.map(o =>
                <MenuItem disabled={o.disabled} onClick={() => {
                    setValue(o.value);
                    props.onChange(o);
                    props.onClose();
                }} selected={o.value === value} key={o.value} value={o.value}>{o.display}</MenuItem>)}
            <Divider />
            {props.onClear ? <Button size="small" onClick={() => {
                props.onClear?.();
                props.onClose();
            }} fullWidth>Clear</Button> : <Button size="small" onClick={props.onClose} fullWidth>Close</Button>}
        </Popover>
    )
}

const searchRoot = css({
    "& .MuiOutlinedInput-root": {
        border: "none",
        borderRadius: 0
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderRadius: 0
    },
})