import {ProductPreview, ReceiveInput} from "../../../api/Logistics";
import {DriverLogDetailRow} from "./DriverLogDetail";
import {distinct, first, useAsync2} from "nate-react-api-helpers";
import {useMemo} from "react";
import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import {Product} from "../../../api/Products";

export function DriverLogExtras(props: {
    log: ReceiveInput;
    contents: ProductPreview[];
}) {
    const existingProductIds = useMemo(() => {
        return distinct(props.contents.map(i => i.product))
    }, [props.contents])

    const extras = props.log.extras.filter(e => {
        // @ts-ignore
        return productIds.indexOf(e.product.product) === -1
    })

    const items = useMemo(() => {
        return props.log.items.filter(e => {
            return existingProductIds.indexOf(e.product) === -1
        });
    }, [props.log.items, existingProductIds]);

    const needProductIds = useMemo(() => {
        return distinct(items.map(i => i.product))
    }, [items])

    const products = useAsync2((input) => {
        if(input.ids.length === 0) return Promise.resolve([] as Product[]);
        return api.products.listById(input)
    }, {ids: needProductIds}, [needProductIds]);

    if(products.loadingOrError) {
        return (
            <tr><td colSpan={3}>
                <Loader {...products} />
            </td></tr>
        );
    }

    return (
        <>
            {extras.map(e => <tr>
                <td>{e.product.description}</td>
                <td style={{textAlign: "right"}}>0</td>
                <td>
                    <DriverLogDetailRow expected={0} got={e.status?.received || 0} damaged={e.status?.damaged || 0} />
                </td>
            </tr>)}
            {items.map(e => <tr>
                <td>{first(products.asList, p => p.id === e.product)?.name || e.product}</td>
                <td style={{textAlign: "right"}}>0</td>
                <td>
                    <DriverLogDetailRow expected={0} got={e.received || 0} damaged={e.damaged || 0} />
                </td>
            </tr>)}
        </>
    )
}