import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import { api } from "../../../../api/API";
import { AsyncHelper } from "./AsyncHelper";
import moment from "moment";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {ProjectLogItem} from "../../../../api/ProjectLog";

export function ChangeHistory(props: {
    opening?: {
        id: number;
        name: string;
    };
    miscProduct?: {
        id: number;
        name: string;
    };

    onDone(): void;
}) {

    const data = useGetAll(input => api.projectLog.list(input), {
        opening: props.opening?.id,
        miscProduct: props.miscProduct?.id
    }, [props.opening?.id, props.miscProduct?.id]);

    return (
        <Dialog open onClose={props.onDone}>
            <DialogTitle>
                {props.opening?.name || props.miscProduct?.name} Change History
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                <AsyncHelper<ProjectLogItem[]> {...data}>
                    {list => <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Detail</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map(l => (<TableRow key={l.id}>
                                <TableCell>{moment(l.createdAt).format("MMM DD [at] Ha")}</TableCell>
                                <TableCell>{l.createdByName}</TableCell>
                                <TableCell>
                                    {l.content}

                                    {l.note && <div style={{paddingTop: 8}}>
                                        Note: {l.note}
                                    </div>}
                                </TableCell>
                            </TableRow>))}
                            {list.length === 0 && <TableRow key="no-data">
                                <TableCell colSpan={3}>
                                    No History
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>}
                </AsyncHelper>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDone}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}