import {Delivery, DeliveryItem, PurchaseOrderItem} from "../../../api/Logistics";
import {distinct, orderByStrAscending} from "nate-react-api-helpers";
import {forwardRef, useMemo} from "react";
import {Page, pdfTableStyle} from "../../../misc/pdf/parts/Page";
import moment from "moment";
import {DataSplitInfo, PageRenderProvider} from "../../../misc/pdf/parts/PageContext";
import {PDF} from "../../../misc/pdf/parts/PDF";
import {Project} from "../../../api/Projects";
import {PDFHeader} from "../../../misc/pdf/parts/PDFHeader";
import {useReadyCheck} from "../../../misc/pdf/UseReadyCheck";
import {table} from "../../../misc/pdf/parts/TableStyle";
import {formatDescription} from "../purchasing/toSource/SubmitButton";
import {hollowMetalDoor, hollowMetalFrame, woodDoor} from "../../project/quote/openings/QuoteOpenings";

export type Row = {
    type: "data"
    qty: number;
    description: string;
    openings?: string[];
} | {
    type: "header"
    title: string;
}

export function usePickingSlipData(props: {
    items: (DeliveryItem | PurchaseOrderItem)[];
}) {
    return useMemo(() => {

        const doorsHm: Row[] = [];
        const doorsWd: Row[] = [];
        const hardware: Row[] = [];
        const framesHm: Row[] = [];
        const framesOther: Row[] = [];
        const div10CornerGuard: Row[] = [];
        const div10Locker: Row[] = [];
        const div10Mailbox: Row[] = [];
        const div10WrAccessory: Row[] = [];
        const div10WrPartition: Row[] = [];

        props.items.map(itm => {
            switch(itm.kind) {
                case "door": {
                    const obj: Row = {
                        type: "data",
                        qty: itm.qty,
                        description: itm.details ? formatDescription(itm.details) + " " + itm.details.frameHanding : itm.description,
                        openings: (itm.openings as any[])?.map(o => o.name) || [],
                    }

                    switch (itm.details?.doorSeries) {
                        case hollowMetalDoor:
                        default:
                            doorsHm.push(obj)
                            break;
                        case woodDoor:
                            doorsWd.push(obj)
                            break;
                    }

                    break;
                }
                case "frame": {

                    const obj: Row = {
                        type: "data",
                        qty: itm.qty,
                        description: itm.details ? formatDescription(itm.details) + " " + itm.details.frameHanding : itm.description,
                        openings: (itm.openings as any[])?.map(o => o.name) || [],
                    }

                    switch (itm.details?.frameSeries) {
                        case hollowMetalFrame:
                            framesHm.push(obj)
                            break;
                        default:
                            framesOther.push(obj)
                            break;
                    }

                    break;
                }
                case "frame-anchor":
                case "hardware":
                    hardware.push({
                        type: "data",
                        qty: itm.qty,
                        description: itm.details ? formatDescription(itm.details) : itm.description,
                        openings: (itm.openings as any[])?.map(o => o.name) || [],
                    })
                    break;
                case "div-10": {
                    const obj: Row = {
                        type: "data",
                        qty: itm.qty,
                        description: itm.details ? formatDescription(itm.details) : itm.description,
                    }

                    switch (itm.details?.div10Category) {
                        case 'corner-guard':
                            div10CornerGuard.push(obj);
                            break;
                        case 'locker':
                            div10Locker.push(obj);
                            break;
                        case 'mailbox':
                            div10Mailbox.push(obj);
                            break;
                        default:
                        case 'wr-accessory':
                            div10WrAccessory.push(obj);
                            break;
                        case 'wr-partition':
                            div10WrPartition.push(obj);
                            break;
                    }

                    break;
                }
                default:
                    console.error("invalid type '" + itm.kind + "'", itm)
                    break;
            }
        })

        function simplify(arr: Row[]) {
            const out: Row[] = [];
            arr.map(a => {
                if(a.type !== "data") return;

                const other = out.find(r => r.type === "data" && r.description === a.description)
                if(other && other.type === "data") {
                    other.qty += a.qty;
                    if(other.openings && a.openings) {
                        other.openings.push(...a.openings);
                    }
                } else {
                    out.push(a);
                }
            })

            out.map(r => {
                if(r.type === "header") return;
                if(r.openings) {
                    r.openings = distinct(r.openings)
                    orderByStrAscending(r.openings, v => v)
                }
            })

            return out;
        }

        const result: Row[] = [];

        if(framesHm.length > 0) {
            result.push({
                type: "header",
                title: "HM Frames",
            })

            result.push(...simplify(framesHm))
        }

        if(framesOther.length > 0) {
            result.push({
                type: "header",
                title: "Other Frames",
            })

            result.push(...simplify(framesOther))
        }

        if(doorsHm.length > 0) {
            result.push({
                type: "header",
                title: "HM Doors",
            })

            result.push(...simplify(doorsHm))
        }

        if(doorsWd.length > 0) {
            result.push({
                type: "header",
                title: "WD Doors",
            })

            result.push(...simplify(doorsWd))
        }

        if(hardware.length > 0) {
            result.push({
                type: "header",
                title: "Hardware",
            })

            result.push(...simplify(hardware))
        }

        if(div10WrAccessory.length > 0) {
            result.push({
                type: "header",
                title: "Div 10 - Accessories",
            })

            result.push(...simplify(div10WrAccessory))
        }

        if(div10WrPartition.length > 0) {
            result.push({
                type: "header",
                title: "Div 10 - Partitions",
            })

            result.push(...simplify(div10WrPartition))
        }

        if(div10CornerGuard.length > 0) {
            result.push({
                type: "header",
                title: "Div 10 Corner Guards",
            })

            result.push(...simplify(div10CornerGuard))
        }

        if(div10Locker.length > 0) {
            result.push({
                type: "header",
                title: "Div 10 - Lockers",
            })

            result.push(...simplify(div10Locker))
        }

        if(div10Mailbox.length > 0) {
            result.push({
                type: "header",
                title: "Div 10 - Mailboxes",
            })

            result.push(...simplify(div10Mailbox))
        }

        return result;
    }, [props.items]);
}

export function buildSections(rows: Row[]) {
    type Section = {rows: Row[], header: string}
    const rowsByHeader: Section[] = []
    let currentSection: Section | null = null;

    rows.map((r, index) => {
        if(r.type === "header") {
            currentSection = {rows: [], header: r.title}
            rowsByHeader.push(currentSection)
            return;
        }

        currentSection?.rows.push(r)
    })

    return rowsByHeader
}

export const PickingSlipPDF = forwardRef(function PackingSlipPDF(props: {
    value: Delivery;
    items: DeliveryItem[]
    project: Project;
    onReady(): void;
}, ref: any) {

    const rows = usePickingSlipData({
        items: props.items,
    })
    const ready = useReadyCheck(props.onReady)

    return (
        <div ref={ref}>
            <PDF>
                <PageRenderProvider data={rows} onReady={ready.section()}>
                    {(info: DataSplitInfo<Row>) => {
                        const rowsByHeader = buildSections(info.rows);

                        return (
                        <Page orientation="portrait">
                        <div style={{flex: 1}}>
                            {info.pageIndex === 0 && <>
                                <PDFHeader noAddress title="Picking Slip">
                                </PDFHeader>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "0.8rem",
                                    width: "100%"
                                }}>
                                    <table className={table}>
                                        <tr>
                                            <td>Date Created</td>
                                            <td>S.O. No.</td>
                                        </tr>
                                        <tr>
                                            <td>{moment().format("MM/DD/YYYY")}</td>
                                            <td>{props.value.id}</td>
                                        </tr>
                                    </table>

                                    <table className={table}>
                                        <tr>
                                            <td>Pick By Date</td>
                                            <td>Project</td>
                                        </tr>
                                        <tr>
                                            <td>{moment(props.value.pickByDate).format("MM/DD/YYYY")}</td>
                                            <td>{props.project.name}</td>
                                        </tr>
                                    </table>
                                </div>
                            </>}

                            {rowsByHeader.map(section => <>
                                <div style={{height: 16}}/>
                                <table className={pdfTableStyle}>
                                    <thead>
                                        <tr>
                                            <th style={{textAlign: "left"}} colSpan={3}>{section.header}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {section.rows.map((i, index) => {
                                        if (i.type === "header") {
                                            return null;
                                        }

                                        const qtyWidth = 60;

                                        if(!i.openings) {
                                            return (
                                                <tr key={index.toString()}>
                                                    <td style={{textAlign: "right", width: qtyWidth}}>{i.qty}</td>
                                                    <td colSpan={2}>{i.description}</td>
                                                </tr>
                                            )
                                        }

                                        return (
                                            <tr key={index.toString()}>
                                                <td style={{textAlign: "right", width: qtyWidth}}>{i.qty}</td>
                                                <td>{i.description}</td>
                                                <td>{i.openings.join(", ")}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </>)}
                        </div>
                        </Page>)}}
                </PageRenderProvider>
            </PDF>
        </div>
    )
});