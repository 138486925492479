import { RouteDetailHeader } from "./RouteDetailHeader";
import {RouteItems} from "./RouteItems";
import {useParams} from "react-router-dom";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Container} from "../../../misc/Container";
import {Loader} from "../../../misc/Loader";
import {WhiteColumn} from "../purchasing/WhiteColumn";
import {useEffect} from "react";
import {routePopupPanel} from "./RouteList";

export function RouteDetail() {
    const params = useParams<{id: string}>();
    const id = parseInt(params["id"] || "0")
    const data = useAsync2(input => api.logistics.getRoute(input), {id: id}, [id]);
    const showPopout = data.result && data.result.completed === false;

    useEffect(() => {
        if(!showPopout) return;

        routePopupPanel.open();
        routePopupPanel.sendToClient({
            route: id,
        })
        
        return () => routePopupPanel.close();
    }, [id, showPopout]);

    return (
        <Container>
            <WhiteColumn>
                <div style={{display: "flex", flexDirection: "column", height: "100%", overflow: "auto"}}>
                    <Loader {...data}>
                        {value => <RouteDetailHeader value={value} onReload={data.reload} />}
                    </Loader>
                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                        <RouteItems route={id} routeObj={data.result} />
                    </div>
                </div>
            </WhiteColumn>
        </Container>
    )
}