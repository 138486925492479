import {Delivery} from "../../../api/Logistics";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {SnackContext} from "../../../misc/Snackbar";
import {api} from "../../../api/API";
import {Button, Chip, Grid} from "@mui/material";
import {paginated2options, Select2, select2list} from "../../../misc/Select2";
import {deliveryStatuses} from "./DeliveryList";
import { DeliveryItems } from "./DeliveryItems";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {usePopout} from "../../../misc/PopoutInteractor";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";
import {LogisticsItemHeader} from "../purchasing/LogisticsItemHeader";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {DateField} from "../../../misc/DateField";
import {DeliveryNotes} from "./DeliveryNotes";
import {ItemsFooter} from "./ItemsFooter";

export function DeliveryDetail(props: {
    value: Delivery;
    onReload(): void;
}) {
    const onReload = useSyncedRef(props.onReload);
    const value = props.value;
    const valueRef = useSyncedRef(value);
    const [lastUpdate, setLastUpdate] = useState<number>();

    const snack = useContext(SnackContext);

    const update = useCallback((prop: keyof Delivery) => {
        return async (newValue: any) => {
            // @ts-ignore
            value[prop] = newValue;
            setLastUpdate(Date.now());
        }
    }, [value]);

    useEffect(() => {
        if(!lastUpdate) return;

        const tm = setTimeout(async () => {
            if(Object.keys(valueRef.current).length === 0) return;
            if(valueRef.current.id <= 0) return;

            try {
                await api.logistics.updateDelivery(valueRef.current);
                onReload.current();
            } catch (e: any) {
                snack.error(e.toString())
            }
        }, 500);

        return () => clearTimeout(tm);
    }, [lastUpdate, snack, valueRef, onReload]);

    const ctrl = usePopout(deliveriesPopoutPanel);
    const hasWarning = !value.hasEnoughInWarehouse || value.isMissingAnchors;

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <form onSubmit={e => {
                e.preventDefault();
            }} style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <LogisticsItemHeader>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2}>
                            Pack #{value.id}
                        </Grid>
                        <Grid item xs={2}>
                            <Select2 disabled={true} label="Project" initialValue={value.project as any} initialDisplay={value.projectName}
                                     lookup={paginated2options("name", "id", search => api.projects.list({search: search, offset: 0}))}
                                     onChange={e => update("project")(e.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <Select2 label="Status"
                                    disabled={["draft", "to-pick", "picked"].indexOf(value.status) === -1}
                                     initialValue={value.status}
                                     lookup={select2list(deliveryStatuses)}
                                     onChange={e => update("status")(e.value)}
                                      />
                        </Grid>
                        <Grid item xs={4}>
                            <DateField disabled={["draft", "to-pick", "picked"].indexOf(value.status) === -1}
                                       fullWidth nullable
                                       label="Pick By"
                                       initialValue={value.pickByDate}
                                       onChange={update("pickByDate")} />
                        </Grid>
                        {hasWarning && <Grid item>
                            {!value.hasEnoughInWarehouse && <Chip size="small" label="Missing Products" />}
                            {value.isMissingAnchors && <Chip color="warning" label="Frames are missing their anchors" />}
                        </Grid>}
                        <Grid item>

                            <Button variant="outlined" endIcon={<OpenInNewIcon />}
                                    onClick={() => {
                                        ctrl.open();
                                        ctrl.sendToClient({
                                            delivery: props.value.id,
                                            project: props.value.project,
                                        });
                                    }} disabled={!isEditable(props.value)}>
                                To Pack
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{height: 8}} />
                </LogisticsItemHeader>
                <div style={{flex: 1, display: "flex"}}>
                </div>
            </form>
            <DeliveryItems delivery={value} onReload={props.onReload} />
            <DeliveryNotes id={value.id} />
            <ItemsFooter delivery={value} />
        </div>
    )
}

export function isEditable(value: Delivery) {
    return value.status === "draft"
}