import {PurchaseOrder, PurchaseOrderItem} from "../../../../api/Logistics";
import {forwardRef, useMemo} from "react";
import {Page, pdfTableStyle} from "../../../../misc/pdf/parts/Page";
import moment from "moment";
import {formatCents, groupByArr} from "nate-react-api-helpers";
import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {pickup} from "../PurchaseOrderList";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {HardwareGroupWithContent} from "../../../../api/Hardware";
import {HardwarePrepImage} from "./HardwarePrepImage";
import {pdfInfo} from "../../../../misc/pdf/parts/CoverPage";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {PDFHeader} from "../../../../misc/pdf/parts/PDFHeader";
import {HardwarePrepTable} from "./HardwarePrepTable";
import {table, tableish} from "../../../../misc/pdf/parts/TableStyle";
import {FrameDetails, useFrameDetails} from "./FrameDetails";
import {DoorDetails, useDoorDetails} from "./DoorDetails";
import {KeyingDetails} from "./KeyingDetails";
import {KeyDetails} from "../../../../api/Keying";

export const taxRate = 13;

export const purchaseOrderCSV = function (props: {
    value: PurchaseOrder;
    items: PurchaseOrderItem[]
}) {
    const {sum, rows, date} = buildData(props);
    const out: string[][] = [];

    out.push(["Purchase Order"])
    out.push(["Date", date]);
    out.push(["P.O. number", props.value.poNumber])
    out.push(["Vendor", props.value.supplierName])
    out.push([])
    out.push(["Item", "Qty", "Rate", "Amount"]);

    out.push(...rows.map(i => [
        combineContent(" - ", i.description, i.productCode),
        i.qty,
        i.unitAmount,
        i.amount
    ]))
    out.push(["", "",`Total`, formatCents(sum)])

    return out;
}

export const dateFormat = "MM/DD/YYYY"

function buildData(props: {
    value: PurchaseOrder;
    items: PurchaseOrderItem[]
}) {
    let sum = 0;

    let rows = groupByArr(props.items, i => i.description)
        .map(c => {
            const qty = c.values.reduce((acc, v) => v.qty + acc, 0);
            const amount = Math.round(c.values[0].unitPriceCents * qty);
            sum += amount;

            return {
                isDoorOrFrame: c.values[0].kind === "door" || c.values[0].kind === "frame",
                description: c.values[0].description,
                unitAmount: formatCents(c.values[0].unitPriceCents),
                amount: formatCents(amount),
                qty: qty.toString(),
                productCode: c.values[0].code,
                openings: c.values.map(v => v.openingIds || []).flat() as number[],
            }
        });

    let tax = Math.round(sum * taxRate/100);

    rows.push({
        description: "HST (ON) on purchases (Input Tax Credit)",
        qty: "",
        productCode: "",
        amount: formatCents(tax),
        unitAmount: taxRate + "%",
        isDoorOrFrame: false,
        openings: [] as number[],
    })

    sum += tax;

    const date = moment(props.value.submittedAt).format(dateFormat);

    return {
        sum,
        tax,
        rows,
        date,
    }
}

export const PurchaseOrderPDF = forwardRef(function PurchaseOrderPDF(props: {
    value: PurchaseOrder;
    items: PurchaseOrderItem[]
    hwGroups: HardwareGroupWithContent[]
    keyDetails: KeyDetails;
    onReady(): void;
}, ref: any) {
    const {sum, rows, date} = useMemo(() => buildData(props), [props]);
    const ready = useReadyCheck(props.onReady);
    const section = ready.section();

    const frames = useFrameDetails(props.items)
    const doors = useDoorDetails(props.items)

    const hasKeyedItem = useMemo(() => {
        return props.items.some(h => !!h.hwKeyed)
    }, [props.items]);

    const doorOrFrameHwGroups = useMemo(() => {
        const hardwareGroupIds: number[] = [];

        props.items
            .filter(v => v.kind === "door" || v.kind === "frame")
            .filter(v => v.openings?.map(o => {
                if(o.hardwareGroup && hardwareGroupIds.indexOf(o.hardwareGroup) === -1) {
                    hardwareGroupIds.push(o.hardwareGroup);
                }
            }))

        return props.hwGroups.filter(o => hardwareGroupIds.includes(o.id))
    }, [props.hwGroups, props.items]);

    return (
        <div ref={ref}>
            <PDF>
                <PageRenderProvider key="page-render" data={rows} onReady={section}>
                    {(info: DataSplitInfo<{
                        description: string;
                        unitAmount: string;
                        amount: string;
                        qty: string;
                        productCode: string;
                        openings: number[];
                        isDoorOrFrame: boolean;
                    }>) =>
                        <Page orientation="portrait" key={info.pageIndex.toString()}>
                            <div style={{flex: 1}}>
                                <PDFHeader noAddress title="Purchase Order">
                                    <table className={table}>
                                        <tr>
                                            <td>Date</td>
                                            <td>P.O. No.</td>
                                        </tr>
                                        <tr>
                                            <td>{date}</td>
                                            <td>{props.value.poNumber}</td>
                                        </tr>
                                    </table>
                                </PDFHeader>

                                <div style={{display: "flex", justifyContent: "space-between", fontSize: "0.8rem"}}>
                                    <div>
                                        <div className={tableish}>
                                            <div>Vendor:</div>
                                            <div>
                                                <div>{props.value.supplierName}</div>
                                                <div>{props.value.supplierAddress}</div>
                                                <div>{props.value.supplierCity}, {props.value.supplierProvince}</div>
                                                <div>{props.value.supplierPostal}</div>
                                            </div>
                                        </div>
                                        <div className={tableish} style={{marginTop: 20}}>
                                            <div>Project:</div>
                                            <div>
                                                <div>{props.value.linkedProjects?.map(p => p.name).join(", ")}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={tableish}>
                                            <div>Ship To:</div>
                                            <div>
                                                {props.value.deliveryMethod === pickup ?
                                                    <>We will pick up</> : <>
                                                        <div>{pdfInfo.address1}</div>
                                                        <div>{pdfInfo.address2}</div>
                                                        <div>{pdfInfo.postal}</div>
                                                    </>}
                                            </div>
                                        </div>
                                        <div style={{paddingTop: 20}}>
                                            <table className={table}>
                                                <tr>
                                                    <td>Terms</td>
                                                    <td>Expected</td>
                                                </tr>
                                                <tr>
                                                    <td>Net 30</td>
                                                    <td>{props.value.due ? moment(props.value.due).format(dateFormat) : ""}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div style={{height: 16}} />

                                <table className={pdfTableStyle}>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Qty</th>
                                            <th>Rate</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {info.rows.map(i => <tr>
                                        <td>
                                            {i.description.includes(i.productCode) ? i.description : combineContent(" - ", i.description, i.productCode)}
                                        </td>
                                        <td style={{textAlign: "right"}}>{i.qty}</td>
                                        <td style={{textAlign: "right"}}>{i.unitAmount}</td>
                                        <td style={{textAlign: "right"}}>{i.amount}</td>
                                    </tr>)}
                                    {info.isLastPage && <tr key="footer" className="table-footer">
                                        <td className="pdf-header" colSpan={3} style={{textAlign: "right"}}>
                                            Total
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {formatCents(sum)}
                                        </td>
                                    </tr>}
                                    </tbody>

                                </table>
                            </div>
                        </Page>}
                </PageRenderProvider>
                <FrameDetails value={frames} onReady={ready.section()} />
                <DoorDetails value={doors} onReady={ready.section()} />
                {doorOrFrameHwGroups.length > 0 && <HardwarePrepImage value={doorOrFrameHwGroups}
                                                                      doors={doors}
                                                                      frames={frames} />}
                {doorOrFrameHwGroups.length > 0 && <HardwarePrepTable value={doorOrFrameHwGroups} onReady={ready.section()}
                                                                      doors={doors}
                                                                      frames={frames} />}
                {hasKeyedItem && props.keyDetails.rows.length > 0 && <KeyingDetails value={props.keyDetails} onReady={ready.section()} />}
            </PDF>
        </div>
    )
})

export function combineContent(separator: string, ...input: string[]) {
    return input.filter(i => !!i).join(separator)
}