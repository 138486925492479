import {useEffect, useMemo, useRef, useState} from "react";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import {Button, Dialog, DialogActions, Grid, DialogContent, DialogTitle, Alert, TextField} from "@mui/material";
import {first, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useHwAPI} from "../../shopdrawing/hardware/HardwareGeneric";
import {useReloader} from "../../shopdrawing/hardware/ReloadContext";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useAlternative} from "../alternative/Alternative";
import {MergeCandidate, MergeGroup} from "../../../../api/QuoteHardware";
import {Checkbox} from "../../shopdrawing/release/Checkbox";
import {css} from "@emotion/css";
import {QtyWithPreview} from "../../../logistics/routes/QtyWithPreview";

export function MergeGroupsBtn() {
    const [show, setShow] = useState(false)

    return (
        <>
            <SmallGreyButton onClick={() => setShow(true)}>
                Merge Groups
            </SmallGreyButton>
            {show && <MergeGroups onDone={() => setShow(false)} />}
        </>
    )
}

export function MergeGroups(props: {
    onDone(): void;
}) {
    const onDoneRef = useRef(props.onDone)
    onDoneRef.current = props.onDone;

    const reload = useReloader();

    const hwapi = useHwAPI();
    const project = useProjectId();
    const alternative = useAlternative();

    const [draft, setDraft] = useState<({
        object: MergeGroup,
        warn: string | null;
    })[]>([])

    const data = useAsync2((input) => hwapi.mergeCandidates(input), {
        project: project,
        alternative: alternative,
    }, [project, alternative])

    const update = useAsyncAction(async (input) => {
        await hwapi.merge(input)
        reload.current();
        onDoneRef.current();
    }, [])

    const warnings = draft.filter(d => d.warn !== null).map(d => d.warn);
    const toUpdate = draft.filter(d => d.object.openings.length > 0);

    return (
        <Dialog open onClose={props.onDone}>
            <DialogTitle>
                Merge Groups
            </DialogTitle>
            <DialogContent>
                {data.LoadingElement}

                {data.asList.length === 0 && <Alert severity="info">No merge-able groups found</Alert>}
                {data.asList.map((l, index) => <MergeGroupItem value={l} onChange={v => {
                    const draftCopy = draft.slice(0);
                    draftCopy[index] = v;
                    setDraft(draftCopy)

                    console.log(v);
                }} />)}
            </DialogContent>
            <DialogActions>
                {warnings.length > 0 ? <Alert severity="warning">{warnings.join("\n")}</Alert> : null}
                {update.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button disabled={warnings.length > 0 || toUpdate.length === 0} onClick={() => {
                    update.callback({
                        project: project,
                        alternative: alternative,
                        groups: toUpdate.map(t => t.object)
                    })
                }}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}

function MergeGroupItem(props: {
    value: MergeCandidate;
    onChange(value: {
        object: MergeGroup,
        warn: string | null;
    }): void;
}) {
    const [checked, setChecked] = useState<number[]>([]);
    const firstOp = props.value.openings[0];
    const [hwGroup, setHwGroup] = useState(firstOp.hwGroupName)
    const [shortCode, setShortCode] = useState(firstOp.shortCodeName)

    const onChangeRef = useRef(props.onChange);
    onChangeRef.current = props.onChange;

    useEffect(() => {
        let hwGroupId = 0;


        const hwMatch = first(props.value.openings, op => op.hwGroupName === hwGroup)
        if(hwMatch) {
            hwGroupId = hwMatch.hwGroupId;
        } else if(checked.length > 0) {
            hwGroupId = props.value.openings.filter(o => checked.includes(o.id))[0].hwGroupId;
        }

        let shortCodeId = 0;
        const shortCodeMatch = first(props.value.openings, op => op.shortCodeName === shortCode)
        if(shortCodeMatch) {
            shortCodeId = shortCodeMatch.shortCodeId;
        } else if(checked.length > 0) {
            shortCodeId = props.value.openings.filter(o => checked.includes(o.id))[0].shortCodeId;
        }

        const selected = props.value.openings.filter(o => checked.includes(o.id))
        const notSelected = props.value.openings.filter(o => !checked.includes(o.id))
        const hwNames = selected.map(s => s.hwGroupName)

        let warn: string | null = null;

        if(notSelected.length > 0 && selected.length > 0) {
            const errHwNames = notSelected.filter(a => hwNames.includes(a.hwGroupName));
            if(errHwNames.length > 0) {
                warn = `Must selected or deselected all openings in hardware group ${errHwNames[0].hwGroupName}`
            }

            const err2HwNames = notSelected.filter(a => a.hwGroupName === hwGroup);
            if(err2HwNames.length > 0) {
                warn = `Must selected or deselected all openings in hardware group ${err2HwNames[0].hwGroupName}`
            }
        }

        onChangeRef.current({
            object: {
                hwGroup: hwGroupId,
                hwGroupName: hwGroup,
                shortCodeName: shortCode,
                shortCode: shortCodeId,
                openings: checked,
            },
            warn: warn,
        })
    }, [checked, hwGroup, shortCode, props.value]);

    const qtyPreview = useMemo(() => {
        const v = props.value.shortCodeItems;

        return {
            qty: v.length,
            contents: v.map(c => {
                return {
                    product: c.product,
                    description: c.productCategoryName || c.productName,
                    qty: c.qty,
                    dimensions: "",
                    code: c.productCode,
                    routeItem: 0,
                    toWarehouse: false,
                }
            })
        }
    }, [props.value])

    return (
        <>
            <div style={{fontWeight: "bold"}}>
                {firstOp.descriptiveName}{firstOp.doorName} -- {firstOp.handing}
            </div>
            <div style={{display: "flex", fontSize: "0.8rem"}}>
                <div style={{paddingRight: 8}}>Products:</div>
                <QtyWithPreview data={qtyPreview} />
            </div>

            <div style={{height: 12}} />

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField label="Combined Group Name"  fullWidth size="small" value={hwGroup} onChange={e => setHwGroup(e.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Combined Short Code Name" fullWidth size="small" value={shortCode} onChange={e => setShortCode(e.target.value)} />
                </Grid>
            </Grid>

            <div style={{height: 4}} />

            <table className={mergeTableCss}>
                <tbody>
                <tr>
                    <th>
                        <Checkbox checked={checked.length === props.value.openings.length} onClick={() => {
                            if(checked.length === props.value.openings.length) {
                                setChecked([])
                            } else {
                                setChecked(props.value.openings.map(o => o.id))
                            }
                        }} />
                    </th>
                    <th>Opening</th>
                    <th>HW Group</th>
                    <th>Short Code</th>
                </tr>
                {props.value.openings.map(o => {
                    const isChecked = checked.includes(o.id)
                    return (
                        <tr key={o.id.toString()}>
                            <td><Checkbox checked={isChecked} onClick={() => {
                                if(checked.includes(o.id)) {
                                    setChecked(checked.filter(v => v !== o.id))
                                } else {
                                    setChecked([...checked, o.id])
                                }
                            }} /></td>
                            <td>{o.name}</td>
                            <td>{isChecked ? hwGroup : o.hwGroupName}</td>
                            <td>{isChecked ? shortCode : o.shortCodeName}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    )
}

const mergeTableCss = css({
    borderCollapse: "collapse",
    width: "100%",

    "& > tbody > tr > th": {
        textAlign: "left",
    },

    "& td": {
        paddingLeft: 4,
        paddingRight: 4,

    }, "& th": {
        fontWeight: "normal",
        paddingLeft: 4,
        paddingRight: 4,
        borderBottom: "1px solid",
    }
})