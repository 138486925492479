import {AdjustCol, CentsCol, NumberCol, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import {useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import React, {useState} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {formatCents} from "nate-react-api-helpers";
import {useMiscPrices} from "../../quote/pricing/UseMiscPricing";
import {getDataInner, SubCategories} from "./QuotePDF";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {getAll} from "../../shopdrawing/openings/Openings";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type PriceSummaryLine = {
    id: number;
    name: string;
    extendedCost: number;
    qty: number;
    markup: string;
    unitPrice: number;
    extendedPrice: number;
    profitActual: number;
    profitProjected: number;
    marginProjected: string;

    canExpand: boolean;
    expandPath: string[]
    expanded: boolean;
    indent: number;
}

export function QuoteSummary() {
    const project = useProjectId();
    const alternative = useAlternative();
    const u = useUser();

    const miscPrices = useMiscPrices({
        project,
        alternative,
    })
    const [selectedPath, setSelectedPath] = useState<string[]>([]);

    if(u === null) return null;

    return (
        <Table<PriceSummaryLine>
            name={priceTableName(project, "summary")}
            globalPrefsName={priceTableName(0, "summary")}
            fetch={async (ctx) => {

                let rs = getAll(ctx, offset => api.quotePricing.list({project: project, alternative, offset}))
                const misc = miscPrices.get();
                const riders = getAll(ctx, offset => api.quotePricing.listRiders({ project, alternative, offset }))

                const data = getDataInner({
                    prices: await rs,
                    notes: [],
                    miscPrices: await misc,
                    riders: await riders,
                })

                function calcRows(rows: QuotePriceLine[], riders: QuotePriceRider[]) {
                    const riderTotal = riders.map(d => d.qty * d.unitCostCents).reduce((a, b) => a + b, 0);
                    const riderQty = riders.map(d => d.qty).reduce((a, b) => a + b, 0);

                    return {
                        extendedPrice:
                            rows.map(d => d.extendedPrice).reduce((a, b) => a + b, 0) +
                            riderTotal
                        ,
                        extendedCost: rows.map(d => d.unitCostCents * d.qty).reduce((a, b) => a + b, 0) + riderTotal,
                        qty: rows.map(d => d.qty).reduce((a, b) => a + b, 0) + riderQty,
                    }
                }

                priceDetailEmitter.emit(await rs);

                let groups: PriceSummaryLine[] = [];
                let counter = 0;

                function expandSection(rows: QuotePriceLine[], riders: QuotePriceRider[], depth: number, parentPath: string[], selectedSubPath: string[], categories: SubCategories[]) {
                    for(let i = 0; i < categories.length; i++) {
                        const cat = categories[i];
                        const rows2 = rows.filter(cat.filter);
                        const riders2 = riders.filter(cat.filterRider);
                        const {extendedCost, extendedPrice, qty} = calcRows(rows2, riders2);
                        const expanded = cat.name === selectedSubPath[0];

                        groups.push({
                            id: counter++,
                            name: cat.name,
                            extendedCost: extendedCost,
                            extendedPrice: extendedPrice,
                            qty: qty,
                            markup: formatCents(extendedPrice - extendedCost),
                            unitPrice: 0,
                            profitActual: 0,
                            profitProjected: 0,
                            marginProjected: "",
                            indent: depth,
                            expandPath: parentPath.concat(cat.name),
                            canExpand: cat.children && cat.children.length > 0,
                            expanded: expanded,
                        } as PriceSummaryLine)

                        if(expanded && cat.children) {
                            expandSection(rows2, riders2, depth+1, parentPath.concat(cat.name), selectedSubPath.slice(1), cat.children)
                        }
                    }
                }

                data.map((d, index) => {
                    if(d.type === "section-meta") {
                        const extendedPrice = d.totalCents;
                        const {extendedCost, qty} = calcRows(d.rows, d.riders);

                        let expandPath = [d.value]
                        const expanded = selectedPath.length > 0 && selectedPath[0] === d.value;

                        groups.push({
                            id: counter++,
                            name: d.value,
                            extendedCost: extendedCost,
                            qty: qty,
                            markup: formatCents(extendedPrice - extendedCost),
                            unitPrice: 0,
                            extendedPrice: extendedPrice,
                            profitActual: 0,
                            profitProjected: 0,
                            marginProjected: "",
                            indent: 0,
                            expandPath: expandPath,
                            canExpand: d.categories.length > 0,
                            expanded: expanded,
                        } as PriceSummaryLine)

                        if(expanded) {
                            expandSection(d.rows, d.riders, 1, [d.value], selectedPath.slice(1), d.categories)
                        }

                    } else if(d.type === "total") {
                        groups.push({
                            id: index,
                            name: "Total",
                            extendedCost: 0,
                            qty: 0,
                            markup: "",
                            unitPrice: 0,
                            extendedPrice: d.totalCents,
                            profitActual: 0,
                            profitProjected: 0,
                            marginProjected: "",
                            indent: 0,
                            expanded: false,
                            canExpand: false,
                        } as PriceSummaryLine)
                    }
                })

                return groups;
            }}
            columns={[
                ViewOnly({
                    name: "Name",
                    render: (d: PriceSummaryLine) => (<div style={{paddingLeft: d.indent * 20, display: "flex"}}>
                        <div style={{flex: 1}}>{d.name}</div>
                        {d.canExpand && <div>
                            <button style={{background: "transparent", border: "none", padding: 0, height: "1em", fontSize: 12, cursor: "pointer"}} onClick={() => {
                                if(!d.expanded) {
                                    setSelectedPath(d.expandPath)
                                } else {
                                    setSelectedPath(d.expandPath.slice(0, d.expandPath.length - 1))
                                }
                            }}>{d.expanded ? <KeyboardArrowUpIcon fontSize="inherit" />: <KeyboardArrowDownIcon fontSize="inherit" />}</button>
                        </div>}
                    </div>),
                    width: 300,
                }),
                ViewOnly(NumberCol("Qty", "qty", 150)),
                ViewOnly(CentsCol("Total Cost", "extendedCost", 80)),
                ViewOnly(AdjustCol(StringCol("Markup", "markup", 80), {
                    alignRight: true,
                })),
                ViewOnly(CentsCol("Total Price", "extendedPrice", 100)),
            ]}
            fetchDeps={[project, alternative, selectedPath]}
        />
    )
}