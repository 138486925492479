import {api} from "../../../api/API";
import moment from "moment";
import {DashCardData} from "./DashCardData";

export function DeliveriesToPick() {
    return (
        <DashCardData
            lookup={() => api.logistics.listDeliveries({
                toPick: true,
            })}
            title="Deliveries to Pick"
            itemTitle={d => d.projectName}
            itemSubtext={d => `${d.updatedByName} - ${moment(d.updatedAt || undefined).format("MMMM D")}`}
            itemRight={d => d.itemCount + " items"}
            link="/logistics/deliveries"
        />
    )
}

