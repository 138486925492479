import {useRouteInfo} from "./RouteDetail";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "../../../misc/Snackbar";
import {DeliverOrReceive} from "../receiving/DeliverOrReceive";

export function RouteReceiveAtWarehouse() {
    const {
        id,
    } = useRouteInfo();
    const list = useAsync2(props => api.logistics.listDeliveryItemsRemainingOnTruck(props), {route: id}, [id]);
    const nav = useNavigate();
    const snack = useSnackbar();

    const backURL = `/logistics/driver/${id}`;

    return <DeliverOrReceive
                list={list}
                backURL={backURL}
                title="Tykel Warehouse"
                 submitTitle="Receive"
                atWarehouse={true}
                 onSubmit={async input => {
                     await api.logistics.completeRoute({
                         route: id,
                         details: input,
                     })

                     nav("/logistics/driver/success");
                     snack.success("Saved");
                 }} />
}
