import {useNavigate, useParams} from "react-router-dom";
import {ReceivePOInner} from "../receiving/ReceivePO";
import {api} from "../../../api/API";

export function PickupPO() {
    const params = useParams<{
        id: string;
        stopNumber: string;
        routeItem: string;
        poId: string;
    }>();

    const po = parseInt(params.poId || "0")
    const backURL = `/logistics/driver/${params.id}/${params.stopNumber}`;
    const nav = useNavigate();

    return (
        <ReceivePOInner
            onSubmit={async (input) => {

                await api.logistics.completeRouteItem({
                    route: parseInt(params.id || "0"),
                    routeItem: parseInt(params.routeItem || "0"),
                    details: input,
                })

                nav(backURL);
            }}
            id={po}
            backURL={backURL}
        />
    )
}