import { useState } from "react";
import {Button, Dialog, Divider, TextField} from "@mui/material";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import {useSnackbar} from "../../../misc/Snackbar";

// this type is used serverside too, don't mess with this without updating server
export type CreateProductItem = {
    product: number;
    qty: number;
    code: string;
    description: string
    dimensions: string;
}

export function CreatePOItem(props: {
    manufacturer: number | null;
    onCancel(): void;
    onDone(value: CreateProductItem, qty: number): void;
}) {
    const [item, setItem] = useState<CreateProductItem>();
    const [search, setSearch] = useState("");
    const list = useAsync2(input => api.logistics.listInventory({offset: 0, search: input.search}), {
        search: search,
    }, [search]);
    const [showCreate, setShowCreate] = useState(false);
    const [name, setName] = useState("")
    const [productCode, setProductCode] = useState("")
    const [qty, setQty] = useState("");
    const snack = useSnackbar();

    if(item) {
        return (
            <Dialog open fullScreen>
                <div style={{padding: 8}}>
                    <TextField fullWidth label="Qty" onChange={e => setQty(e.target.value)} />
                </div>
                <div style={{padding: 8}}>
                    <Button onClick={() => item && props.onDone(item, parseInt(qty))} fullWidth color="secondary" variant="contained">Add to List</Button>
                </div>
                <div style={{padding: 8}}>
                    <Button color="primary" fullWidth onClick={() => setItem(undefined)}>Go Back</Button>
                </div>
            </Dialog>
        )
    }

    const manufacturer = props.manufacturer;

    return (
        <Dialog open fullScreen>
            {showCreate && manufacturer ? <>
                <div style={{padding: 8}}>
                    <div style={{padding: 4, fontSize: "1.2rem"}}>Create Product</div>
                    <TextField fullWidth label="Product Code" onChange={e => setProductCode(e.target.value)} />
                    <div style={{height: 8}} />
                    <TextField fullWidth label="Name" onChange={e => setName(e.target.value)} />
                </div>
                <div style={{padding: 4}}>
                    <Button onClick={async () => {
                        snack.loading();

                        try {
                            if(!manufacturer) throw new Error("can't create a new item when we don't know the manufacturer");

                            const product = await api.products.upsert({
                                name: name,
                                productCode: productCode,
                                manufacturer: manufacturer,
                                finish: "",
                                category: -1, // todo: fix
                                catalogPages: "",
                            });

                            setItem({
                                product: product.id,
                                qty: 1,
                                code: product.productCode,
                                description: product.name,
                                dimensions: product.dimensions,
                            })

                            snack.hide()
                        } catch (e: any) {
                            snack.error(e.toString())
                        }
                    }} fullWidth color="secondary" variant="contained">Use</Button>
                </div>
                <div style={{padding: 8}}>
                    <Button color="primary" fullWidth onClick={() => setShowCreate(false)}>Back to Search</Button>
                </div>
            </> : <>
                <div style={{padding: 8}}>
                    <TextField fullWidth label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div>
                <Divider />
                <div style={{flex: 1, overflow: "auto"}}>
                    {props.manufacturer && <div>
                        <Button color="primary" fullWidth onClick={() => setShowCreate(true)}>Create New</Button>
                    </div>}
                    <Loader {...list}>
                        {list => <div>
                            {list.data.map(l =>
                                <div style={{padding: 4, paddingLeft: 8, paddingRight: 8}} onClick={() => setItem({
                                    product: l.product,
                                    code: l.productCode,
                                    description: l.name,
                                    qty: 1,
                                    dimensions: l.dimensions,
                                })}>
                                    <div style={{fontSize: "1.3rem"}}>{l.productCode} - {l.name}</div>
                                    <div style={{fontSize: "1rem"}}>{l.manufacturerName}</div>
                                </div>
                            )}
                        </div>}
                    </Loader>
                </div>
                <div style={{padding: 4}}>
                    <Button onClick={props.onCancel} fullWidth color="secondary" variant="contained">Cancel</Button>
                </div>
            </>}
        </Dialog>
    )
}