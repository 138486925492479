import {Divider, MenuItem, Select, TextField} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {ucWords} from "../../misc/lists/ListCommon";
import {useSyncedRef} from "../../misc/SyncedRef";
import {CompanyListInput} from "../../api/Companies";
import {css} from "@emotion/css";

export function useGetOrCreateManufacturerProps() {
    const [selected, setSelected] = useState("-");
    const [create, setCreate] = useState("");

    const selectedRef = useSyncedRef(selected)
    const createRef = useSyncedRef(create)

    const getManufacturer = useCallback(async () => {
            if(selectedRef.current === "-") throw new Error("No selection");

            if(selectedRef.current === "create") {
                const mfg = await api.companies.upsert({
                    name: createRef.current,
                    kind: "supplier",
                })
                return mfg.id;
            } else {
                return parseInt(selectedRef.current);
            }
        }, [createRef, selectedRef]);

    return {
        selected, setSelected,
        create, setCreate,
        getManufacturer,
    }
}

export function GetOrCreateManufacturer(props: {
    selected: any;
    create: string;
    name?: string;
    setCreate(value: string): void;
    setSelected(value: any): void;
    selectOnly?: boolean
}) {
    const {selected, create, setCreate, setSelected} = props;
    const canCreated = !props.selectOnly
    const data = useAsync2((input?: CompanyListInput) => api.companies.list(input), {
        kind: "supplier"
    }, []);

    const isBlank = !data.loadingOrError && data.result?.count === 0;
    useEffect(() => {
        if(isBlank && canCreated) {
            setSelected("create");
        }
    }, [isBlank, setSelected, canCreated])
    const name = props.name || "manufacturer";
    const [search, setSearch] = useState("")
    const list = useMemo(() => {
        if(!data.result?.data) return [];
        if(search === "") return data.result.data;
        return data.result.data.filter(d => d.name.toLowerCase().includes(search.toLowerCase()))
    }, [search, data.result?.data])

    return (
        <>
            {data.LoadingOrErrorElement}

            <Select
                size="small"
                fullWidth variant="outlined"
                    value={selected}
                    onChange={e => {
                        setSelected(e.target.value);
                        setCreate("");
                    }}

                onClose={() => setSearch("")}
        >
                <TextField variant="outlined"
                           fullWidth
                           className={searchClassName}
                           value={search}
                           onChange={e => {
                               e.stopPropagation()
                               setSearch(e.target.value)
                           }}
                           onKeyDown={e => e.stopPropagation()}
                           placeholder="Search"
                           autoFocus
                />
                <Divider />
                <MenuItem value="-">Choose a {name}</MenuItem>
                {canCreated && <MenuItem value="create">Create a new {name}</MenuItem>}
                {list.map(l => (
                    <MenuItem key={l.id.toString()} value={l.id.toString()}>{l.name}</MenuItem>
                ))}
            </Select>

            {canCreated && selected === "create" && <div style={{paddingTop: 16}}>
                <TextField size="small" fullWidth label={ucWords(name) + " Name"} variant="outlined" value={create} onChange={e => setCreate(e.target.value)} />
            </div>}
        </>
    )
}

const searchClassName = css({
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    }
})