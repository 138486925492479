import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {useGetAll} from "../routes/useGetAll";
import {rowSecondaryTextStyle, rowStyle} from "../driver/RouteList";
import moment from "moment";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";
import {useEffect, useRef} from "react";

export function Receiving() {
    const list = useGetAll(props => api.logistics.listPurchaseOrders({
        readyForDelivery: true,
        offset: props.offset,
    }), {}, []);

    useInterval(list.reload, 5 * 60 * 1000);

    return (
        <MobileContainer topCenter="Receiving">
            <Loader {...list}>
                {input => <div>
                    {input.map(d =>
                        <Link to={"/logistics/receiving/" + d.id} style={{textDecoration: "none", color: "inherit"}}>
                            <div className={rowStyle}>
                                <div>
                                    {d.supplierName}: PO#{d.poNumber}
                                    <div className={rowSecondaryTextStyle}>
                                        Submitted {moment(d.submittedAt).format("YYYY MMM D")}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )}
                    {input.length === 0 && <div style={{padding: 8}}><Alert color="info">No POs Pending</Alert></div>}
                </div>}
            </Loader>
        </MobileContainer>
    )
}

export function useInterval(cb: () => void, interval: number) {
    const ref = useRef(cb);
    ref.current = cb;

    useEffect(() => {
        const i = setInterval(() => {
            ref.current();
        }, interval);

        return () => clearInterval(i);
    }, [interval]);
}