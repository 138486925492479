import { Container } from "../../../misc/Container";
import {DeliveryDetail} from "./DeliveryDetail";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {SmoothLoader} from "../../../misc/Loader";
import {useParams} from "react-router-dom";
import {WhiteColumn} from "../purchasing/WhiteColumn";

export function Delivery() {
    const params = useParams<{id: string}>();
    const id = parseInt(params["id"] || "0")
    const data = useAsync2(input => api.logistics.getDelivery(input), {id: id}, [id]);

    return (
        <Container>
            <WhiteColumn>
                <SmoothLoader {...data}>
                    {value => <DeliveryDetail value={value} onReload={data.reload} />}
                </SmoothLoader>
            </WhiteColumn>
        </Container>
    )
}