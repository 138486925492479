import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import {getRowCategoryBase, PriceLine2, PriceRow, SectionKey} from "../../shopdrawing/pricing/Frames";
import {FetchContext} from "../../../../misc/scroller/Table";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";

function makeList(prices: QuotePriceLine[], riders: QuotePriceRider[]) {
    let out: PriceRow[] = [];
    let productType: string|null = null;
    let lastKey: SectionKey | null = null;

    prices.forEach(p => {
        let key = getRowCategoryBase(p, false) as any

        if(p.productType === "hardware") {
            key = "hardware-after";
        }

        if (productType !== null && lastKey !== null && (productType !== p.productType || lastKey !== key)) {
            riders.filter(v => v.productType === productType && v.sectionKey === lastKey).forEach(r => {
                out.push({
                    id: out.length * -1,
                    kind: "quote-price-rider",
                    value: r,
                })
            })

            out.push({
                id: out.length * -1,
                kind: "quote-price-rider-create",
                productType: productType,
                sectionKey: lastKey,
                name: "",
            })
        }

        lastKey = key;
        productType = p.productType

        out.push({
            id: p.id,
            kind: "quote-price",
            value: p,
        })
    })

    if(productType !== null && lastKey !== null) {
        riders.filter(v => v.productType === productType && v.sectionKey === lastKey).forEach(r => {
            out.push({
                id: out.length * -1,
                kind: "quote-price-rider",
                value: r,
            })
        })

        out.push({
            id: out.length * -1,
            kind: "quote-price-rider-create",
            productType: productType,
            sectionKey: lastKey,
            name: "",
        })
    }

    return out
}

export async function getQuotePricing(ctx: FetchContext, props: {
    project: number,
    alternative: number,
    customSort?: (rs: QuotePriceLine[]) => void
}) {
    const {project, alternative} = props;
    const pricesP = getAll(ctx, offset =>
        api.quotePricing.list({project: project, alternative, offset})
    )

    const ridersP = getAll(ctx, offset =>
        api.quotePricing.listRiders({project: project, alternative, offset})
    )

    const rs = await pricesP;
    const riders = await ridersP;

    if(props.customSort) {
        props.customSort(rs);
    } else {
        rs.sort(defaultPriceSortFunc)
    }

    priceDetailEmitter.emit(rs);
    const list = makeList(rs, riders);
    console.log("list", list);

    return list
}

export function defaultPriceSortFunc(a: QuotePriceLine | PriceLine2, b: QuotePriceLine | PriceLine2) {
    const aVal = getRowCategoryBase(a, true)
    const bVal = getRowCategoryBase(b, true)

    if(aVal < bVal) return -1;
    if(aVal > bVal) return 1;
    return 0
}

export async function quotePriceChange(input: PriceRow, opts: {
    project: number;
    alternative: number;
}) {
    if(input.kind === "quote-price") {
        const result = await api.quotePricing.upsert(input.value)
        return Object.assign({}, input, {
            value: result,
        })
    }

    if(input.kind === "quote-price-rider") {
        const result = await api.quotePricing.upsertRider(input.value)
        return Object.assign({}, input, {
            value: result,
        })
    }

    if(input.kind === "quote-price-rider-create") {
        const result = await api.quotePricing.upsertRider({
            id: 0,
            project: opts.project,
            quoteAlternative: opts.alternative,
            productType: input.productType,
            sectionKey: input.sectionKey,
            name: input.name,
            unitCostCents: 0,
            qty: 0,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            updatedBy: 0,
        })

        return {
            id: input.id,
            kind: "quote-price-rider",
            value: result,
        }
    }

    return input
}