import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {deepOrange, green} from "@mui/material/colors";
import WarningIcon from '@mui/icons-material/Warning';
import {ProductPreview, ReceiveInput} from "../../../api/Logistics";
import {useMemo, useState} from "react";
import {css} from "@emotion/css";
import {QtyPreview} from "./QtyWithPreview";

export function Completed(props: {
    value: {
        completed: boolean;
        driverLog: ReceiveInput|null;
        qty: number;
        contents: ProductPreview[];
    };
    dropoff?: boolean;
    routeComplete: boolean;
}) {
    const hasWarn = useMemo(() => {
        if(!props.value.completed) return false;
        if(!props.value.driverLog) return false;
        if(props.value.driverLog.extras.length > 0) return true;

        let shouldWarn = false;

        let contents = props.value.contents;
        if(props.dropoff) {
            contents = contents.filter(c => {
                return !c.toWarehouse;
            })
        }

        const expect: {[k: number]: number} = {};
        contents.map(c => {
            expect[c.product] = (expect[c.product] || 0) + c.qty;
            return null;
        })

        const have: {[k: number]: number} = {};
        props.value.driverLog.items.map(c => {
            have[c.product] = (have[c.product] || 0) + c.received;
            if(c.damaged && c.damaged > 0) {
                shouldWarn = true;
            }

            return null;
        })

        if(shouldWarn) return true;

        for(let i in expect) {
            if(expect[i] > 0 && !have.hasOwnProperty(i)) {
                return true
            }

            if(expect[i] !== have[i]) {
                return true
            }
        }

        return shouldWarn;
    }, [props.value, props.dropoff]);

    const [preview, setPreview] = useState<HTMLElement|null>(null);

    if(hasWarn) {
        return (
            <>
                <div className={wrapperClass} style={{cursor: "pointer", backgroundColor: deepOrange["700"]}} onMouseOver={e => setPreview(e.currentTarget)} onMouseLeave={() => setPreview(null)}>
                    <div>Missing/Extra Items</div>
                    <div style={{width: 4}} />
                    <div style={{fontSize: "0.8rem", height: "1.2em"}}>
                        <WarningIcon color="inherit" fontSize="inherit"/>
                    </div>
                </div>
                {preview && <QtyPreview contents={props.value.contents} driverLog={props.value.driverLog} anchorEl={preview} onClose={() => setPreview(null)} />}
            </>
        )
    }

    if(props.value.completed) {
        return (
            <div className={wrapperClass} style={{backgroundColor: green["700"]}}>
                <div>Completed</div>
                <div style={{width: 4}} />
                <div style={{fontSize: "0.8rem", height: "1.2em"}}>
                    <CheckCircleIcon color="inherit" fontSize="inherit"/>
                </div>
            </div>
        )
    }

    if(props.routeComplete) {
        return (
            <div className={wrapperClass} style={{backgroundColor: deepOrange["700"]}}>
                <div>Skipped</div>
                <div style={{width: 4}} />
                <div style={{fontSize: "0.8rem", height: "1.2em"}}>
                    <WarningIcon color="inherit" fontSize="inherit"/>
                </div>
            </div>
        )
    }

    return null;
}

const wrapperClass = css({
    color: "white",
    backgroundColor: deepOrange["700"], borderRadius: 8, padding: 0, paddingLeft: 6, paddingRight: 4,
    fontSize: "0.7rem",
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 8
})