import {QtyWithPreview} from "./QtyWithPreview";
import {DriverRoute, DriverRouteItem} from "../../../api/Logistics";
import {Completed} from "./Completed";

export function DropPoItemRow(props: {
    value: DriverRouteItem;
    list: DriverRouteItem[];
    route: DriverRoute;
}) {
    const c = props.value;

    return (
        <tr>
            <td>PO #{c.dropPoNumber}</td>
            <td>
                <QtyWithPreview data={c} dropoff />
            </td>
            <td>
                <Completed value={props.value} routeComplete={props.route.completed} dropoff />
            </td>
            <td></td>
        </tr>
    )
}