import {Button} from "@mui/material";
import {useCheckboxSelection} from "../../project/shopdrawing/release/Checkbox";
import {TableSelectionContext, useTableData} from "../../../misc/scroller/Table";
import {routePopupPanel} from "./RouteList";
import { DriverRoutePendingItem} from "../../../api/Logistics";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {pickupTableName} from "./PickupTable";
import {dropTableName} from "./DropTable";
import {useContext, useRef} from "react";

export function AddItemsButton(props: {
    pickup: boolean;
    route?: number
}) {
    const ids = useCheckboxSelection();
    const tbls = useContext(TableSelectionContext)
    const items = useTableData<DriverRoutePendingItem>(props.pickup ? pickupTableName : dropTableName)
    const snack = useSnackbar();
    const dblClickActive = useRef(false)

    return (
        <Button disabled={ids.length === 0 || !props.route} variant="contained" color="primary" size="small" onClick={async () => {
            if(!props.route) return;

            if(dblClickActive.current) return;
            dblClickActive.current = true;

            let havePOList: number[] = [];

            const data = items
                .filter(v => ids.indexOf(v.id) !== -1)
                .filter(v => {
                    if(v.pickupPo) {
                        if(havePOList.indexOf(v.pickupPo) !== -1) return false;
                        havePOList.push(v.pickupPo);
                    }

                    return true;
                })
                .map(item => {
                    if(item.pickupPo) {
                      return api.logistics.updateRouteItem({
                          id: 0,
                          route: props.route,
                          pickupPo: item.pickupPo,
                          completed: false,
                      } as any)
                    }

                    return api.logistics.updateRouteItems([{
                            id: 0,
                            route: props.route,
                            pickupDelivery: item.pickupDelivery,
                            sortOrder: -1,
                            completed: false,
                        },{
                            id: 0,
                            route: props.route,
                            dropDelivery: item.pickupDelivery,
                            sortOrder: 9999,
                            completed: false,
                        }] as any)
                })

            try {
                await Promise.all(data);

                tbls.refresh(props.pickup ? pickupTableName : dropTableName);
                routePopupPanel.sendToServer("refresh-route");
            } catch (e: any) {
                snack.error(e.toString())
            }

            dblClickActive.current = false;
        }}>
            Add Items
        </Button>
    )
}