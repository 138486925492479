import { APIBase } from "nate-react-api-helpers";
import {Users} from "./Users";
import {Projects} from "./Projects";
import {Openings} from "./Openings";
import {Hardware} from "./Hardware";
import {Companies} from "./Companies";
import {TableConfig} from "./TableConfig";
import {Logistics} from "./Logistics";
import {Print} from "./Print";
import {Tasks} from "./Tasks";
import { Pricing } from "./Pricing";
import { Submissions } from "./Submissions";
import {MiscProducts} from "./MiscProducts";
import {Products} from "./Products";
import {ProjectLog} from "./ProjectLog";
import {Library} from "./Library";
import {QuoteOpenings} from "./QuoteOpenings";
import {QuoteHardware} from "./QuoteHardware";
import {QuotePricing} from "./QuotePricing";
import {Keying} from "./Keying";
import {Div10} from "./Div10";
import {QuoteDiv10} from "./QuoteDiv10";
import {Extractor} from "./Extractor";
import {Contacts} from "./Contacts";
import {ProductCatalogs} from "./ProductCatalogs";
import {ProjectFiles} from "./ProjectFiles";
import {ProjectTimeline} from "./ProjectTimeline";
import {Files} from "./Files";
import {QuoteAlternatives} from "./QuoteAlternatives";
import {ProjectChangeset} from "./ProjectChangeset";
import {MiscPricing} from "./MiscPricing";
import {QuoteMiscPricing} from "./QuoteMiscPricing";
import {QuotePriceSectionNotes} from "./QuotePriceSectionNotes";
import {PriceSectionNotes} from "./PriceSectionNotes";
import {Manufacturing} from "./Manufacturing";

class API extends APIBase {
    companies = new Companies(this);
    contacts = new Contacts(this);
    div10 = new Div10(this);
    extractor = new Extractor(this);
    files = new Files(this);
    hardware = new Hardware(this);
    hardwareLibrary = new Library(this);
    keying = new Keying(this);
    logistics = new Logistics(this);
    manufacturing = new Manufacturing(this);
    miscPricing = new MiscPricing(this);
    miscProducts = new MiscProducts(this);
    openings = new Openings(this);
    priceSectionNotes = new PriceSectionNotes(this)
    pricing = new Pricing(this);
    print = new Print(this);
    productCatalogs = new ProductCatalogs(this);
    products = new Products(this);
    projectChangeset = new ProjectChangeset(this);
    projectFiles = new ProjectFiles(this);
    projectLog = new ProjectLog(this);
    projectTimeline = new ProjectTimeline(this);
    projects = new Projects(this);
    quoteAlternatives = new QuoteAlternatives(this);
    quoteDiv10 = new QuoteDiv10(this);
    quoteHardware = new QuoteHardware(this);
    quoteMiscPricing = new QuoteMiscPricing(this);
    quoteOpenings = new QuoteOpenings(this);
    quotePriceSectionNotes = new QuotePriceSectionNotes(this)
    quotePricing = new QuotePricing(this);
    submission = new Submissions(this);
    tableConfig = new TableConfig(this);
    tasks = new Tasks(this);
    users = new Users(this);

    constructor() {
        super({
            jwtRefreshEndpoint: "/api/auth/refresh"
        });

        this.fetcher.preflight.push((input) => {
            const headers = new Headers(input.params.headers);
            headers.set("X-Nonce", window.crypto.randomUUID());

            return {
                ...input,
                params: {
                    ...input.params,
                    headers: headers
                }
            }
        });
    }
}

export const api = new API();