import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";
import {Cents} from "nate-react-api-helpers";
import {Moment} from "moment";
import {Extra, Status} from "../pages/logistics/receiving/ReceivePO";
import {TDiv10Type} from "../pages/project/shopdrawing/div10/Div10Tabs";
import {Div10Category} from "./Pricing";
import {POHwPrepItem} from "../pages/logistics/purchasing/pdf/FrameDetails";
import {DescriptionInput} from "../pages/logistics/purchasing/toSource/SubmitButton";

export class Logistics extends SubAPI {

    listInventoryRequest(input: {
        project: number
        offset?: number;
        state?: string;
        kind?: string; // e.g. hardware
        category?: "opening" | "div10" | "door-hm" | "frame-hm" | "door-wd" | "hardware"
    }) {
        return this.fetcher.get<Paginated<InventoryRequest>>("/api/logistics/inventory-requests", input)
    }

    updateInventoryRequest(input: InventoryRequest) {
        return this.fetcher.post("/api/logistics/inventory-request", input)
    }

    setInventoryStocking(input: {
        product: number;
        stocking: boolean;
    }) {
        return this.fetcher.post("/api/logistics/inventory-item/set-stocking", input)
    }

    bulkUpdateRequestDueDates(input: {
        ids: number[],
        due: Moment|null,
    }) {
        return this.fetcher.post("/api/logistics/inventory-requests/bulk-update-due-date", input)
    }

    listPurchaseOrders(input: {
        offset?: number;
        search?: string;
        project?: number;
        outstanding?: boolean;
        readyForPickup?: boolean;
        readyForDelivery?: boolean;
        forPayables?: boolean
    }) {
        return this.fetcher.get<Paginated<PurchaseOrder>>("/api/logistics/purchase-orders", input)
    }

    getPO(input: {id: number}) {
        return this.fetcher.get<PurchaseOrder>("/api/logistics/purchase-order", input)
    }

    updatePurchaseOrder(input: PurchaseOrder) {
        return this.fetcher.post<PurchaseOrder>("/api/logistics/purchase-order", input)
    }

    updatePayable(input: PurchaseOrder) {
        return this.fetcher.post<PurchaseOrder>("/api/logistics/payable", input)
    }

    listPurchaseOrderItems(input: {
        offset?: number;
        purchaseOrder: number;
        type: "payable" | "purchase-order"
    }) {
        return this.fetcher.get<Paginated<PurchaseOrderItem>>("/api/logistics/purchase-order/items", input)
    }

    listLogisticsPurchaseOrderItems(input: {
        offset?: number;
        purchaseOrder: number;
        forLogistics: boolean; // true;
    }) {
        return this.fetcher.get<Paginated<PurchaseOrderItem & {product: number}>>("/api/logistics/purchase-order/items", input)
    }

    updatePurchaseOrderItem(input: Omit<PurchaseOrderItem, "logisticsQtyOutstanding">) {
        return this.fetcher.post("/api/logistics/purchase-order/item", input)
    }

    listToSourceItems(props: {
        offset?: number;
        supplier?: number;
        type?: "hardware"|"door"|"frame"|"div10"|"frame-anchor"|"component"
        subType?: TDiv10Type;
        sourceMode?: SourceMode;
        distributionMode?: DistributionMode
    }) {
        return this.fetcher.get<Paginated<ToSourceItem>>("/api/logistics/items-to-source", props)
    }

    listInventory(props: {
        offset?: number;
        search?: string;
        kind?: string;
        subKind?: "hardware" | "door" | "frame" | "div10"| "component" | "frame-anchor"
        expandGroup?: number | null;
    }) {
        return this.fetcher.get<Paginated<InventoryItem>>("/api/logistics/inventory-items", props)
    }

    listProjectInventory(props: {
        offset?: number;
        search?: string;
        subKind?: "hardware" | "door" | "frame" | "div10" | "component"
    }) {
        return this.fetcher.get<Paginated<{id: number} & InventoryItem>>("/api/logistics/project-inventory-items", props)
    }

    nonStockingInventoryItems(props: {
        offset?: number;
        search?: string;
        subKind?: "hardware" | "door" | "frame" | "div10" | "component"
    }) {
        return this.fetcher.get<Paginated<{id: number} & InventoryItem>>("/api/logistics/non-stocking-inventory-items", props)
    }

    updateInventory(props: InventoryItem) {
        return this.fetcher.post<InventoryItem>("/api/logistics/inventory-item", props)
    }

    listInventoryToRestock(props: {
        offset?: number;
        search?: string;
        belowMin?: boolean;
    }) {
        return this.fetcher.get<Paginated<InventoryItem>>("/api/logistics/inventory-items/restocking", props)
    }

    updateDelivery(props: Delivery) {
        return this.fetcher.post<Delivery>("/api/logistics/delivery", props)
    }

    updateDeliveryCustomerNote(props: {
        id: number;
        customerNote: string;
    }) {
        return this.fetcher.post("/api/logistics/delivery/customer-note", props)
    }

    updateDeliveryItem(props: DeliveryItem) {
        return this.fetcher.post<DeliveryItem>("/api/logistics/delivery/item", props)
    }

    getDelivery(input: {
        id: number;
    }) {
        return this.fetcher.get<Delivery>("/api/logistics/delivery", input)
    }

    listDeliveryItems(props: {
        offset?: number;
        delivery: number;
    }) {
        return this.fetcher.get<Paginated<DeliveryItem>>("/api/logistics/delivery/items", props)
    }

    listDeliveries(props: {
        offset?: number;
        search?: string;
        project?: number;
        toPick?: boolean;
        toDeliver?: boolean;
    }) {
        return this.fetcher.get<Paginated<Delivery>>("/api/logistics/deliveries", props)
    }

    partsToDeliver(props: {
        offset?: number;
        search?: string;
        project?: number | null;
        type?: "frame" |"hardware" | "door" | "frame-anchor" | "div10";
        div10Type?: TDiv10Type;
    }) {
        return this.fetcher.get<Paginated<ToDeliverItem>>("/api/logistics/delivery/pending-items-to-deliver", props)
    }

    dashSummary() {
        return this.fetcher.get<DashSummary>("/api/logistics/dash-summary", {})
    }

    updateRoute(props: DriverRoute) {
        return this.fetcher.post<DriverRoute>("/api/logistics/route", props)
    }

    listRoutes(props: {
        active?: boolean;
        offset?: number;
        search?: string;
    }) {
        return this.fetcher.get<Paginated<DriverRoute>>("/api/logistics/routes", props)
    }

    getRoute(props: {id: number}) {
        return this.fetcher.get<DriverRoute>("/api/logistics/route", props)
    }

    updateRouteItem(props: DriverRouteItem & {setToWarehouse?: number[]}) {
        return this.fetcher.post<DriverRoute>("/api/logistics/route/item", props)
    }

    updateRouteItems(props: DriverRouteItem[]) {
        return this.fetcher.post<{}>("/api/logistics/route/items", props)
    }

    listRouteItems(props: {
        route: number;
        forProject?: number;
        offset?: number;
        search?: string;
    }) {
        return this.fetcher.get<Paginated<DriverRouteItem>>("/api/logistics/route/items", props)
    }

    listRoutableItems(props: {
        offset?: number;
        type?: string;
    }) {
        return this.fetcher.get<Paginated<DriverRoutePendingItem>>("/api/logistics/route/items-to-move", props)
    }

    listDeliveryItemsRemainingOnTruck(props: {
        route: number;
    }) {
        return this.fetcher.get<DeliveryItem[]>("/api/logistics/route/delivery-items-remaining", props)
    }

    listRouteItemsRemainingOnTruck(props: {
        route: number;
    }) {
        return this.fetcher.get<DriverRouteItem[]>("/api/logistics/route/items-remaining", props)
    }

    completeRouteItem(props: {
        route: number;
        routeItem: number;
        details: ReceiveInput;
    }) {
        return this.fetcher.post<{}>("/api/logistics/route/complete-route-item", props)
    }

    completeRoute(props: {
        route: number;
        details: ReceiveInput;
    }) {
        return this.fetcher.post<{}>("/api/logistics/route/complete-route", props)
    }

    receivePO(props: ReceiveInput) {
        return this.fetcher.post<{}>("/api/logistics/purchase-order/receive", props);
    }

    listDamagedProducts(props: {
        includeReviewed: boolean;
        project?: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<DamageReport>>("/api/logistics/damaged-products", props);
    }

    updateDamageReport(input: DamageReport) {
        return this.fetcher.post<{}>("/api/logistics/damaged-product", input);
    }

    getRouteItem(input: {id: number}) {
        return this.fetcher.get<DriverRouteItem>("/api/logistics/route/item", input);
    }

    listWasteProducts(props: {
        includeReviewed: boolean;
        project?: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<WasteReport>>("/api/logistics/waste/list", props);
    }

    updateWasteReport(props: WasteReport) {
        return this.fetcher.post<{}>("/api/logistics/waste", props);
    }
}

export type DistributionMode = "all" | "focused"

export type WasteReport = {
    id: number;
    productName: string;
    logisticsDirective: string; // "warehouse" | "leave-at-supplier"

    reviewed: boolean;

    supplier: number | null;
    supplierName: string | null;
    poNumber: string | null;
    purchaseOrder: number | null;

    createdAt:     DateString;
    createdByName: string
    createdBy:     number;

    updatedAt: DateString;
    updatedBy: number;

    archived: boolean;

    qty: number;
    unitCostCents: number;
    markupCents: number | null;
    markupPercent: number | null;
    extendedPrice: number;

    note: string;
}

export type SourceMode = "to-order" | "stock"

export type DamageReport = {
    id: number;

    createdAt:     DateString;
    createdBy:     number;
    createdByName: string

    updatedAt: DateString;

    product:             number;
    productName:         string
    qty:                 number;
    receivedUnitPrice:   Cents;
    purchaseOrder:       number;
    purchaseOrderNumber: string
    supplier: string;

    project?: number;

    wePayPercent?: number | null;
    wePayPerUnitCents:   number;
    theyPayPerUnitCents: number;
    reviewed:     boolean;
    usableQty?:    number;

    archived: boolean;
}

export type ReceiveInput = {
    purchaseOrder: number;
    delivery: number

    items: Status[];
    extras: Extra[];
}

export type DriverRoutePendingItem = {
    id: number;
    pickupPo?: number;
    pickupPoNumber?: string;
    pickupCompany?: number;
    pickupCompanyName?: string;
    pickupDelivery?: number;
    dropProject?: number;
    dropProjectName?: string;
    qty: number;
    contents: ProductPreview[];
}

export type ProductPreview = {
    product: number;
    description: string;
    dimensions: string;
    qty: number;
    code: string;
    routeItem: number;
    project?: number;
    projectName?: string;

    purchaseOrderItem?: number;
    toWarehouse: boolean;
}

export type DriverRoute = {
    id: number;
    date: DateString;
    completed: boolean;
    routeLeftovers: null | ReceiveInput;
}

export type DriverRouteItem = {
    id: number;
    route: number;

    pickupPo?: number;
    pickupPoNumber?: string;
    pickupPoCompanyName?: string;

    dropPo?: number;
    dropPoNumber?: string;
    dropPoProject?: number;
    dropPoProjectName?: string;

    pickupDelivery?: number;
    pickupDeliveryProjectName?: string;

    dropDelivery?: number;
    dropDeliveryProject?: number;
    dropDeliveryProjectName?: string;

    completed: boolean;

    driverLog: null | ReceiveInput;
    contents: ProductPreview[];
    qty: number;
    sortOrder: number;

    archived: boolean;
}

type DashSummary = {
    qtyToOrder: number;
    activeOrderCount: number;
    qtyToRestock: number;
    centsOrdered7d: number;
    centsOrdered7dPrev: number;
    centsPicked7d: number;
    centsPicked7dPrev: number;
    centsDelivered7d: number;
    centsDelivered7dPrev: number;
}

export type ToDeliverItem = {
    id: number;
    source: string;

    product: number;
    name:             string;
    productCode:      string;
    dimensions:       string;

    doorSeries?: string;
    doorMaterial?: string;
    doorFinish?: string;
    doorGauge?: string;
    doorElevation?: string;
    doorCore?: string;
    doorSeam?: string;
    doorHanding?: string;
    doorLabel?: string;

    frameSeries?: string;
    frameMaterial?: string;
    frameGauge?: string;
    frameScreenElev?: string;
    frameProfile?: string;
    frameJambDepth?: string;
    frameConstruction?: string;
    frameHanding?: string;
    frameHardwarePreps?: string;
    frameLabel?: string;
    frameType?: string;

    qty: number;

    manufacturer: number;
    manufacturerName: string;

    project: number;
    projectName: string;

    doorName?: string;

    inventoryRequestIds: number[];

    description?: string;
    dimWidth?: string;
    dimHeight?: string;
    dimLength?: string;
    finish?: string;
    div10CornerGuardFinish?: string;
    div10CornerGuardType?: string;
    div10Description?: string;
    div10LockerBaseTrim?: string;
    div10LockerCoating?: string;
    div10LockerEndPanels?: boolean;
    div10LockerSloped?: boolean;
    div10LockerTiers?: string;
    div10MailboxLoading?: string;
    div10MailboxMounting?: string;
    div10MailboxParcelUnits?: number;
    div10MailboxTenantUnits?: number;
    div10Note?: string;
    div10PartitionCoating?: string;
    div10Room?: string;
}

export type DeliveryItem = {
    id: number;

    source: string;
    delivery: number;

    product:          number;
    description:         string;
    code:               string;
    dimensions:       string;

    kind?: InventoryKind;
    details?: DescriptionInput & {
        div10Room: string | null
    };
    openings?: {id: number, name: string}[];

    qty: number;

    manufacturer: number;
    manufacturerName: string;

    inventoryRequests?: number[];

    archived: boolean;
    updatedAt: DateString;
}

export type Delivery = {
    id: number;
    project: number;
    projectName: string;
    status: string;
    pickByDate: DateString;
    archived: boolean;
    updatedAt: DateString;
    updatedByName: string;
    customerNote: string;

    itemCount: number;
    hasEnoughInWarehouse: boolean;
    isMissingAnchors: boolean;
}

export type InventoryItem = {
    id: number;
    qty: number;
    stockMinQty: number;
    idealQty: number;
    isStocking: boolean;
    archived: boolean;

    project: number;
    projectName: string;
    door: string;

    product: number;

    name:             string;
    productCode:             string;
    dimensions:       string;

    isGroup: boolean;
    baseProduct?: number;

    warnReorder: boolean;
    warnLow: boolean;

    manufacturer: number;
    manufacturerName: string;

    belowMinSince?: DateString;

    projects: {
        projectId: number;
        projectName: string;
        inventoryRequestId: number; // pick a random inventory request
        qty: number;
    }[]
}

export type ToSourceItem = {
    id: number;

    qty: number;
    stockMinQty: number | null;
    idealQty: number | null;
    stockQty: number | null;
    reservedQty: number | null;
    purchasedQty: number | null;

    kind: string;

    projectMeta: {
        project: number;
        projectName: string;
        qty: number;
        neededBy: DateString;
    }[]

    source: string;

    product: number;
    name: string;
    description: string;
    productCode: string;
    finish: string
    dimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;
    partOfFrame: boolean;

    frameAnchorName?: string;
    frameAnchorDetail?: ToSourceItem[];

    keyingDetail?: string | null;

    manufacturer: number;
    manufacturerName: string;

    supplier: number;
    supplierName: string;

    project?: number;
    projectName?: string;

    openingId: number;
    openingFloor: string | null;
    openingName: string|null;

    neededBy: DateString | null;
    releasedBy: number | null;

    inventoryRequestId: number | null;
    lastPrice: Cents | null;
    lastPriceAtOthers: Cents | null;

    doorSeries: string|null;
    doorMaterial: string|null;
    doorFinish: string|null;
    doorGauge: string|null;
    doorElevation: string|null;
    doorCore: string|null;
    doorSeam: string|null;
    doorHanding: string|null;
    doorLabel: string|null;
    doorThickness: string|null;

    frameSeries: string|null;
    frameMaterial: string|null;
    frameGauge: string|null;
    frameScreenElev: string|null;
    frameProfile: string|null;
    frameJambDepth: string|null;
    frameConstruction: string|null;
    frameHanding: string|null;
    frameHardwarePreps: string|null;
    frameLabel: string|null;
    frameType: string|null;

    div10Category:           Div10Category | null | "";
    div10Room:               string;
    div10Product:            number;
    div10Note:               string;
    div10PartitionBracing:   string;
    div10PartitionCoating:   string;
    div10LockerTiers:        string;
    div10LockerSloped:       boolean;
    div10LockerEndPanels:    boolean;
    div10LockerBaseTrim:     boolean;
    div10LockerCoating:      string;
    div10MailboxLoading:     string;
    div10MailboxMounting:    string;
    div10MailboxTenantUnits: number;
    div10MailboxParcelUnits: number;
    div10CornerGuardType:    string;
    div10CornerGuardFinish:  string;
    div10Description:        string;
}

export function isPurchaseOrderItem(input: any): input is PurchaseOrderItem {
    return "purchaseOrder" in input;
}

export type PurchaseOrderItem = {
    id: number;
    purchaseOrder: number;
    reference: string;
    description: string;
    code: string;
    qty: number;
    qtyOutstanding: number;
    logisticsQtyOutstanding: number;
    unitPriceCents: number;
    archived: boolean;
    updatedAt: DateString;
    product?: number;

    details?: DescriptionInput & {
        div10Room: string | null
    };

    kind: InventoryKind; // will never be frame-anchor b/c frame & it's frame-anchor are combined

    doorSeries?: string | null;
    doorMaterial?: string | null;
    doorFinish?: string | null;
    doorGauge?: string | null;
    doorElevation?: string | null;
    doorCore?: string | null;
    doorSeam?: string | null;
    doorHanding?: string | null;
    doorLabel?: string | null;
    doorThickness?: string|null;

    frameSeries?: string | null;
    frameMaterial?: string | null;
    frameGauge?: string | null;
    frameScreenElev?: string | null;
    frameProfile?: string | null;
    frameJambDepth?: string | null;
    frameConstruction?: string | null;
    frameHanding?: string | null;
    frameLabel?: string | null;
    frameType?: string | null;

    dimWidth?: string | null;
    dimHeight?: string | null;
    dimLength?: string | null;

    hwKeyed?: boolean | null; // applies to hardware item
    hwPrepCutFile?: number|null;
    screenElevationFile?: number|null;
    doorElevationFile?: number|null;
    hwPrepGroupNumbers?: number[];
    inventoryRequests?: number[];
    openingIds?: number[];

    openings?: {
        id: number;
        name: string;
        seqNumber: number;
        locationTransition: string; // to / from
        qty: number;
        type: string; // single/pair...
        subType?: string; // active/inactive (for the purchase order item linked, not the whole opening)
        frameHardwarePreps: POHwPrepItem[];
        doorHardwarePreps: POHwPrepItem[];
        kind: InventoryKind;
        frameAnchorProductCode?: string | null;
        frameAnchorQtyOverride?: number | null
        hardwareGroup: number;
        hasCustomActiveHingeQty?: number;
        hasCustomInActiveHingeQty?: number;
        hardwarePrepFiles: number[];
    }[];

    createStockingInventoryRequestForProduct?: number;
}

export type InventoryKind = 'hardware'|'door'|'frame'|'div-10'|'frame-anchor'|"component";

export type PurchaseOrder = {
    id: number;
    poNumber: string;
    payableRefNumber: string;
    submittedAt: DateString | null;
    due: DateString | null;
    status: string;
    payableStatus: string;
    supplier: number;
    supplierName: string;
    supplierAddress: string;
    supplierCity: string;
    supplierProvince: string;
    supplierPostal: string;
    supplierSellsFrames: boolean;
    supplierSellsDoors: boolean;
    supplierSellsHardware: boolean;
    supplierSellsDiv10: boolean;
    deliveryMethod: string;
    totalCents: number;
    updatedAt: DateString;
    updatedBy: number;
    updatedByName: string;
    notes: string;
    archived: boolean;
    
    linkedProjects?: {name: string; id: number}[] | null;
};

export type InventoryRequest = {
    id: number;

    // opening
    opening: number;
    openingName: string;
    locationOne: string;
    locationTransition: string;
    locationTwo: string;

    // div 10
    div10: number;
    room: string;
    category: string;
    manufacturer: number;
    manufacturerName: string;
    productCode: string;
    name: string;
    finish: string;
    dimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;

    isStocking: boolean;
    isWaste: boolean;
    keyed: boolean;

    qty: number;
    receivedQty?: number;
    followupRequest?: number;
    status: string;
    updatedBy: string;
    updatedByName: string;
    updatedAt: DateString;
    neededBy: string;
    neededByCalcManuallySet: string | null;
    neededByCalcTimeline: string | null;
    neededByCalcReleaseSet: string | null;

    hardwareGroup?: string;

    type: InventoryKind;
}