import {api} from "../../../../api/API";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ChangesetContainer} from "./ChangesetContainer";
import {Alert, Button, DialogActions, LinearProgress, Typography} from "@mui/material";
import {useProjectId} from "../ProjectName";
import {getAll} from "../openings/Openings";
import {useChangesetId} from "./ChangesetId";
import {Center} from "./Center";
import {usePrintParallel} from "./PrintParallel";
import {first, orderByAscending, useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {getPricingPDFCustom, sectionToItem} from "../pricing/PricingActions";
import {PDFInfo} from "../../../../misc/pdf/PrintManager";
import {hardwarePDF} from "../../quote/hardware/HardwareTemplateBtn";
import {div10PDF} from "../div10/pdf/MakeSubmittal";
import {getKeyingPDF, KeyingPDFInfo, makeKeyCounts} from "../keying/submittal/KeyingSubmittal";
import {getSubmittalPreferences} from "../../../../misc/pdf/useSubmittalPreferences";
import {
    ExportColumn,
    getShopDrawingPDF, isShopDrawingPrefV1,
    makeDrawingInfo,
    openingColumns,
    ShopDrawingSubmittalPrefs
} from "../openings/MakeSubmittal";
import {getPDFClient} from "../../../../misc/pdf/parts/CoverPage";
import {Project} from "../../../../api/Projects";
import {ChangesetProvider} from "./ChangesetContext";
import {ProjectProvider} from "../../ProjectProvider";
import {MiscPrice} from "../../../../api/MiscPricing";

export async function getContractor(project: Project) {
    if(project.contractor) {
        return await api.companies.get({id: project.contractor})
    }

    return null;
}

export function PrintSubmissions() {

    return (
        <ProjectProvider>
            <ChangesetProvider>
                <PrintSubmissionsInner />
            </ChangesetProvider>
        </ProjectProvider>
    )
}

export function PrintSubmissionsInner() {
    const nav = useNavigate();
    const projectId = useProjectId();
    const sessionId = useChangesetId();
    const {printer, reset, loadingCount, totalCount, errors} = usePrintParallel();

    const action = useAsyncAction(async () => {
        reset();

        const projectP = api.projects.get(projectId);
        const clearAttachmentsP = api.projectChangeset.clearAttachmentDir({
            session: sessionId,
        })

        const clientP = projectP.then(project => getPDFClient(project));
        const hwP = api.hardware.listWithContents({project: projectId})

        const div10ItemP = projectP.then(project => getAll(null, offset => api.div10.list({
            project: project.id,
            offset: offset,
        })));

        const project = await projectP;
        await clearAttachmentsP
        const client = await clientP;
        let hw = await hwP;
        const div10Items = await div10ItemP;

        function add(info: PDFInfo) {
            info.serverInfo.attachToChangeset = sessionId;
            info.serverInfo.ignoreSubmissionDate = true;

            printer(info);
        }

        add(getPricingPDFCustom({
            project: project,
            data: async () => {

                const contractor = getContractor(project)
                const quoteNumber = api.projects.getProjectNumber({project: project.id});
                const {sections, notes, title} = await api.projectChangeset.getCustomerSummary({project: project.id})
                const data = sectionToItem(sections)
                if(notes.trim() !== "") {
                    data.push({
                        type: "generalNoteSection",
                        value: notes,
                        isFirst: false,
                        group: undefined
                    })
                }

                return {
                    data: data,
                    title: title,
                    project: project,
                    contractor: await contractor,
                    currentQuoteNumber: await quoteNumber,
                }
            },
        }))

        hw = hw.filter(h =>
            !!h.backup?.id ||
            h.hardware.some(v => !!v.backup) ||
            h.openings.some(v => v.sessionChanged)
        );

        add(await hardwarePDF({
            lastSubmittal: null,
            project: project,
            client: client,
            hardware: hw,
        }));

        // todo: filter for only changed items

        add(div10PDF({
            lastSubmittal: null,
            project: project,
            client: client,
            items: div10Items,
        }));

        add(getKeyingPDF({
            project,
            client,
            keyData: async () => {
                let keyData = await getAll(null, offset => api.keying.list({offset, project: project.id}))
                const names = await api.keying.listNames({
                    project: project.id,
                })

                keyData = keyData.filter(k => k.modifiedInCurrentChangeset)

                const prefs = await getSubmittalPreferences<KeyingPDFInfo>({
                    project: project.id,
                    kind: 'keying-schedule',
                });

                const result = makeKeyCounts(names, prefs.value || undefined);

                return {
                    data: keyData,
                    names: names,
                    info: {
                        keyCount: result,
                        version: 1 as 1,
                    }
                }
            },
            lastSubmission: null,
        }))

        add(getShopDrawingPDF({
            projectId: project.id,
            getData: async () => {
                const prefs = await getSubmittalPreferences<ShopDrawingSubmittalPrefs>({
                    project: project.id,
                    kind: 'shop-drawing',
                });
                let preamble = "";
                let selected = openingColumns;

                if(!prefs.value) {
                    // do nothing
                } else if (prefs.value instanceof Array) {
                    selected = prefs.value
                        .map(l => first(openingColumns, c => c.key === l))
                        .filter(v => !!v) as ExportColumn[]
                } else if(isShopDrawingPrefV1(prefs.value)) {
                    preamble = prefs.value.preamble;
                    selected = prefs.value.columns
                        .map(l => first(openingColumns, c => c.key === l))
                        .filter(v => !!v) as ExportColumn[]
                }

                let list = await getAll(null, offset => api.openings.list({
                    project: project.id,
                    offset
                }))

                list = list.filter(l => {
                    if(!l.backup) return false;
                    return selected.some(s => l[s.key] !== l.backup?.[s.key])
                })

                orderByAscending(list, l => l.seqNumber)

                return {
                    preamble,
                    columns: selected,
                    project,
                    drawingInfo: makeDrawingInfo(list),
                    lastSubmission: prefs.lastSubmission,
                    client,
                    data: list,
                }
            }
        }))

    }, []);

    const actionRef = useSyncedRef(action.callback)
    useEffect(() => {
        actionRef.current(null);
    }, [actionRef])

    const close = useAsyncAction(async (input: null | {force: true}) => {
        await api.projectChangeset.close({
            project: projectId,
            ignoreValidation: !!input?.force,
        });
        nav(`/project/${projectId}/shop-drawing/changeset/${sessionId}/receive-acceptance`);
    }, []);

    const canContinue = totalCount > 0 && loadingCount === 0;

    return (
        <ChangesetContainer stage="print-submissions">
            <Center>
                <div style={{padding: 16}}>
                    {!canContinue ? <>
                        <Typography variant="h6">Printing Submissions...</Typography>
                        <div>Please leave your window open while we render and attach this to your changeset.</div>
                    </> : <>
                        <Typography variant="h6">Submissions Printed</Typography>
                        <div>You can access the PDFs in the project contract files section.</div>
                    </>}
                </div>

                {(action.loading || action.error) && action.LoadingElement}

                {loadingCount > 0 && <LinearProgress value={loadingCount / totalCount * 100} />}
                {errors.length > 0 && <>
                    <div style={{height: 8}} />
                    <Alert color="error">
                        {errors.map(e => <div>{e}</div>)}
                    </Alert>
                    <div style={{height: 8}} />
                    <DialogActions>
                        <Button disabled={!canContinue} onClick={() => {
                            action.callback(null);
                        }}>Retry</Button>
                    </DialogActions>
                </>}

                <DialogActions>
                    {close.LoadingElement}
                    <Button disabled={!canContinue} onClick={() => nav(`/project/${projectId}/shop-drawing/changeset/${sessionId}/save-session`)}>Back</Button>
                    <Button disabled={!canContinue} variant="contained" color="primary" onClick={() => {
                        close.callback(null);
                    }}>Close Session</Button>
                    {close.error ? <Button disabled={!canContinue} color="error" onClick={() => {
                        close.callback({force: true});
                    }}>Danger: Force Close</Button> : null}
                </DialogActions>
            </Center>
        </ChangesetContainer>
    )

}

export function transformMiscPricesForPDF(list: MiscPrice[]): MiscPrice[] {
    return list.filter(m => m.backup && m.backup.extendedPrice !== m.extendedPrice).map(m => {
        return Object.assign({}, m, {
            extendedPrice: m.extendedPrice - (m.backup?.extendedPrice || 0),
        })
    });
}