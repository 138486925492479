import {QtyWithPreview} from "./QtyWithPreview";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import Clear from "@mui/icons-material/Clear";
import {DriverRoute, DriverRouteItem} from "../../../api/Logistics";
import {first, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {RefreshRouteItems} from "./RouteItems";
import {Grid, Checkbox, Button} from "@mui/material";
import {routePopupPanel} from "./RouteList";
import {Completed} from "./Completed";
import {css} from "@emotion/css";
import {useEffect, useState} from "react";
import {useFocusMode} from "../../project/shopdrawing/hardware/UseFocusMode";

export function PickupPoItemRow(props: {
    value: DriverRouteItem;
    list: DriverRouteItem[];
    route: DriverRoute;
}) {
    const snack = useSnackbar();
    const c = props.value;
    const projects: {
        project: number;
        projectName: string;
        count: number;
    }[] = [];

    c.contents.map(v => {
        const proj = first(projects, p => p.project === (v.project || 0));
        if(proj) {
            proj.count += v.qty;
            return;
        }

        projects.push({
            project: v.project || 0,
            projectName: v.projectName || "Warehouse",
            count: v.qty,
        })
    });

    const drops = props.list.filter(r => r.dropPo === c.pickupPo);
    const [checked, setChecked] = useState<number[]>([]); // purchaseOrderItem ids
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState("")
    const [refresh, setRefresh] = useState(0)

    useEffect(() => {
        const newChecked: number[] = [];

        props.value.contents.map(c => {
            if(!c.purchaseOrderItem) return;
            if(c.toWarehouse) newChecked.push(c.purchaseOrderItem);
        })

        setChecked(newChecked);
        setShow(newChecked.length !== 0 && newChecked.length !== props.value.contents.length);

    }, [props.value, refresh]);

    useEffect(() => {
        setMode(checked.length === 0 ? "site" :
            checked.length === props.value.contents.length ?
                "warehouse" : "split")
    }, [checked, props.value]);

    const [headerRef, setHeaderRef] = useState<HTMLElement|null>(null)
    const [editing, setEditing] = useState(false)

    useFocusMode({
        enabled: editing,
        focusRef: headerRef,
        adjustFocus: (input, retryLater) => {
            const tbody = input.parentElement;
            if(!tbody) {
                retryLater();
                return [];
            }

            const rows = tbody.querySelectorAll("tr[data-pickup='" + props.value.id.toString() + "']");
            return [input, ...Array.from(rows)];
        }
    })

    const save = useAsyncAction(async (input: {src: DriverRouteItem, checked: number[]}) => {

        await api.logistics.updateRouteItem(Object.assign({}, input.src, {
            setToWarehouse: input.checked,
        }));

        setEditing(false);
        RefreshRouteItems.emit(true);
    }, [])

    return (
        <>
            <tr ref={setHeaderRef}>
                <td>
                    PO #{c.pickupPoNumber}
                </td>
                <td>
                    <QtyWithPreview data={c} />
                </td>
                <td>
                    <Completed value={props.value} routeComplete={props.route.completed} />
                </td>
                <td>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end"}}>
                        <div className="actions">
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <select value={mode} onChange={(e) => {
                                        const value = e.target.value;
                                        setMode(value);
                                        setShow(true);
                                        setEditing(true)

                                        if(value === "warehouse") {
                                            // @ts-ignore
                                            setChecked(c.contents.map(c => c.purchaseOrderItem).filter(c => c));
                                        } else if(value === "site") {
                                            setChecked([])
                                        }
                                    }}>
                                        <option value="split">Split PO</option>
                                        <option value="warehouse">All to warehouse</option>
                                        <option value="site">All to site</option>
                                    </select>
                                </Grid>
                                <Grid item>
                                    <SimpleIconButton disabled={props.value.completed} onClick={async () => {
                                        try {
                                            const list = [
                                                Object.assign({}, c, {
                                                    archived: true
                                                })
                                            ];

                                            // archive drop at the same time
                                            drops.map(drop =>
                                                list.push(Object.assign({}, drop, {
                                                    archived: true
                                                })))

                                            await api.logistics.updateRouteItems(list)
                                        } catch (e: any) {
                                            snack.error(e.toString())
                                            return;
                                        }

                                        RefreshRouteItems.emit(null);
                                        routePopupPanel.sendToClient("refresh-to-move")
                                        snack.success("Updated")
                                    }}>
                                        <Clear />
                                    </SimpleIconButton>
                                </Grid>
                            </Grid>
                        </div>
                        {editing && <>
                            <div style={{width: 8}} />
                            <Button variant="outlined" style={{paddingTop: 2, paddingBottom: 2}} size="small"
                                    onClick={() => {
                                        setEditing(false);
                                        setRefresh(Date.now());
                                    }}
                            >Cancel</Button>
                            <div style={{width: 8}} />
                            <Button variant="contained"
                                    style={{paddingTop: 2, paddingBottom: 2}} size="small"
                                    onClick={() => {
                                        save.callback({
                                            checked: checked,
                                            src: props.value,
                                        })
                                    }}
                            >Save</Button>
                            {save.LoadingElement}
                        </>}
                    </div>
                </td>
            </tr>
            {show && <tr data-pickup={props.value.id.toString()} key="pickup-header" className={pickupHeaderClass + " " + pickupDataRowClass}>
                <th>Item</th>
                <th style={{textAlign: "right"}}>Qty</th>
                <th style={{textAlign: "center"}}>To Warehouse</th>
                <th></th>
            </tr>}
            {show && c.contents.map((content, index) => {
               return (<tr data-pickup={props.value.id.toString()} key={"pickup-choose" + index} className={pickupDataRowClass}>
                   <td>
                       {content.description}
                   </td>
                   <td>
                       {content.qty}
                   </td>
                   <td style={{paddingTop: 0, paddingBottom: 0, textAlign: "center"}}>
                       <Checkbox disableRipple size="small" checked={checked.indexOf(content.purchaseOrderItem || 0) !== -1} onChange={(e, isChecked) => {
                            if(!content.purchaseOrderItem) return;
                            setEditing(true);

                            if(isChecked) {
                                 setChecked([...checked, content.purchaseOrderItem]);
                            } else {
                                 setChecked(checked.filter(c => c !== content.purchaseOrderItem));
                            }
                       }} />
                   </td>
                   <td></td>
               </tr>)
            })}
        </>
    )
}

const pickupHeaderClass = css({
    "& th": {
        textAlign: "left",
    },
})

const pickupDataRowClass = css({
    "& .MuiCheckbox-root": {
        padding: 0,
    }
})