import {QtyWithPreview} from "./QtyWithPreview";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import Clear from "@mui/icons-material/Clear";
import {DriverRoute, DriverRouteItem} from "../../../api/Logistics";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {RefreshRouteItems} from "./RouteItems";
import {first} from "nate-react-api-helpers";
import {routePopupPanel} from "./RouteList";
import {Completed} from "./Completed";

export function PickupDeliveryRow(props: {
    value: DriverRouteItem;
    list: DriverRouteItem[];
    route: DriverRoute;
}) {
    const snack = useSnackbar();


    const c = props.value;
    const pu = first(props.list, l => l.dropDelivery === c.pickupDelivery);

    return (
        <tr>
            <td>Pack #{c.pickupDelivery} ({c.pickupDeliveryProjectName})</td>
            <td>
                <QtyWithPreview data={c} />
            </td>
            <td>
                <Completed value={props.value} routeComplete={props.route.completed} />
            </td>
            <td>
                <div className="actions">
                    <SimpleIconButton disabled={props.value.completed} onClick={async () => {
                        try {
                            const list = [
                                Object.assign({}, c, {
                                    archived: true
                                })
                            ];

                            // archive pickup at the same time
                            if(pu) {
                                list.push(Object.assign({}, pu, {
                                    archived: true
                                }))
                            }

                            await api.logistics.updateRouteItems(list)
                        } catch (e: any) {
                            snack.error(e.toString())
                            return;
                        }

                        RefreshRouteItems.emit(null);
                        routePopupPanel.sendToClient("refresh-to-move")
                        snack.success("Updated")
                    }}>
                        <Clear />
                    </SimpleIconButton>
                </div>
            </td>
        </tr>
    )
}