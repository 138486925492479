import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../api/API";
import {DeliverOrReceive} from "../receiving/DeliverOrReceive";

export function PickupDelivery() {
    const params = useParams<{
        id: string;
        stopNumber: string;
        routeItem: string;
        deliveryId: string;
    }>();

    const backURL = `/logistics/driver/${params.id}/${params.stopNumber}`;
    const nav = useNavigate();

    return <DeliverOrReceive backURL={backURL}
                             routeItem={parseInt(params.routeItem || "0")}
                             delivery={parseInt(params.deliveryId || "0")}
                             submitTitle="Pickup Delivery"
                             onSubmit={async input => {
                                 await api.logistics.completeRouteItem({
                                     routeItem: parseInt(params.routeItem|| "0"),
                                     route: parseInt(params.id || "0"),
                                     details: input,
                                 })

                                 nav(backURL);
                             }} />
}