import {Delivery, DeliveryItem} from "../../../api/Logistics";
import {removeRowOption, RowOptionCol} from "../purchasing/RowOptions";
import {
    BoolCol,
    NumberCol,
    PrefixSearch,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../misc/scroller/Table";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import {useContext, useEffect} from "react";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";
import {isEditable} from "./DeliveryDetail";
import {useSyncedRef} from "../../../misc/SyncedRef";

export const deliveryItemsTableName = "logistics.delivery-items";

export function DeliveryItems(props: {
    delivery: Delivery;
    onReload(): void;
}) {
    const ctx = useContext(TableSelectionContext);
    const refreshRef = useSyncedRef(ctx.refresh);
    const onReloadRef = useSyncedRef(props.onReload);
    const deliveryIsEditable = isEditable(props.delivery);
    const deliveryId = props.delivery.id;
    const projectId = props.delivery.project;

    useEffect(() => {
        if(!deliveryIsEditable) return;

        deliveriesPopoutPanel.open();

        deliveriesPopoutPanel.sendToClient({
            delivery: deliveryId,
            project: projectId,
        });

        const sub = deliveriesPopoutPanel.fromClient.subscribeAndFireLast(input => {
            if(input === "refresh-delivery") {
                refreshRef.current(deliveryItemsTableName)
            }
        });

        const sub2 = deliveriesPopoutPanel.fromClient.subscribe(input => {
            if(input === "refresh-delivery") {
                onReloadRef.current();
            }
        });

        return () => {
            sub2.cancel();
            sub.cancel();
            deliveriesPopoutPanel.close();
        }
    }, [refreshRef, deliveryIsEditable, deliveryId, projectId, onReloadRef]);

    return (
        <>
            <Table<DeliveryItem>
                name={deliveryItemsTableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [deliveryItemsTableName],
                                onComplete: () => deliveriesPopoutPanel.sendToClient("refresh-to-source"),
                            })
                        ],
                    }),
                    ViewOnly(StringCol("Description", "description", 200)),
                    ViewOnly(PrefixSearch(PrefixSearch(StringCol("Code", "code", 80)))),
                    ViewOnly(StringCol("Dim.", "dimensions", 80)),
                    ViewOnly(NumberCol("Qty", "qty", 80)),
                    ViewOnly(StringCol("Source", "source", 80)),
                    ViewOnly(StringCol("Supplier", "manufacturerName", 200)),
                    ViewOnly(BoolCol("Has Enough Inventory", "hasEnoughInWarehouse", 80)),
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listDeliveryItems({
                    offset,
                    delivery: props.delivery.id,
                }))}
                fetchDeps={[props.delivery.id]}
                onChange={async v => {
                    const r = await api.logistics.updateDeliveryItem(v)
                    onReloadRef.current();
                    return r;
                }}
                noHorizontalScroller
                noFooter
            />
        </>
    )
}