import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../api/API";
import {DeliverOrReceive} from "../receiving/DeliverOrReceive";
import {AsyncResult2, first, orderByStrAscending, selectMany} from "nate-react-api-helpers";
import {useGetAll} from "../routes/useGetAll";
import {useMemo} from "react";
import {ProductPreview} from "../../../api/Logistics";
import {Status} from "../receiving/ReceivePO";

export function DropAtProject() {
    const params = useParams<{
        id: string;
        stopNumber: string;
        projectId: string;
    }>();

    const backURL = `/logistics/driver/${params.id}/${params.stopNumber}`;
    const nav = useNavigate();

    const list = useGetAll(input => {
        return api.logistics.listRouteItems(input as any)
    }, {route: params.id, forProject: params.projectId}, [params.projectId, params.id])

    const preMergeList = useMemo(() => {
        return selectMany(list.result?.map(r => r.contents) || [], v => v)
    }, [list.result]);

    const transformed: AsyncResult2<ProductPreview[]> = useMemo(() => {
        let result = preMergeList
        result = combineByProduct(result)

        console.log(
            preMergeList.map(m => ({product: m.product, qty: m.qty})),
            result.map(m => ({product: m.product, qty: m.qty}))
        )

        return Object.assign({}, list, {
            result: result,
            asList: result || [],
        })
    }, [list, preMergeList])

    return <DeliverOrReceive backURL={backURL}
                             title={first(transformed.asList, v => true)?.projectName || "Loading..."}
                             submitTitle="Drop Delivery"
                             list={transformed}
                             onSubmit={async input => {

                                 const byRouteItem = splitProductsByRouteItem(input.items, preMergeList)

                                 const promises = byRouteItem.map((ri, index) => {
                                     return api.logistics.completeRouteItem({
                                         routeItem: ri.routeItem,
                                         route: parseInt(params.id || "0"),
                                         details: {
                                            purchaseOrder: 0,
                                            delivery: 0,
                                            items: ri.status,
                                            extras: index === 0 ? input.extras : [],
                                         },
                                     })
                                 })

                                 await Promise.all(promises);
                                 nav(backURL);
                             }} />
}

type ByRouteItem = {
    status: Status[];
    routeItem: number;
}

function splitProductsByRouteItem(list: Status[], hint: ProductPreview[]): ByRouteItem[] {
    const result: ByRouteItem[] = [];

    hint.forEach(h => {
        const status = list.find(l => l.product === h.product)
        if(!status) return;

        let qty = status.received
        if(qty > h.qty) {
            qty = h.qty
        }

        status.received -= qty;

        let damaged = status.damaged;
        let statusStr = status.status

        if(status.damaged && status.damaged > 0) {
            status.damaged = undefined;
            status.status = "ok";
        }

        let resultItem = result.find(r => r.routeItem === h.routeItem);
        if(!resultItem) {
            resultItem = {
                status: [],
                routeItem: h.routeItem,
            }

            result.push(resultItem);
        }

        resultItem.status.push({
            product: h.product,
            productName: h.description,
            damaged,
            received: qty,
            status: statusStr,
        })
    })

    return result;
}

export function combineByProduct(list: ProductPreview[]): ProductPreview[] {
    const result: ProductPreview[] = [];

    list.forEach(item => {
        const existing = result.find(r => r.product === item.product && r.description === item.description);
        if(existing) {
            existing.qty += item.qty;
        } else {
            result.push(Object.assign({}, item));
        }
    })

    orderByStrAscending(result, v => v.description);
    return result;
}