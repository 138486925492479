import {Alert, Button, Grid, TextField} from "@mui/material";
import { grey } from "@mui/material/colors";
import {useContext, useEffect, useState} from "react";
import {CheckboxContext} from "./Checkbox";
import {useSnackbar} from "../../../../misc/Snackbar";
import {api} from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {tabToCategory} from "./DoorTable";
import {ChangesetContext} from "../changeset/ChangesetContext";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Moment} from "moment";

export function ReleaseBox(props: {
    onUpdated(): void;
    type: string;
}) {

    const ctx = useContext(CheckboxContext)
    const [selected, setSelected] = useState<number[]>([]);
    const [authorizedBy, setAuthorizedBy] = useState("")
    const [note, setNote] = useState("")
    const [neededBy, setNeededBy] = useState<string|null>(null)
    const projectId = useProjectId();

    const listenAll = ctx.listenAll;
    useEffect(() => {
        const sub = listenAll(setSelected)
        return () => sub.cancel();
    }, [listenAll]);

    const snack = useSnackbar();
    const changesetCtx = useContext(ChangesetContext)

    return (
        <div style={{backgroundColor: grey["200"], width: "100%", padding: 8, paddingTop: 16}}>
            <form onSubmit={async e => {
                e.preventDefault();
                if(!authorizedBy) return;
                if(selected.length === 0) return;

                try {
                    snack.loading();

                    await api.openings.release({
                        authorizedBy,
                        note,
                        neededBy: neededBy,
                        type: tabToCategory(props.type),
                        frameForOpenings: tabToCategory(props.type) === "frame" ? selected : [],
                        doorForOpenings: tabToCategory(props.type) === "door" ? selected: [],
                        requests: tabToCategory(props.type) === "hardware" || tabToCategory(props.type) === "div10" || tabToCategory(props.type) === "frame-anchor" ? selected : [],
                        project: projectId,
                        unRelease: false,
                    })

                    props.onUpdated();
                    snack.success("Updated");

                } catch (e: any) {
                    snack.error(e.toString());
                }
            }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <TextField value={authorizedBy} onChange={e => setAuthorizedBy(e.target.value)} label="Authorized By" InputLabelProps={{shrink: true}} size="small" />
                    </Grid>
                    <Grid item>
                        <TextField value={note} onChange={e => setNote(e.target.value)} label="Note" style={{width: 400}} InputLabelProps={{shrink: true}} size="small" />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            label="Needed By (optional)"
                            onChange={(value: any) => {
                                if(!value) {
                                    setNeededBy(null);
                                } else {
                                    setNeededBy((value as Moment).toJSON())
                                }
                            }}
                            inputFormat="YYYY-MM-DD"
                            value={neededBy}
                            renderInput={(props) => <TextField {...props} size="small" />} />
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" disableElevation disabled={changesetCtx.proposing || selected.length === 0 || !authorizedBy} color="primary">{selected.length > 0 ? <>Release {selected.length} items</> : <>Release</>}</Button>
                    </Grid>
                </Grid>
            </form>

            {changesetCtx.proposing && <Alert severity="error" style={{marginTop: 8}}>
                You have pending changes that require approval. Please resolve these before you release items.
            </Alert>}
        </div>
    )
}