import Info from "@mui/icons-material/Info";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import {useState} from "react";
import {Popover} from "@mui/material";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {ProductPreview, ReceiveInput} from "../../../api/Logistics";
import {DriverLogExtras} from "./DriverLogExtras";
import {DriverLogDetail} from "./DriverLogDetail";
import {sum} from "nate-react-api-helpers";

type Input = {
    qty: number;
    contents: ProductPreview[];
    driverLog?: null | ReceiveInput;
};

export function QtyWithPreview(props: {
    data: Input;
    dropoff?: boolean;
}) {
    const [preview, setPreview] = useState<HTMLElement|null>(null);
    const contents = props.dropoff ? props.data.contents.filter(c => {
        return !c.toWarehouse;
    }) : props.data.contents;
    const qty = sum(contents.map(c => c.qty));

    return (
        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            {qty} pcs&nbsp;

            <SimpleIconButton
                onMouseEnter={(e: any) => {
                    setPreview(e.target as any);
                }}
                onMouseLeave={() => setPreview(null)}
            >
                <Info />
            </SimpleIconButton>

            {preview && <QtyPreview contents={contents} driverLog={props.data.driverLog} onClose={() => setPreview(null)} anchorEl={preview} />}
        </div>
    );
}

export function QtyPreview(props: {
    contents: ProductPreview[];
    driverLog?: null | ReceiveInput;
    anchorEl: any;
    onClose(): void;
}) {
    const dl = props.driverLog;

    return (
        <Popover open={true}
                 anchorEl={props.anchorEl}
                 sx={{
                     pointerEvents: 'none',
                 }}
                 disableRestoreFocus
                 onClose={props.onClose}
                 anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                 }}
                 transformOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}
        >
            <div style={{padding: 4}}>
                <table className={tableClass}>
                    <thead>
                    <tr>
                        <td>Item</td>
                        <td>Qty</td>
                        {dl && <td>Received</td>}
                    </tr>
                    </thead>
                    <tbody>
                    {props.contents.map(c => <tr>
                        <td>{c.description}</td>
                        <td style={{textAlign: "right"}}>{c.qty}</td>
                        <td style={{textAlign: "right"}}>
                            {dl && <DriverLogDetail log={dl} content={c} />}
                        </td>
                    </tr>)}
                    {dl && <DriverLogExtras contents={props.contents} log={dl} />}
                    </tbody>
                </table>
            </div>
        </Popover>
    )
}

// @ts-ignore
const tableClass = css({
    fontSize: "0.8rem",
    cellSpacing: 0,
    borderCollapse: "collapse",

    "& > thead": {
        fontWeight: "bold",

        "& td": {
            borderBottom: "1px solid " + grey["400"],
        }
    },

    "& > tbody > tr:not(:last-child) > td": {
        borderBottom: "1px solid " + grey["200"]
    },

    "& > * > tr > td": {
        paddingLeft: 2, paddingRight: 4,
    }
})