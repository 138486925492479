import {useRouteInfo} from "./RouteDetail";
import {
    Alert,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton,
} from "@mui/material";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {Link, useNavigate, useParams} from "react-router-dom";
import { css } from "@emotion/css";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import { StopHeader } from "./StopHeader";
import {rowStyle} from "./RouteList";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {CheckCircle} from "@mui/icons-material";
import {green} from "@mui/material/colors";
import {useCallback, useEffect, useState} from "react";
import {DriverRouteItem} from "../../../api/Logistics";
import {useSnackbar} from "../../../misc/Snackbar";

function isDrop(c: DriverRouteItem) {
    return c.dropPo || c.dropDelivery
}

export function RouteStops() {
    const {
        grouped,
        delivery,
        rawItems,
        id
    } = useRouteInfo();
    const nav = useNavigate();
    const params = useParams<{stopNumber: string}>();
    const index = parseInt(params.stopNumber || "0");
    const remaining = useAsync2((props) => api.logistics.listDeliveryItemsRemainingOnTruck(props), {
        route: id,
    }, [id]);

    const hasPending = (grouped[index]?.children.filter(c => !c.completed).length || 0) > 0;
    const routeHasPending = grouped.filter(g => g.children.filter(c => !c.completed && isDrop(c)).length > 0).length > 0;
    const snack = useSnackbar();

    const finishRoute = useCallback(async () => {
        snack.loading();
        try {
            const delivery = await api.logistics.getRoute({id: id});
            if(!delivery) throw new Error("missing result");

            await api.logistics.completeRoute({
                route: delivery.id,
                details: {
                    purchaseOrder: 0,
                    delivery: 0,
                    items: [],
                    extras: [],
                }
            });

            snack.success("Updated");
            nav("/logistics/driver/success");
        } catch (e: any) {
            snack.error(e.toString())
        }
    }, [snack, id, nav]);

    const showFinish = index+1 >= grouped.length && !(remaining.asList.length > 0 || routeHasPending);
    const next = useCallback(() => {
        if(index+1 >= grouped.length) {
            if(remaining.asList.length > 0 || routeHasPending) {
                nav(`/logistics/driver/${id}/receive-at-warehouse`)
                return
            }

            finishRoute();
            return;
        }

        nav(`/logistics/driver/${id}/${index+1}`)
    }, [nav, grouped, id, index, remaining.asList.length, routeHasPending, finishRoute]);

    const [showConfirmNext, setShowConfirmNext] = useState(false)

    useEffect(() => {
        if(!grouped || grouped.length < index) return;
        const group = grouped[index];
        if(!group) return;


        const list = group.children
        for(let i = 0; i < list.length; i++) {
            if(list[i].completed) continue;

            const url = routeForItem(list[i], id, index)
            if(url.indexOf("drop-at-project") !== -1) {
                nav(url)
                return;
            }
        }

    }, [grouped, index, nav, id]);

    return (
        <MobileContainer topCenter={delivery.result ? "#" + delivery.result.id : "Loading.."} back={`/logistics/driver/${id}`}>
            <div style={{flex: 1, overflow: "auto"}}>
                <Loader {...rawItems} />
                <Loader {...delivery}>
                    {() => {
                        if(index >= grouped.length) {
                            return (
                                <div style={{padding: 8}}><Alert>Invalid URL, please go back and try again</Alert></div>
                            )
                        }

                        const item = grouped[index];
                        return <div style={{padding: 4}}>
                            <StopHeader>
                                {item.company}
                            </StopHeader>
                            <div style={{height: 4}} />
                            <div className={cardStyle} style={{flex:1, overflow: "auto", padding: 0}}>
                                {item.children.map(c => {

                                    let route = routeForItem(c, id, index)

                                    return (
                                        <Link to={route} style={{textDecoration: "none", color: "inherit"}}>
                                            <div className={rowStyle} style={{display: "flex"}}>
                                                <div style={{flex: 1}}>
                                                    {c.pickupPoNumber && `Pickup PO #${c.pickupPoNumber}`}
                                                    {c.pickupDeliveryProjectName && `Delivery #${c.pickupDelivery} (${c.pickupDeliveryProjectName})`}
                                                    {c.dropDelivery && `Delivery from warehouse for ${c.dropDeliveryProjectName}`}
                                                    {c.dropPo && `Parts of PO #${c.dropPoNumber} for ${c.dropPoProjectName}`}
                                                </div>
                                                <div>
                                                    {c.completed && <IconButton style={{color: green["700"]}}><CheckCircle /></IconButton>}
                                                    {!c.completed && <IconButton><ChevronRight /></IconButton>}
                                                </div>
                                            </div>
                                        </Link>
                                    )

                                })}
                            </div>
                        </div>
                    }}
                </Loader>
            </div>
            <div style={{padding: 8}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" disabled={index===0} color="secondary"
                                onClick={() => {
                                    nav(`/logistics/driver/${id}/${index-1}`)
                                }}>
                            Prev Stop
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={hasPending ? "warning" : "secondary"}
                            onClick={() => {
                                if(hasPending) {
                                    setShowConfirmNext(true);
                                    return;
                                }

                                next();
                            }}
                        >
                            {showFinish ? "Finish" : "Next Stop"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {showConfirmNext && <Dialog open>
                <DialogTitle>Do you want to skip part of this stop?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are still uncompleted pickups/deliveries on this stop.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="warning" onClick={() => {
                                setShowConfirmNext(false)
                                next()
                            }}>Ignore Stop</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="secondary" onClick={() => setShowConfirmNext(false)}>Go Back</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>}
        </MobileContainer>
    )
}

function routeForItem(c: DriverRouteItem, id: number, index: number) {
    if(c.pickupPo) {
        return `/logistics/driver/${id}/${index}/${c.id}/pickup-po/${c.pickupPo}`;
    } else if(c.dropPo) {
        return  `/logistics/driver/${id}/${index}/${c.id}/drop-at-project/${c.dropPoProject}`;
    } else if(c.dropDelivery) {
        return  `/logistics/driver/${id}/${index}/${c.id}/drop-at-project/${c.dropDeliveryProject}`;
    } else if(c.pickupDelivery) {
        return  `/logistics/driver/${id}/${index}/${c.id}/pickup-delivery/${c.pickupDelivery}`;
    }

    return "";
}

export const cardStyle = css({
    padding: 8,
    backgroundColor: "white",
    // border: "1px solid " + grey["300"],
    // borderRadius: 4,
})