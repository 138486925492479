import {useNavigate, useParams} from "react-router-dom";
import {first, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useGetAll} from "../routes/useGetAll";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {cardStyle} from "../driver/RouteStops";
import {
    Button,
} from "@mui/material";
import {useState} from "react";
import {PurchaseOrderItem, ReceiveInput} from "../../../api/Logistics";
import {EditReceivingItem} from "./EditReceivingItem";
import {CreatePOItem, CreateProductItem} from "./CreatePOItem";
import {ReceiveItem, TItem} from "./ReceiveItem";
import {grey} from "@mui/material/colors";
import {useSyncedRef} from "../../../misc/SyncedRef";

// this type is used serverside too, don't mess with this without updating server
export type Status = {
    product: number;
    productName: string; // used to differentiate frames with different preps that have same product id
    status: "ok" | "damaged" | "back-order",
    received: number;
    damaged?: number;
}

export type Extra = {
    status: Status;
    product: CreateProductItem;
}

export function ReceivePO() {
    const params = useParams<{id: string}>();
    const id = parseInt(params.id || "0");
    const nav = useNavigate();

    return (
        <ReceivePOInner
            id={id}
            onSubmit={async (input) => {
                await api.logistics.receivePO(input);
                nav("/logistics/receiving")
            }}
            backURL="/logistics/receiving"
        />
    )
}

export function ReceivePOInner(props: {
    id: number;
    backURL: string;
    onSubmit: (input: ReceiveInput) => Promise<any>
}) {
    const po = useAsync2(props => api.logistics.getPO(props), {
        id: props.id
    }, [props.id]);
    const items = useGetAll(props => api.logistics.listLogisticsPurchaseOrderItems(props), {
        purchaseOrder: props.id,
        forLogistics: true,
    }, [props.id]);
    const [status, setStatus] = useState<Status[]>([]);
    const [contextMenu, setContextMenu] = useState<PurchaseOrderItem|null>(null);
    const [extras, setExtras] = useState<Extra[]>([]);
    const [showCreate, setShowCreate] = useState(false);
    const submitRef = useSyncedRef(props.onSubmit);

    const submit = useAsyncAction( (input) => {
        return submitRef.current(input);
    }, []);

    const renderList = (items.asList ||[]).filter(v => v.logisticsQtyOutstanding !== 0)

    return (
        <MobileContainer topCenter={"#" + po.result?.poNumber} back={props.backURL}>
                <Loader {...po}>
                    {value => <div className={cardStyle} style={{borderBottom: "1px solid " + grey["500"]}}>
                        <div style={{fontSize: "1.5rem", fontWeight: 500}}>{value.supplierName}</div>
                        <div style={{fontSize: "1rem"}}>PO #{value.poNumber}</div>
                    </div>}
                </Loader>
                {contextMenu && <EditReceivingItem status={first(status, v => v.product === contextMenu.product && v.productName === contextMenu.description) || {
                    status: "ok",
                    product: contextMenu.product || -1,
                    productName: contextMenu.description || "",
                    received: contextMenu.qty,
                    damaged: undefined,
                }} onChange={v => {
                    setStatus(old => [...old.filter(s => s.product !== v.product), v])
                    setContextMenu(null);
                }} onCancel={() => setContextMenu(null)} />}
                <div style={{flex: 1}}>
                    <Loader {...items}>
                        {() => <div className={cardStyle} style={{padding: 0}}>
                            <div style={{padding: 4, fontSize: "0.9rem"}}>Long press items for more options</div>
                            <table style={{width: "100%", borderCollapse: "collapse"}}><tbody>
                            {renderList.map(v => {
                                    let value = first(status, s => s.product === v.product && s.productName === v.description);
                                    const receiveItem = Object.assign({}, v, {
                                        qty: v.logisticsQtyOutstanding,
                                    })

                                    return (
                                        <ReceiveItem pickup item={receiveItem as TItem} value={value} onEdit={setContextMenu as any} onStatusChange={updatedStatus => {
                                            const updated = Object.assign(updatedStatus, {product: v.product}) as Status;

                                            if(value) {
                                                setStatus(old =>
                                                    old.map(o => o.product === v.product && o.productName === v.description ?
                                                        updated
                                                        : o)
                                                );
                                            } else {
                                                setStatus(old => [...old, updated])
                                            }

                                        }} />
                                    );
                                })}
                                {extras.map(e => {
                                    return (
                                        <ReceiveItem item={e.product} value={e.status} onEdit={() => {}} onStatusChange={updatedStatus => {
                                            if(updatedStatus.received === 0) {
                                                setExtras(old => old.filter(o => o !== e));
                                                return;
                                            }

                                            e.status = updatedStatus;
                                            setExtras(old => old.slice(0));
                                        }} />
                                    );
                                })}
                            </tbody></table>
                        </div>}
                    </Loader>
                </div>
                {showCreate && <CreatePOItem manufacturer={po.result?.supplier || null} onCancel={() => setShowCreate(false)} onDone={(item, qty) => {
                    setShowCreate(false);

                    item.qty = qty;

                    setExtras(old => [...old, {
                        status: {
                            product: item.product,
                            productName: item.description,
                            status: "ok",
                            received: qty,
                        },
                        product: item,
                    }])
                }} />}

                <div className={cardStyle} style={{padding: 16}}>
                    <Button color="secondary" variant="contained" fullWidth onClick={() => setShowCreate(true)}>
                        Add Item
                    </Button>
                    <Loader {...submit} />
                    <div style={{height: 16}} />
                    <Button color="secondary" variant="contained"
                            fullWidth
                            disabled={status.length !== renderList.length}
                            onClick={() => {
                        let allStatus = status;
                        const toAdd = items.asList.filter(i => !first(status, s => s.product === i.product));

                        allStatus.push(...toAdd.map(a => ({
                            status: "ok" as any,
                            received: 0,
                            product: a.product,
                            productName: a.description,
                        })))

                        submit.callback({
                            purchaseOrder: props.id,
                            items: allStatus,
                            extras: extras,
                        })
                    }}>Receive Order</Button>
                </div>
        </MobileContainer>
    )
}