import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {InventoryItem} from "../../../api/Logistics";
import {useRef, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";

export function QtyEdit(props: {
    value: InventoryItem;
    update(input: InventoryItem): Promise<void>;
    onDone(): void;
}) {
    const [value, setValue] = useState("")

    const update = useRef(props.update);
    update.current = props.update;

    const obj = useRef(props.value);
    obj.current = props.value;

    const onDone = useRef(props.onDone);
    onDone.current = props.onDone;

    const action = useAsyncAction(async (qty: string) => {
        let qtyNum = parseInt(qty, 10);
        if(isNaN(qtyNum) || qtyNum < 0) throw new Error("Invalid number");

        if(qtyNum === 0) {
            qtyNum = -1; // use -1 to signal 0 to backend (see backend for more notes)
        }

        await update.current(Object.assign({}, obj.current, {
            overrideQty: qtyNum,
        }));

        onDone.current();
    }, []);

    return (
        <Dialog open>
            <DialogTitle>
                Override Qty
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to override the inventory level? This will affect shipping, receiving and
                    project planning.
                </DialogContentText>
                <div style={{height: 8}} />
                <div>
                    <TextField
                        variant="outlined"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        autoComplete="false"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {action.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button onClick={() => {
                    action.callback(value);
                }} color="error">Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}