import {TextField} from "@mui/material";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useEffect, useRef, useState} from "react";
import {api} from "../../../api/API";

export function DeliveryNotes(props: {
    id: number;
}) {
    const [value, setValue] = useState("")

    const get = useAsync2((input) => {
        return api.logistics.getDelivery(input)
    }, {
        id: props.id
    }, [props.id])

    const fromServer = get.result;

    useEffect(() => {
        setValue(fromServer?.customerNote || "");
    }, [fromServer])

    const timer = useRef<any>(null);
    const update = useAsyncAction(async (input) => {
        return api.logistics.updateDeliveryCustomerNote(input)
    }, [])

    const textUpdated = (value: string) => {
        setValue(value)

        if(timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            timer.current = null;

            update.callback({
                id: props.id,
                customerNote: value,
            })
        }, 1000);
    }

    return (
        <div style={{padding: 8, paddingTop: 0}}>
            {get.LoadingOrErrorElement || <TextField label="Customer Note" multiline onChange={e => {
                textUpdated(e.target.value)
            }} value={value} rows={5} fullWidth />}
        </div>
    )
}