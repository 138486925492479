import {hasPermission, useUser} from "../../../../misc/Permission";
import {Table} from "../../../../misc/scroller/Table";
import React, {useContext} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize2} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {
    AddRider,
    commonGroupBy2, commonPricingColumns2,
    PriceCol,
    PriceRow, RiderRowTitle,
    ViewPriceCol
} from "../../shopdrawing/pricing/Frames";
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import {getQuotePricing, quotePriceChange} from "./QuotePriceRiderUtil";

export function QuoteFrames() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();
    const customize = usePriceCellCustomize2();
    if(!u) return null;

    return (
        <Table<PriceRow>
            name={priceTableName(project, "frame")}
            globalPrefsName={priceTableName(0, "frame")}
            cellCustomize={customize}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={(ctx) => getQuotePricing(ctx, {
                project: project,
                alternative: alternative,
                customSort: (rs) => {
                    rs.sort((a, b) => {
                        if(a.productType === b.productType) return 0;
                        if(a.productType === "frame-anchor") return 1;
                        if(b.productType === "frame-anchor") return -1;
                        return 0;
                    })
                }
            })}
            columns={[
                PriceCol({
                    name: "Series",
                    price: (dt: QuotePriceLine) => {
                        if(dt.productType === "frame-anchor") return `Anchor: ${dt.productCode}`;
                        if(dt.productType === "frame-prep") return `${dt.productName}: ${dt.productCode}`;
                        return dt.frame.series
                    },
                    rider: (dt: QuotePriceRider, row: PriceRow) => {
                        return <RiderRowTitle row={row} />
                    },
                    createRider: (row) => {
                        return <AddRider row={row} />
                    },
                    width: 200,
                }),
                ViewPriceCol("Material", "frame.material"),
                ViewPriceCol("Gauge", "frame.gauge"),
                ViewPriceCol("Screen Elev", "frame.screenElev"),
                ViewPriceCol("Profile", "frame.profile"),
                ViewPriceCol("Jamb Depth", "frame.jambDepth"),
                ViewPriceCol("Construction", "frame.construction"),
                ViewPriceCol("Handing", "frame.handing"),
                ViewPriceCol("Label", "frame.label"),
                ViewPriceCol("Type", "frame.type"),
                ViewPriceCol("Height", "frame.height"),
                ViewPriceCol("Width", "frame.width"),
                ...commonPricingColumns2(u, true),
            ]}
            fetchDeps={[project, alternative]}
            columnDeps={[u]}
            groupBy={Object.assign({}, commonGroupBy2, {
                groupFilter: (groupName: string) =>
                    groupName === "frame" || groupName === "frame-anchor" ||
                    groupName === "project" || groupName === "frame-totals" || groupName === "frame-prep",
            })}
            onChange={input => quotePriceChange(input, {
                project: project,
                alternative: alternative
            })}
        />
    )
}

