import {ShopContainer} from "../ShopContainer";
import {PriceLine} from "../../../../api/Pricing";
import {parseCents} from "nate-react-api-helpers";
import React, {useState} from "react";
import {TableCellTextPopper} from "../../../../misc/scroller/TableEditCell";
import { PricingActions } from "./PricingActions";
import {useUser} from "../../../../misc/Permission";
import {MiscProduct} from "../../../../api/MiscProducts";
import {Frames, PriceLine2, PriceRow} from "./Frames";
import {Doors} from "./Doors";
import {Hardware} from "./Hardware";
import {QuotePricingSwitcher} from "../../quote/pricing/QuotePricingSwitcher";
import {useQueryParam} from "../../quote/pricing/QueryParam";
import {Div10} from "./Div10";
import {Summary} from "./Summary";
import {CostHelperProvider} from "./CostHelperContext";
import {CostHelper} from "./CostHelper";
import {QuoteMiscPricing} from "../../quote/pricing/QuoteMiscPricing";
import {PriceSectionNotes} from "./PriceSectionNotes";
import {QuoteHardware} from "../../quote/pricing/QuoteHardware";
import {QuoteDoors} from "../../quote/pricing/QuoteDoors";
import {QuoteFrames} from "../../quote/pricing/QuoteFrames";
import {QuoteDiv10} from "../../quote/pricing/QuoteDiv10";
import {useIsQuote} from "../../quote/QuoteContainer";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {PricingTabs} from "./PricingTabs";
import {MiscPrice} from "../../../../api/MiscPricing";
import {QuoteMiscPrice} from "../../../../api/QuoteMiscPricing";
import {projectTablePrefName} from "../TablePrefName";
import {MiscPricing} from "./MiscPricing";
import {QuoteSummary} from "../../quote/pricing/QuoteSummary";

export function priceTableName(project: number, tab: string, tab2: string = "default") {
    if(tab === "div10") {
        tab2 = "div10/" + tab2;
    }

    return projectTablePrefName("project.pricing." + tab + "." + tab2, project);
}

export function Pricing() {
    const u = useUser();
    const [tab, setTab] = useQueryParam("tab", "summary");
    const [subTab, setSubTab] = useQueryParam("tab2", "");

    if(!u) return null;

    return <ShopContainer name={<QuotePricingSwitcher />}
                          actions={<PricingActions tab={tab} subTab={subTab} />}>
        <PricingGeneric
            tab={tab} onTabChange={setTab}
            subTab={subTab} onSubTabChange={setSubTab}
        />
    </ShopContainer>
}

export function PricingGeneric(props: {
    tab: string;
    subTab: string;
    onTabChange(value: string): void;
    onSubTabChange(value: string): void;
}) {
    const isQuote = useIsQuote();

    return (
        <>
            <CostHelperProvider>
                <PricingTabs {...props} />

                <div style={{
                    flex: 1, display: "flex",
                    flexDirection: "column",
                    overflow: "auto"
                }}>
                    {isQuote ? <>
                        {props.tab === "frame" ? <QuoteFrames /> :
                            props.tab === "door" ? <QuoteDoors type={props.subTab as any} /> :
                                props.tab === "hardware" ? <QuoteHardware /> :
                                    props.tab === "summary" ? <QuoteSummary /> :
                                        props.tab === "misc" ? <QuoteMiscPricing /> :
                                            <QuoteDiv10 type={props.subTab as any} />}
                    </> : <>
                        {props.tab === "frame" ? <Frames /> :
                            props.tab === "door" ? <Doors type={props.subTab as any} /> :
                                props.tab === "hardware" ? <Hardware /> :
                                    props.tab === "summary" ? <Summary /> :
                                        props.tab === "misc" ? <MiscPricing /> :
                                            <Div10 type={props.subTab as any} />}
                    </>}

                    <PriceSectionNotes key={props.tab + "-" + props.subTab} tab={props.tab} subTab={props.subTab} />
                </div>
                <CostHelper />
            </CostHelperProvider>
        </>
    )
}

export function showNumber(name: string, value?: number) {
    if(!value) return name;

    return <div style={{display: "flex", alignItems: "center", flexWrap: "nowrap"}}>
        {name}
        <span style={{fontSize: "0.75rem"}}>&nbsp;({value})</span>
    </div>
}

export function profit2(row: PriceRow) {
    if(row.kind === "price" || row.kind === "quote-price") {
        const dt = row.value;
        const qty = "qty" in dt ? dt.qty : 1;
        return dt.extendedPrice - dt.unitCostCents * qty
    }

    return 0;
}

export function profit(dt: PriceLine | MiscPrice | QuoteMiscPrice | MiscProduct | QuotePriceLine| PriceLine2) {
    const qty = "qty" in dt ? dt.qty : 1;
    return dt.extendedPrice - dt.unitCostCents * qty
}

export function byFixedOrder(list: string[]): (a: string, b: string) => number {
    return (a, b) => {
        const ia = list.indexOf(a)
        const ib = list.indexOf(b)

        if(ia === ib) return 0;
        if(ia === -1) return 100;
        if(ib === -1) return 100;
        return ia - ib;
    }
}

export function isNullOrUndefined(input: any): input is undefined {
    if(input === null || input === undefined) return true;
    return false;
}

export function MarkupEditCell<T = PriceLine|MiscProduct|QuotePriceLine>(props: {
    anchor: any;
    row: T;
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: T): Promise<any>;
}) {
    const [value, setValue] = useState(props.initialValue);

    return (
        <TableCellTextPopper
            anchor={props.anchor}
            alignRight
            onEnter={() => {
                props.onDone(Object.assign({}, props.row, parseMarkup(value)))
            }}
            onEsc={props.onCancel}
            onTab={props.onCancel}
            onArrow={() => props.onCancel()}
            onBlur={props.onCancel}
            value={value}
            onChange={setValue}
        />
    )
}

export function MarkupEditCell2(props: {
    anchor: any;
    row: PriceRow;
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: PriceRow): Promise<any>;
}) {
    const [value, setValue] = useState(props.initialValue);
    if(props.row.kind !== "quote-price" && props.row.kind !== "price") return null;
    const objValue = props.row.value;

    return (
        <TableCellTextPopper
            anchor={props.anchor}
            alignRight
            onEnter={() => {
                props.onDone(Object.assign({}, props.row, {
                    value: Object.assign({}, objValue, parseMarkup(value))
                }))
            }}
            onEsc={props.onCancel}
            onTab={props.onCancel}
            onArrow={() => props.onCancel()}
            onBlur={props.onCancel}
            value={value}
            onChange={setValue}
        />
    )
}

export function parseMarkup(value: string) {
    let dollars = () => {
        let val = value.replace("$", "");

        if(val.indexOf(".") === -1) {
            val = val + ".00";
        }

         return {
            markupCents: parseCents(val),
            markupPercent: null,
        }
    }

    let percent = () => {
        console.log("result: percent", value)
        return {
            markupCents: null,
            markupPercent: parseInt(value.replace("%", "")),
        };
    }

    let auto = () => {
        console.log("result: auto", value)
        return {
            markupCents: null,
            markupPercent: null,
        }
    }

    try {

        if (value.startsWith("$")) {
            return dollars();
        } else if (value.endsWith("%")) {
            return percent();
        }

        if (value.indexOf(".") !== -1) {
            return dollars();
        }

        if (value.toLowerCase() === "auto") {
            return auto();
        }

        let v = parseInt(value)
        if (!isNaN(v)) {
            return percent();
        }

        return auto();
    } catch (e) {
        return auto();
    }
}