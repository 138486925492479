import {MobileContainer} from "../../../misc/Container";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {groupRouteItemsByLocation} from "../routes/RouteItems";
import {rowSecondaryTextStyle, rowStyle} from "./RouteList";
import {useEffect, useMemo, useState} from "react";
import {Alert, Button} from "@mui/material";
import {Loader} from "../../../misc/Loader";
import {CheckCircle, HourglassBottom, HourglassEmpty} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {Link, useParams} from "react-router-dom";
import {css} from "@emotion/css";

export function useRefreshOnResize() {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const cb = () => setValue(Date.now());
        window.addEventListener("resize", cb);

        return () => window.removeEventListener("resize", cb);
    });

    return value;
}

export function useRouteInfo() {
    const params = useParams<{id: string}>();
    const id = parseInt(params.id || "0");

    const data = useAsync2((props) => {
        const ctx = {cancelled: false};
        return getAll(ctx, offset => api.logistics.listRouteItems({route: props.route, offset}))
    }, {route: id}, [id])

    const delivery = useAsync2((props) => {
        return api.logistics.getRoute(props)
    }, {id: id}, [id]);

    const grouped = useMemo(() => groupRouteItemsByLocation(data.asList), [data.asList]);

    return {
        grouped,
        delivery,
        rawItems: data,
        id,
    };
}

export function RouteDetail() {

    const {grouped, delivery, rawItems, id} = useRouteInfo();

    useRefreshOnResize();

    const hasStarted = rawItems.asList.filter(d => d.completed).length > 0;
    let startIndex = grouped.reduce((acc, g, index) => {
        if(acc !== -1) return acc;
        if(g.children.filter(c => !c.completed).length > 0) return index;
        return -1;
    }, -1)

    if(startIndex === -1) {
        startIndex = grouped.length-1;
    }

    return (
        <MobileContainer topCenter={delivery.result ? "#" + delivery.result.id : "Loading.."} back="/logistics/driver">
            <div style={{flex: 1, overflow: "auto"}}>
                {!rawItems.loading && grouped.length === 0 && <Alert style={{margin: 8}}>No stops in this route. The office must have forgotten to fill it out</Alert>}
                <Loader {...rawItems} />
                <Loader {...delivery} />
                {grouped.map(g => {
                    const done = g.children.filter(c => c.completed).length;
                    let state = done === g.children.length ? "done" : done === 0 ? "not-started" : "in-progress";

                    return (
                        <div className={rowStyle}>
                            <div style={{flex: 1}}>
                                <div>{g.company}</div>
                                <div className={rowSecondaryTextStyle}>{g.children.length} items</div>
                            </div>
                            <div>
                                {state === "not-started" ? <div style={{color: grey["500"]}}><HourglassEmpty color="inherit" /></div> :
                                    state === "in-progress" ? <HourglassBottom /> :
                                        <CheckCircle />}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div style={{padding: 16, borderTop: "1px solid " + grey["300"], background: grey["200"]}}>
                <Link className={linkNoStyle} to={`/logistics/driver/${id}/${startIndex}`}><Button size="large" fullWidth variant="contained" color="secondary">{hasStarted ? "Resume" : "Start"}{" Route"}</Button></Link>
                <div style={{height: window.innerHeight / 3}} />
            </div>

        </MobileContainer>
    )
}

const linkNoStyle = css({
    textDecoration: "none",
})