import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    FormControlLabel,
    Switch,
    DialogContentText,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
    ListItemButton,
    ListItemText,
    List
} from "@mui/material";
import {formatCents, parseCents, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {OpeningType2, Product} from "../../../../api/Products";
import {api} from "../../../../api/API";
import React, {useEffect, useState} from "react";
import {Loader} from "../../../../misc/Loader";
import {SetupAutoDimension} from "./SetupAutoDimension";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {CategoryTree} from "./CategoryTree";
import {GetOrCreateManufacturer2} from "../hardware/library/GetOrCreateManufacturer2";
import {Company} from "../../../../api/Companies";
import {ProductFind} from "./ProductLookup";
import {FileItemSelection, ProductCatalogLookup} from "./ProductCatalogLookup";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    noPrepOpt,
    PrepMenuOptions,
    PrepOption
} from "./PrepMenuOptions";
import {ProductFileLookup} from "./ProductFileLookup";
import {useAlternative} from "../../quote/alternative/Alternative";

export type CategoryKind = "hardware" | "div-10" | "frame-anchor" | null;

export function CreateProduct(props: {
    mode: CategoryKind;
    edit?: ProductFind | Product;
    skipToDimensionScreen?: boolean;
    onDone(value: Product): void;
    onCancel(): void;
}) {
    const [product, setProduct] = useState<Product|undefined>(() => {
        if(props.skipToDimensionScreen && props.edit) return props.edit;
        return undefined;
    });

    if(product) {
        const pr = product;

        return (
            <SetupAutoDimension mode={props.mode} product={product} onDone={() => {
                props.onDone(pr)
            }} onCancel={() => {
                props.onDone(pr)
            }} />
        )
    }

    return (
        <CreateProductInner mode={props.mode} edit={props.edit} onDone={(product, dim) => {
            if(dim) {
                setProduct(product);
            } else {
                props.onDone(product);
            }
        }} onCancel={props.onCancel} />
    )
}

function CreateProductInner(props: {
    edit?: ProductFind | Product;
    mode: CategoryKind;
    onDone(value: Product, dimension: boolean): void;
    onCancel(): void;
}) {

    const altId = useAlternative();
    const [defaultHwGroup, setDefaultHwGroup] = useState<OpeningType2>(props.edit?.defaultHwGroup||"active");

    const [hwPrep, setHwPrep] = useState<PrepOption>(props.edit ? {

        pairFramePrep: props.edit.hwPrepPairFrame,
        pairFramePrepIsCustom: props.edit.hwPrepPairFrameIsCustom,
        pairActiveDoorPrep: props.edit.hwPrepPairActiveDoor,
        pairActiveDoorPrepIsCustom: props.edit.hwPrepPairActiveDoorIsCustom,
        pairInactiveDoorPrep: props.edit.hwPrepPairInactiveDoor,
        pairInactiveDoorPrepIsCustom: props.edit.hwPrepPairInactiveDoorIsCustom,

        singleFramePrep: props.edit.hwPrepSingleFrame,
        singleFramePrepIsCustom: props.edit.hwPrepSingleFrameIsCustom,
        singleActiveDoorPrep: props.edit.hwPrepSingleActiveDoor,
        singleActiveDoorPrepIsCustom: props.edit.hwPrepSingleActiveDoorIsCustom,
    } : noPrepOpt);

    const [hwPrepCustomFile, setHwPrepCustomFile] = useState(props.edit && props.edit.hwPrepFile ? {
        id: props.edit.hwPrepFile,
        name: props.edit.hwPrepFileName || "",
    } : null);

    const [showCustomPrep, setShowCustomPrep] = useState(false);

    const [category, setCategory] = useState<number|null>(props.edit?.category || null);
    const [catalog, setCatalog] = useState<FileItemSelection | null>(props.edit?.catalog ? {
        id: props.edit.catalog,
        name: props.edit.catalogName || "",
        pages: props.edit.catalogPages || "",
    } : null);
    const [productCode, setProductCode] = useState(props.edit?.productCode || "");
    const [defaultPrice, setDefaultPrice] = useState(props.edit?.defaultCostCents ? formatCents(props.edit.defaultCostCents) : "")
    const [finish, setFinish] = useState(props.edit?.finish || "");
    const [name, setName] = useState(() => {
        if(props.mode === "div-10") return (props.edit?.name || "").replace(/^.*?: /, "") // remove "PREFIX:" for div10 products
        return (props.edit?.name || "");
    });
    const [finishName, setFinishName] = useState(props.edit?.finishName || "");
    const [keyed, setKeyed] = useState(props.edit?.keyed || false);
    const [manufacturer, setManufacturer] = useState<Partial<Company>|undefined>(props.edit ? {
        id: props.edit.manufacturer,
        name: props.edit.manufacturerName,
    } : undefined);

    const [distributor, setDistributor] = useState<Partial<Company>|undefined>(props.edit && props.edit.defaultDistributor ? {
        id: props.edit.defaultDistributor,
        name: props.edit.defaultDistributorName || "",
    } : undefined);

    const [showDistributor, setShowDistributor] = useState(!!distributor);

    const [partOfFrame, setPartOfFrame] = useState(props.edit?.partOfFrame || false);

    const finishNameForCode = useAsync2(async (input) => {
        if(!input.code) return null;
        return await api.products.getFinishNameForCode(input);
    }, {code: finish}, [finish]);
    const lookupFinishName = finishNameForCode.result?.finishName;

    useEffect(() => {
        if(!lookupFinishName) return;
        setFinishName(lookupFinishName);
    }, [lookupFinishName]);

    const hasDims = !!(props.edit && (props.edit.dimHeightFormula || props.edit.dimWidthFormula || props.edit.dimLengthFormula))
    const [needDimensions, setNeedDimensions] = useState(hasDims);
    const needDimensionsRef = useSyncedRef(needDimensions);

    const onDoneRef = useSyncedRef(props.onDone);

    const submit = useAsyncAction(async (input) => {
        const product = await api.products.upsert(input)
        onDoneRef.current(product, needDimensionsRef.current);
    }, []);

    const [showCatalog, setShowCatalog] = useState(false);

    if(showCatalog) {
        return <ProductCatalogLookup name={props.mode === "frame-anchor" ? "Anchor Detail" : "Cut Sheet"} onDone={async (v) => {
                setCatalog(v)
                setShowCatalog(false);
            }} value={catalog} manufacturer={manufacturer?.id} category={category || undefined} />
    }

    if(showCustomPrep && manufacturer?.id) {
        return <ProductFileLookup
            title="Custom Prep File"
            type="custom-preps"
            value={hwPrepCustomFile}
            manufacturer={manufacturer.id}
            onDone={(value) => {
                setShowCustomPrep(false);
                setHwPrepCustomFile(value);
            }}
        />
    }

    const canHaveDimensions = !(props.mode === "frame-anchor")
    const canCombineWithFrame = props.mode === "frame-anchor"

    return (
        <Dialog open={true} onClose={props.onCancel}>
            <DialogTitle>{props.edit ? "Edit Product" : "Create Product"}</DialogTitle>
            <DialogContent>
                {props.edit && <DialogContentText>
                    Warning: This product may be used in other projects and will be updated there too.
                </DialogContentText>}
                <Grid container spacing={1} flexWrap="nowrap">
                    <Grid item>
                        <CategoryTree
                            mode={props.mode}
                            value={category}
                            onChange={(cat, info) => {
                                console.log("category", cat, info)

                                if(cat === category) return;
                                setCategory(cat)

                                if(info?.isAutoQtyHinge) {
                                    setDefaultHwGroup("each-door");
                                } else {
                                    setDefaultHwGroup("active");
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <div style={{padding: 16, paddingBottom: 0, maxWidth: 400}}>
                            <GetOrCreateManufacturer2
                                fullWidth
                                size="small"
                                label="Manufacturer"
                                kind={props.mode === "div-10" ? "div10" : "hardware"}
                                onChange={mfg => {
                                    setManufacturer(mfg)

                                    if(mfg.defaultDistributor) {
                                        setShowDistributor(true);
                                        setDistributor({
                                            id: mfg.defaultDistributor,
                                            name: mfg.defaultDistributorName || "",
                                        })
                                    } else {
                                        setShowDistributor(false);
                                        setDistributor(undefined);
                                    }

                                }}
                                value={manufacturer?.id}
                                afterShortName={
                                    <FormControlLabel control={<Checkbox checked={showDistributor} onChange={(v, checked) => {
                                        setShowDistributor(checked)
                                        if(!checked) {
                                            setDistributor(undefined);
                                        }
                                    }} />} label="Alt Distributor" />
                                }
                            />

                            {showDistributor && <>
                                <div style={{height: 8}} />
                                <GetOrCreateManufacturer2
                                    fullWidth
                                    size="small"
                                    label="Default Distributor"
                                    kind={props.mode === "div-10" ? "div10" : "hardware"}
                                    onChange={setDistributor}
                                    value={distributor?.id}
                                />
                            </>}

                            <div style={{height: 16}} />

                            <Grid container spacing={1}>
                                {props.mode === "frame-anchor" ? <>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="description">Description</InputLabel>

                                            <Select labelId="description" size="small" fullWidth label="Description" value={fixAnchorName(name) || "-"}
                                                    onChange={e => {
                                                        const opt = anchorLookupOptions().find(v => v.value === e.target.value as any)

                                                        if(opt) {
                                                            setName(opt.value)
                                                        } else {
                                                            setName(e.target.value as any)
                                                        }
                                                    }}>
                                                <MenuItem value="-">Choose a Type</MenuItem>
                                                {anchorLookupOptions().map(v => (<MenuItem value={v.value}>{v.display}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField size="small" label="Product Code" value={productCode} onChange={e => setProductCode(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ListItemButton
                                            onClick={() => setShowCatalog(true)}>
                                            <ListItemText
                                                style={{flex: 1}}
                                                secondary={catalog?.name || "None"}
                                                primary="Anchor Detail"
                                            />
                                            <ChevronRightIcon />
                                        </ListItemButton>
                                    </Grid>
                                </> : <>
                                    <Grid item xs={6}>
                                        <TextField size="small" label="Product Code" value={productCode} onChange={e => setProductCode(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField size="small" label="Finish" value={finish} onChange={e => setFinish(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField size="small" label="F. Desc" value={finishName} onChange={e => setFinishName(e.target.value)} />
                                    </Grid>
                                </>}
                                {props.mode === "div-10" && <>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <TextField size="small"
                                                   label="Description"
                                                   value={name}
                                                   onChange={e => setName(e.target.value)}
                                                   fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List dense>
                                            <ListItemButton
                                                onClick={() => setShowCatalog(true)}>
                                                <ListItemText
                                                    style={{flex: 1}}
                                                    secondary={catalog?.name || "None"}
                                                    primary="Cut Sheet"
                                                />
                                                <ChevronRightIcon />
                                            </ListItemButton>
                                        </List>
                                    </Grid>
                                </>}
                                {props.mode === "hardware" && <>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="default-hw-group">Default HW Group</InputLabel>

                                            <Select labelId="default-hw-group" size="small" fullWidth label="Default HW Group" value={defaultHwGroup}
                                                    onChange={e => setDefaultHwGroup(e.target.value as any)}>
                                                <MenuItem value="active">Active</MenuItem>
                                                <MenuItem value="inactive">In-Active</MenuItem>
                                                <MenuItem value="each-door">Each Door</MenuItem>
                                                <MenuItem value="common">Common</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel control={<Checkbox checked={keyed} onChange={(e, checked) => setKeyed(checked)} />} label="Keyed" />
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <PrepMenuOptions onChange={(value) => {
                                            const hadTemplate = needsCustomPrep(hwPrep);
                                            const update: PrepOption = Object.assign({}, hwPrep, value)

                                            if(needsCustomPrep(update) && !hadTemplate) {
                                                setShowCustomPrep(true);
                                            }

                                            if(!needsCustomPrep(update)) {
                                                setHwPrepCustomFile(null);
                                            }

                                            setHwPrep(update)
                                        }} value={hwPrep} category={category} />
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <List dense>
                                            {needsCustomPrep(hwPrep) ? <ListItemButton onClick={() => setShowCustomPrep(true)}>
                                                <ListItemText
                                                    style={{flex: 1}}
                                                    secondary={hwPrepCustomFile?.name || "None"}
                                                    primary="Custom Prep File"
                                                />
                                                <ChevronRightIcon />
                                            </ListItemButton> : null}
                                            <ListItemButton
                                                onClick={() => setShowCatalog(true)}>
                                                <ListItemText
                                                    style={{flex: 1}}
                                                    secondary={catalog?.name || "None"}
                                                    primary="Cut Sheet"
                                                />
                                                <ChevronRightIcon />
                                            </ListItemButton>
                                        </List>
                                    </Grid>
                                </>}
                            </Grid>

                            <div style={{height: 20}} />

                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                {canHaveDimensions && <>
                                    <FormControlLabel control={<Switch checked={needDimensions} onChange={(e, checked) => setNeedDimensions(checked)} />} label="Has Dimensions" />
                                </>}

                                <TextField label="Default Price" size="small" value={defaultPrice} onChange={e => setDefaultPrice(e.target.value)} />
                            </div>

                            {canCombineWithFrame && <>
                                <div style={{height: 20}} />
                                <FormControlLabel control={<Switch checked={partOfFrame} onChange={(e, checked) => setPartOfFrame(checked)} />} label="Part of Frame" />
                            </>}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <Loader {...submit} />
                    </Grid>
                    <Grid item>
                        <Button onClick={props.onCancel}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={
                            props.mode === "frame-anchor" ?
                                (!category || !manufacturer || !productCode) :
                                (!category || !manufacturer || !finish || !productCode)
                        }
                                variant="contained" color="primary" onClick={() => {

                            let updateObj: any = {};

                            if(props.edit) {
                                Object.assign(updateObj, props.edit);
                            }

                            if(hwPrep.singleFramePrepIsCustom) hwPrep.singleFramePrep = `${productCode} Prep - See attached template`;
                            if(hwPrep.pairFramePrepIsCustom) hwPrep.pairFramePrep = `${productCode} Prep - See attached template`;
                            if(hwPrep.singleActiveDoorPrepIsCustom) hwPrep.singleActiveDoorPrep = `${productCode} Prep - See attached template`;
                            if(hwPrep.pairActiveDoorPrepIsCustom) hwPrep.pairActiveDoorPrep = `${productCode} Prep - See attached template`;
                            if(hwPrep.pairInactiveDoorPrepIsCustom) hwPrep.pairInactiveDoorPrep = `${productCode} Prep - See attached template`;
                            let dfPrice: number | null = null;
                            if(defaultPrice) {
                                const c = parseCents(defaultPrice)
                                if(c > 0) {
                                    dfPrice = c;
                                }
                            }

                            Object.assign(updateObj, {
                                name: name, // name only has affect on div10 products, see backend for naming overrides
                                productCode: productCode,
                                finish: finish,
                                finishName: finishName,
                                manufacturer: manufacturer?.id,
                                defaultDistributor: distributor?.id || null,
                                category: category,
                                partOfFrame: partOfFrame,
                                defaultHwGroup: defaultHwGroup,
                                keyed: keyed,
                                catalog: catalog?.id || null,
                                catalogPages: catalog?.pages || "",

                                hwPrepSingleFrame: hwPrep.singleFramePrep,
                                hwPrepSingleFrameIsCustom: hwPrep.singleFramePrepIsCustom,
                                hwPrepSingleActiveDoor: hwPrep.singleActiveDoorPrep,
                                hwPrepSingleActiveDoorIsCustom: hwPrep.singleActiveDoorPrepIsCustom,
                                hwPrepPairFrame: hwPrep.pairFramePrep,
                                hwPrepPairFrameIsCustom: hwPrep.pairFramePrepIsCustom,
                                hwPrepPairActiveDoor: hwPrep.pairActiveDoorPrep,
                                hwPrepPairActiveDoorIsCustom: hwPrep.pairActiveDoorPrepIsCustom,
                                hwPrepPairInactiveDoor: hwPrep.pairInactiveDoorPrep,
                                hwPrepPairInactiveDoorIsCustom: hwPrep.pairInactiveDoorPrepIsCustom,

                                hwPrepFile: hwPrepCustomFile?.id || null,
                                defaultCostCents: dfPrice,
                            })

                            if(props.edit && dfPrice !== props.edit.defaultCostCents && !!altId) {
                                updateObj.updateQuotePricingForAlternative = altId;
                            }

                            submit.callback(updateObj)
                        }}>{
                            props.edit ?
                                (needDimensions ? "Save & Next" : "Save") :
                                (needDimensions ? "Add & Next" : "Add")
                        }</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>

    )
}

function needsCustomPrep(hwPrep: PrepOption) {
    return hwPrep.pairFramePrepIsCustom ||
        hwPrep.singleFramePrepIsCustom ||
        hwPrep.pairActiveDoorPrepIsCustom ||
        hwPrep.singleActiveDoorPrepIsCustom ||
        hwPrep.pairInactiveDoorPrepIsCustom
}

function fixAnchorName(name: string) {
    const found = anchorLookupOptions().find(c => c.value === name);
    if(!found) return ""
    return found.value;
}

export function anchorLookupOptions() {
    return [
        {value: "AWA", display: "AWA: Adjustable Wall Anchor"},
        {value: "BASE", display: "BASE: Base/Floor Anchor"},
        {value: "EMA", display: "EMA: Existing Masonry Anchor"},
        {value: "FSA", display: "FSA: 5 3/4\" Fixed Stud Anchor"},
        {value: "IMP", display: "IMP: Insulated Metal Panel Anchor"},
        {value: "P&S", display: "P&S: Plug & Strap"},
        {value: "S&T", display: "S&T: Strap & Tension"},
        {value: "WIRE", display: "WIRE: Masonry Wire Anchor"},
        {value: "Z", display: "Z: Welded \"Z\" Anchor"},
    ]
}