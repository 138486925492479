import React, {PropsWithChildren} from "react";
import {createTheme, IconButton, styled, ThemeProvider} from "@mui/material";
import {Sidebar} from "./Sidebar";
import {CurrentUser} from "./CurrentUser";
import {TableSelectionContextProvider} from "./scroller/Table";
import {HeaderTasks} from "./tasks/HeaderTasks";
import West from "@mui/icons-material/West";
import {Link, useNavigate} from "react-router-dom";
import {amber} from "@mui/material/colors";
import {UndoProvider} from "./scroller/GlobalUndo";
import {TestingBanner} from "./TestingBanner";

const AppBar = styled("div")<{
    color?: string;
}>((props) => ({
    backgroundColor: props.color || props.theme.palette.primary.main,
    width: "100%",
    padding: 6,
    flexGrow: 0,
    flexBasis: 1,
    boxShadow: "0 0 3px 0px #000000a1",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
}));

const Outer = styled("div")(props => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}))

const Row = styled("div")(props => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    flex: 1,
    overflow: "auto",
}))

const Body = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.light,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}))

export function Container(props: PropsWithChildren<{
    topCenter?: JSX.Element
    color?: string;
    noSidebar?: boolean
}>) {
    return (
        <Outer>
            <UndoProvider>
                <TableSelectionContextProvider>
                    <TestingBanner />
                    <AppBar color={props.color}>
                        <img src="/images/handle-white.png" style={{paddingLeft: 8, height: 20, marginBottom: -3}} alt="Logo" />
                        <div style={{paddingLeft: 8, color: "white", fontWeight: "bold"}}>
                            Handle
                        </div>
                        <div style={{flex: 1}} />
                        {props.topCenter}
                        <div style={{flex: 1}} />
                        <HeaderTasks />
                        <div style={{width: 8}} />
                        <CurrentUser />
                    </AppBar>
                    <Row>
                        {props.noSidebar ? null : <Sidebar />}
                        <Body>
                            {props.children}
                        </Body>
                    </Row>
                </TableSelectionContextProvider>
            </UndoProvider>
        </Outer>
    );
}

const mobileTheme = createTheme({
    palette: {
        secondary: amber,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1.4rem"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "2rem"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "1.2rem",
                }
            }
        }
    }
})

export function MobileContainer(props: PropsWithChildren<{
    topCenter?: any;
    back?: string;
}>) {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={mobileTheme}>
            <Outer>
                <TableSelectionContextProvider>
                    <AppBar>
                        {props.back ?
                            <div style={{width: 60}}>
                                <IconButton style={{color: "white"}} onClick={() => navigate(props.back || "/")}>
                                  <West />
                                </IconButton>
                            </div>
                            : <Link to="/"><img src="/images/handle-white.png" style={{paddingLeft: 8, height: 20, marginBottom: -3}} alt="Logo" /></Link>
                        }

                        <div style={{flex: 1}} />
                        <div style={{color: "white", fontSize: "1.8rem", fontWeight: "bold"}}>{props.topCenter}</div>
                        <div style={{flex: 1}} />
                        <div style={{width: 60, display: "flex", justifyContent: "flex-end"}}>
                            <CurrentUser />
                        </div>
                    </AppBar>
                    <Body>
                        {props.children}
                    </Body>
                </TableSelectionContextProvider>
            </Outer>
        </ThemeProvider>
    )
}

export function PopoutContainer(props: PropsWithChildren<{}>) {
    return (
        <TableSelectionContextProvider>
            {props.children}
        </TableSelectionContextProvider>
    )
}