import {Card, CircularProgress, MenuItem, Popper, TextField, TextFieldProps} from "@mui/material";
import {useEffect, useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {red} from "@mui/material/colors";

export function AutoComplete<T>(props: Omit<TextFieldProps, "value"|"onSelect"> & {
    search: (input: string) => Promise<T[]>
    value: any;
    equals: (a: T, b: T) => boolean;
    makeLabel: (input: T) => string;
    makeLabelReact?: (input: T) => any;
    useNativeInput?: boolean;
    popperClassName?: string;
    onSelect(value: T): void;
    onCreate?(value: string): void;
}) {
    const {
        search, value, equals, makeLabel, onSelect, onCreate, popperClassName,
        ...textProps
    } = props;

    const [searchStr, setSearchStr] = useState("");

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [list, setList] = useState<T[]>([]);
    const [error, setError] = useState<string|null>(null)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(open) return;
        setSearchStr(props.value ? makeLabel(props.value) || "" : "");
    }, [props.value, makeLabel, open])

    const searchRef = useSyncedRef(search);

    useEffect(() => {
        if(!open) return;

        let ignore = false;

        (async () => {
            try {
                setError(null);
                setLoading(true);

                const promise = await searchRef.current(searchStr);
                if (ignore) return;

                setLoading(false);
                setList(promise)
            } catch (e: any) {
                if (ignore) return;

                setLoading(false);
                setError(e.toString());
            }
        })()

        return () => {
            ignore = true;
        }
    }, [searchStr, open, searchRef]);

    const showCreate = (!list || list.filter(l => makeLabel(l) === searchStr).length === 0) && props.onCreate && searchStr;
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if(focused) {
            setOpen(focused)
            return;
        }

        // delay closing the popper to allow the user to click on the popper
        const tm = setTimeout(() => {
            setOpen(focused)
        }, 500);

        return () => clearTimeout(tm);
    }, [focused]);

    return (
        <>
            {props.useNativeInput ? <input
                    type="text"
                    value={searchStr} onChange={e => setSearchStr(e.target.value)}
                    ref={setAnchorEl as any}
                    placeholder={props.placeholder}
                    style={Object.assign({
                        border: "none",
                        width: "100%",
                        height: "100%",
                        padding: 4,
                        paddingLeft: 6,
                        paddingRight: 6
                    }, props.style || {})}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    className={props.className}
                    autoComplete="off"
                /> :
                <TextField
                    value={searchStr} onChange={e => setSearchStr(e.target.value)}
                    ref={setAnchorEl}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    {...textProps}
                    autoComplete="off"
                />
            }


            <Popper open={open} style={{zIndex: 1400}}
                    anchorEl={anchorEl}
                    placement="bottom-start"
            >

                <Card className={props.popperClassName} style={{
                    width: anchorEl && window.getComputedStyle(anchorEl).width,
                    // minHeight: 40,
                }}>
                    {loading && <CircularProgress />}
                    {!loading && error && <MenuItem style={{color: red["700"]}}>{error}</MenuItem>}
                    {list && list.map((l, index) => <MenuItem key={index.toString()} onClick={() => onSelect(l)}>
                        {(props.makeLabelReact || makeLabel)(l)}
                    </MenuItem>)}
                    {showCreate && <MenuItem onClick={() => {
                        console.log("create", searchStr)
                        if(onCreate) onCreate(searchStr)
                    }}>Create '{searchStr}'</MenuItem>}
                    {(!list || list.length === 0) && !showCreate && <MenuItem key="no-result">
                        <div style={{fontSize: "0.8rem", textAlign: "center"}}>No results</div>
                    </MenuItem>}
                </Card>
            </Popper>
        </>
    )
}