import {useContext, useEffect, useState} from "react";
import {CheckboxContext} from "../../project/shopdrawing/release/Checkbox";
import {TableSelectionContext, useTableData} from "../../../misc/scroller/Table";
import {SnackContext} from "../../../misc/Snackbar";
import {ToDeliverItem} from "../../../api/Logistics";
import {Button} from "@mui/material";
import {groupByArr, selectMany, sum} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {toDeliverTableName} from "./ToDeliver";
import {deliveryItemsTableName} from "./DeliveryItems";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";

export function SubmitButton(props: {
    delivery?: number;
}) {
    const {listenAll} = useContext(CheckboxContext);
    const [selected, setSelected] = useState<number[]>([]);

    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);

    useEffect(() => {
        const sub = listenAll(setSelected)
        return () => sub.cancel();
    }, [listenAll])

    const data = useTableData<ToDeliverItem>(toDeliverTableName)

    return (
        <div style={{padding: 4,}}>
            <Button onClick={async () => {
                if(!props.delivery) return;
                const delivery = props.delivery;

                try {
                    snack.loading();

                    const toAdd = data.filter(d => selected.indexOf(d.id) !== -1);

                    const updates = groupByArr(toAdd, item => item.product).map(grp => {
                        return api.logistics.updateDeliveryItem({
                            id: 0,
                            product: grp.values[0].product,
                            source: grp.values[0].source,
                            manufacturer: grp.values[0].manufacturer,
                            manufacturerName: grp.values[0].manufacturerName,
                            dimensions: grp.values[0].dimensions,
                            delivery: delivery,
                            description: grp.values[0].name,
                            code: grp.values[0].productCode,
                            qty: sum(grp.values.map(v => v.qty)),
                            inventoryRequests: selectMany(grp.values.map(v => v.inventoryRequestIds), v => v),
                            archived: false,
                            updatedAt: (new Date()) as any,
                        })
                    });

                    await Promise.all(updates);

                    deliveriesPopoutPanel.sendToServer("refresh-delivery");
                    ctx.refresh(toDeliverTableName);
                    snack.success("Added")
                } catch(e: any) {
                    snack.error(e.toString())
                    ctx.refresh(deliveryItemsTableName);
                    ctx.refresh(toDeliverTableName);
                }
            }} variant="contained" disabled={!props.delivery || selected.length === 0}>Add to Delivery</Button>
        </div>
    );
}