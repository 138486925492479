import {EventEmitter, first, sum, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useEffect, useMemo} from "react";
import {
    Delivery,
    DeliveryItem,
    DriverRoute,
    DriverRouteItem,
    ProductPreview,
    PurchaseOrder, PurchaseOrderItem
} from "../../../api/Logistics";
import {Loader} from "../../../misc/Loader";
import Business from "@mui/icons-material/Business";
import {PickupPoItemRow} from "./PickupPoItemRow";
import LocalShipping from "@mui/icons-material/LocalShipping";
import {RouteItemRow} from "./RouteItemRow";
import {DropPoItemRow} from "./DropPoItemRow";
import {DropDeliveryRow} from "./DropDeliveryRow";
import {PickupDeliveryRow} from "./PickupDeliveryRow";
import {QtyWithPreview} from "./QtyWithPreview";
import {useGetAll} from "./useGetAll";
import {routePopupPanel} from "./RouteList";
import {Completed} from "./Completed";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {grey} from "@mui/material/colors";
import {Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {MenuButton} from "../purchasing/MenuButton";
import {usePrintParallel} from "../../project/shopdrawing/changeset/PrintParallel";
import {PackingSlipPDF} from "../deliveries/PackingSlipPDF";
import {getAll} from "../../project/shopdrawing/openings/Openings";

export type Item = {
    type: "pickup" | "drop";
    company: string;
    children: DriverRouteItem[];
}

const warehouse = "Tykel Warehouse";

export function groupRouteItemsByLocation(input: DriverRouteItem[]): Item[] {
    let current: Item|null = null;
    let list: Item[] = [];

    function add(v: DriverRouteItem, input: {type: "pickup" | "drop", company: string}) {
        if(!current || current.type !== input.type || current.company !== input.company) {
            current = Object.assign({}, input, {
                children: [],
            });
            list.push(current);
        }

        current.children.push(v);
    }

    input.map(v => {
        if(v.pickupPo) {
            return add(v, {
                type: "pickup",
                company: v.pickupPoCompanyName || "",
            })
        }

        if(v.pickupDelivery) {
            return add(v, {
                type: "pickup",
                company: warehouse,
            })
        }

        if(v.dropPo) {
            return add(v, {
                type: "drop",
                company: v.dropPoProjectName || "",
            })
        }

        if(v.dropDelivery) {
            return add(v, {
                type: "drop",
                company: v.dropDeliveryProjectName || "",
            })
        }

        return null;
    })

    return list;
}

export const RefreshRouteItems = new EventEmitter();

export function RouteItems(props: {
    route: number;
    routeObj?: DriverRoute | null;
}) {
    const route = props.route;

    const remaining = useAsync2(
        input => api.logistics.listRouteItemsRemainingOnTruck(input),
        {route: route}, [route]);

    const list = useGetAll((props: { route: number, offset: number}) => {
        return api.logistics.listRouteItems(props);
    }, {route: route}, [route]);

    const reloadList = useSyncedRef(list.reload)
    const reloadRemaining = useSyncedRef(remaining.reload)

    useEffect(() => {
        const sub = routePopupPanel.fromClient.subscribeAndFireLast(v => {
            if (v === "refresh-route") {
                reloadList.current();
                reloadRemaining.current();
            }
        })

        return () => sub.cancel();
    },[reloadList, reloadRemaining]);

    useEffect(() => {
        const sub = RefreshRouteItems.subscribe(() => {
            reloadList.current();
            reloadRemaining.current();
        });

        return () => sub.cancel()
    }, [reloadList, reloadRemaining])

    const grouped = groupRouteItemsByLocation(list.asList);

    const leftOvers = useMemo(() => {
        const data = remaining.asList;
        if(!props.routeObj) return null;
        if(!data) return null;

        let list: ProductPreview[] =[];

        data.map(d => {
            d.contents.map(c => {
                const exist = first(list, i => i.product === c.product && i.description === c.description);
                if(!exist) {
                    list.push(c);
                    return null;
                }

                exist.qty += c.qty;
            })

            return null;
        })

        return {
            completed: props.routeObj.completed,
            qty: sum(data.map(d => d.qty)),
            contents: list,
            driverLog: props.routeObj.routeLeftovers
        };
    }, [props.routeObj, remaining.asList])

    const ctx = usePrintParallel();

    if(!props.routeObj) return null;
    const routeObj = props.routeObj

    const showWarehouse =
        remaining.asList.length > 0 ||
        (routeObj.completed && routeObj.routeLeftovers?.items && routeObj.routeLeftovers?.items.length > 0)

    return (
        <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "auto"}}>
            <Loader {...list} />
            {grouped.map((d, index) => {
                const prevGroup = index > 0 ? grouped[index-1] : null;
                const nextGroup = index === grouped.length -1 ? null : grouped[index+1];

                if(d.type === "pickup") {
                    return (
                        <RouteItemRow item={d} icon={<Business fontSize="inherit" />} title={`Pickup at ${d.company}`} next={nextGroup} prev={prevGroup}>
                            <table>
                                {d.children.map(c => {
                                    if(c.pickupPo) return <PickupPoItemRow route={routeObj} list={list.asList} value={c} />
                                    return <PickupDeliveryRow route={routeObj} list={list.asList} value={c} />
                                })}
                            </table>
                        </RouteItemRow>
                    )
                }

                return (
                    <RouteItemRow item={d} icon={<LocalShipping fontSize="inherit" />} title={`Deliver to ${d.company}`} next={nextGroup} prev={prevGroup}>
                        <table>
                            {d.children.map(c => {
                                if(c.dropPo) return <DropPoItemRow route={routeObj} list={list.asList} value={c} />
                                return <DropDeliveryRow route={routeObj} list={list.asList} value={c} />
                            })}
                        </table>
                    </RouteItemRow>
                )
            })}
            <Loader {...remaining} />
            {showWarehouse && <RouteItemRow item={null} icon={<LocalShipping fontSize="inherit" />} title={`Delivery to Tykel Warehouse`} next={null} prev={null}>
                <table>
                    {routeObj.completed && leftOvers ? <>
                        <tr>
                            <td>Delivery</td>
                            <td>
                                <QtyWithPreview data={leftOvers} />
                            </td>
                            <td>
                                <Completed value={leftOvers} routeComplete={routeObj.completed} />
                            </td>
                        </tr>
                    </> : <>
                        {remaining.asList.map(c => <tr>
                            <td>PO #{c.pickupPoNumber || c.dropPoNumber}</td>
                            <td>
                                <QtyWithPreview data={c} />
                            </td>
                            <td>
                                <Completed value={c} routeComplete={routeObj.completed} />
                            </td>
                        </tr>)}
                    </>}
                </table>
            </RouteItemRow>}
            <div style={{flex: 1}} />

            {ctx.loading && <Dialog open>
                <DialogTitle>Printing...</DialogTitle>
                <DialogContent>
                    <Loader loading={true} error={null} />
                </DialogContent>
            </Dialog>}

            <div style={{padding: 8, paddingRight: 16, borderTop: "1px solid " + grey["300"],backgroundColor: grey["200"]}}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <MenuButton
                            options={[{
                                name: "Export Packing Slip",
                                disabled: grouped.filter(g => g.type === "drop" && g.children.length > 0).length === 0,
                                onClick: async () => {

                                    grouped
                                        .filter(g => g.type === "drop" && g.children.length > 0)
                                        .map(async gr => {
                                            gr.children.map(async ch => {

                                                const projectId = ch.dropPoProject || ch.dropDeliveryProject || 0;
                                                const projectP = api.projects.get(projectId);

                                                let value: Delivery | PurchaseOrder;
                                                let items: (DeliveryItem|PurchaseOrderItem)[]

                                                const dropDel = ch.dropDelivery;
                                                const dropPO = ch.dropPo;

                                                if(dropDel) {
                                                    const delP = api.logistics.getDelivery({id: dropDel});
                                                    const delItemsP = getAll(null, offset => api.logistics.listDeliveryItems({delivery: dropDel, offset}));
                                                    value = await delP;
                                                    items = await delItemsP;
                                                } else if(dropPO) {
                                                    const poP = api.logistics.getPO({id: dropPO});
                                                    const poItemsP =  getAll(null, offset => api.logistics.listPurchaseOrderItems({purchaseOrder: dropPO, type: "purchase-order", offset}));
                                                    const poItemsFilter = ch.contents.filter(c => !!c.purchaseOrderItem).map(c => c.purchaseOrderItem)
                                                    value = await poP;
                                                    items = await poItemsP;
                                                    items = items.filter(i => poItemsFilter.includes(i.id)) as any;
                                                } else {
                                                    return null;
                                                }

                                                const project = await projectP;

                                                ctx.printer({
                                                    name: `packing-slip-${sanitizeFileName(project.name)}.pdf`,
                                                    render: async onReady => <PackingSlipPDF project={project} items={items} value={value} onReady={onReady} />,
                                                    download: true,
                                                    serverInfo: {
                                                        submissionForProject: projectId,
                                                        submissionType: "route-packing-slip",
                                                        ignoreSubmissionDate: true,
                                                    }
                                                })
                                            })

                                        })
                                }
                            }]}
                        >
                            Export
                        </MenuButton>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

function sanitizeFileName(input: string): string {
    return input.replace(/[^A-z0-9- .]+/g, "-").replace(/[-]{2,}/g, "-")
}
