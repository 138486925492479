import {SidebarItem} from "./SidebarItem";
import GroupIcon from '@mui/icons-material/Group';
import {grey} from "@mui/material/colors";
import Business from "@mui/icons-material/Business";
import Inventory from "@mui/icons-material/Inventory";
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShipping from '@mui/icons-material/LocalShipping';
import DriveEta from "@mui/icons-material/DriveEta";
import CallReceived from "@mui/icons-material/CallReceived";
import WarningIcon from '@mui/icons-material/Warning';
import CreditCard from "@mui/icons-material/CreditCard";
import AttachMoney from "@mui/icons-material/AttachMoney";
import {useContext} from "react";
import {ProjectContext} from "../pages/project/ProjectProvider";
import {Settings} from "@mui/icons-material";
import TableViewIcon from '@mui/icons-material/TableView';
import PeopleIcon from '@mui/icons-material/People';
import HardwareIcon from '@mui/icons-material/Hardware';
import DeleteIcon from '@mui/icons-material/Delete';

export function Sidebar() {
        const project = useContext(ProjectContext).value;

    return (
        <div style={{display: "flex", flexDirection: "column",
            borderRight: "1px solid " + grey["200"]}}>
            <div style={{height: 16}} />
            <SidebarItem link="/customers" noMatchChildren icon={<PeopleIcon fontSize="inherit" />} name="Customers" />
            <SidebarItem link="/projects" noMatchChildren icon={<Business fontSize="inherit" />} name="Projects" />
            {project.name !== "" && <SidebarItem link={`/project/${project.id}`} indent={1} icon={<Business fontSize="inherit" />} name={project.name} />}
            <SidebarItem link="/logistics" noMatchChildren icon={<CategoryIcon fontSize="inherit" />} name="Logistics" />
            <SidebarItem indent={1} link="/logistics/warehouse" icon={<Business fontSize="inherit" />} name="Warehouse" />
            <SidebarItem indent={1} link="/logistics/purchasing" icon={<ShoppingCartIcon fontSize="inherit" />} name="Purchasing" />
            <SidebarItem indent={1} link="/logistics/packing" icon={<Inventory fontSize="inherit" />} name="Packing" />
            <SidebarItem indent={1} link="/logistics/routes" icon={<LocalShipping fontSize="inherit" />} name="Routes" />
            <SidebarItem indent={1} link="/logistics/damaged" icon={<WarningIcon fontSize="inherit" />} name="Damaged" />
            <SidebarItem indent={1} link="/logistics/waste" icon={<DeleteIcon fontSize="inherit" />} name="Waste" />
            <SidebarItem link="/manufacturing" icon={<HardwareIcon fontSize="inherit" />} name="Manufacture" />
            <SidebarItem link="/finance" noMatchChildren icon={<AttachMoney fontSize="inherit" />} name="Finance" />
            <SidebarItem indent={1} link="/finance/payables" icon={<CreditCard fontSize="inherit" />} name="Payables" />
            <div style={{flex: 1}} />
            <SidebarItem indent={0} link="/settings" noMatchChildren icon={<Settings fontSize="inherit" />} name="Settings" />
            <SidebarItem indent={1} link="/settings/products-and-manufacturers" icon={<CategoryIcon fontSize="inherit" />} name="Products" />
            <SidebarItem indent={0} link="/logistics/driver" icon={<DriveEta fontSize="inherit" />} name="Driving App" />
            <SidebarItem indent={0} link="/logistics/receiving" icon={<CallReceived fontSize="inherit" />} name="Receiving App" />
            <SidebarItem indent={0} link="/extractor" icon={<TableViewIcon fontSize="inherit" />} name="Extractor App" />
            <SidebarItem link="/users" icon={<GroupIcon fontSize="inherit" />} name="Users" />
            <div style={{height: 32}} />
        </div>
    );
}