import {useProjectId} from "../ProjectName";
import {api} from "../../../../api/API";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {Loader} from "../../../../misc/Loader";
import {Opening} from "../../../../api/Openings";
import {tableBorderColor, tableClass} from "./library/ShortCodes";
import {IconButton2, IconCell} from "./library/IconCell";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {ProductLookup} from "../extras/ProductLookup";
import {GroupNumberLookup} from "./GroupNumberLookup";
import {actionColWidth, toHwOpening} from "./HardwareGroup";
import {indentWidth} from "./HardwareTable";
import {useReload, useReloader} from "./ReloadContext";
import {ProductSearchResult} from "../../../../api/Products";
import {ShortCode} from "../../../../api/Library";
import {useSnackAction} from "./SnackAction";
import {useHwAPI} from "./HardwareGeneric";
import {useIsQuote} from "../../quote/QuoteContainer";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {useAlternative} from "../../quote/alternative/Alternative";
import {useContext, useRef, useState} from "react";
import {EditHardwareGroupWithContent, EditHardwareItem, HwEditingContext} from "./HwEditingContextProvider";
import React from "react";
import {useSnackbar} from "../../../../misc/Snackbar";
import {cellInActive} from "../pricing/cellconst";
import {ConfirmShortcodeChange} from "../../../../api/QuoteHardware";
import {ResolveShortcodeChangesDialog} from "./ResolveShortcodeChangesDialog";
import {useAsyncAction} from "nate-react-api-helpers";

export function PendingHardware(props: {}) {
    const project = useProjectId();
    const isQuote = useIsQuote();
    const isQuoteRef = useSyncedRef(isQuote)
    const alternative = useAlternative();

    const list = useGetAll(input => isQuoteRef.current ? api.quoteOpenings.list(input) : api.openings.list(input), {
        project: project,
        alternative: alternative,
        type: "have-no-hardware-group" as "have-no-hardware-group",
    }, [project, alternative]);

    useReload(list.reload, [])
    const parentOnChangeRef = useReloader();
    const hapi = useHwAPI();
    const editCtx = useContext(HwEditingContext);
    const onCreate = useSyncedRef(editCtx.onCreate);
    const [showShortcodeDetail, setShowShortcodeDetail] = useState<ConfirmShortcodeChange>();
    const lastUpdateCallbackValue = useRef<EditHardwareGroupWithContent>();

    const update = useAsyncAction(async (input: {
        value: EditHardwareGroupWithContent;
        createShortcode?: any;
        updateShortcode?: any;
        linkToShortcode?: any;
    }) => {

        lastUpdateCallbackValue.current = input.value;

        const resp = await hapi.upsert({
            value: input.value,
            createShortcode: input.createShortcode,
            updateShortcode: input.updateShortcode,
            linkToShortcode: input.linkToShortcode,
        });

        if(!resp.updated) {
            setShowShortcodeDetail(resp.confirmShortcodeChanges)
            return;
        }

        setShowShortcodeDetail(undefined)
        parentOnChangeRef.current();
    }, []);

    const updateCb = useRef(update.callback)
    updateCb.current = update.callback;

    const makeGroupAndAssignOpening = useSnackAction(async (input: {
        opening: Opening;
        groupContent: ProductSearchResult | ShortCode;
    }) => {
        const op = input.opening;
        const content = input.groupContent;
        let products: EditHardwareItem[] = toProductQty(content, op.openingType);

        if(isShortCode(content)) {
            await updateCb.current({
                value: {
                    id: 0,
                    name: "",
                    openings: [toHwOpening(op)],
                    hardware: products,
                    project: project,
                    quoteAlternative: alternative,
                },

                linkToShortcode: content.id,
            });

            return;
        }

        // need to edit the group to build shortcode before submitting
        onCreate.current({
            id: 0,
            project: project,
            quoteAlternative: alternative,
            openings: [toHwOpening(op)],
            hardware: products,
        })
    }, {
        success: "Updated",
    });

    const skipOpenings = editCtx.creating ? editCtx.creating.openings.map(o => o.id) : [];
    const snack = useSnackbar();

    return (
        <div style={{flex: 1, background: cellInActive}}>
            <div style={{fontSize: "0.9rem", fontWeight: 600, padding: 16, paddingLeft: actionColWidth + 3, paddingBottom: 4}}>Pending</div>
            {showShortcodeDetail && <ResolveShortcodeChangesDialog
                value={showShortcodeDetail}
                error={update.error}
                onCancel={() => setShowShortcodeDetail(undefined)}
                onDone={(input) => {
                    const value = lastUpdateCallbackValue.current;
                    if(!value) return;

                    update.callback({
                        value: value, // use last callback, b/c we may call update for a duplicate action which uses a different value than the "state" value.
                        createShortcode: input.createShortcode,
                        updateShortcode: input.updateShortcode,
                        linkToShortcode: input.linkToShortcode,
                    })
                }} />}
            <Loader<Opening[]|QuoteOpening[]> {...list}>
                {pending => (<table className={tableClass}>
                    <tbody>
                        {pending.length === 0 && <tr><td style={{width: actionColWidth}} /><td><i>No pending openings</i></td></tr>}
                        {pending
                            .filter(i => skipOpenings.indexOf(i.id) === -1)
                            .map((p: Opening|QuoteOpening) => <React.Fragment key={p.id}>
                            <tr key={"header" + p.id} style={{borderTop: "1px solid " + tableBorderColor, borderBottomColor: "transparent"}}>
                                <td style={{width: actionColWidth}} />
                                <td colSpan={2} style={{fontSize: "0.8rem"}}>
                                    {p.name} {p.doorElevation} Door - {p.locationOne} {p.locationTransition} {p.locationTwo} ({p.qty}) {p.hwDescriptiveName}
                                </td>
                            </tr>
                            <tr key={"add" + p.id} style={{borderColor: "transparent"}}>
                                <IconCell>
                                    <IconButton2>
                                        <AddCircleOutlineIcon fontSize="inherit" />
                                    </IconButton2>
                                </IconCell>
                                <td style={{width: indentWidth}}></td>
                                <td className="no-pad">
                                    <ProductLookup
                                        kind="hardware"
                                        placeholder="Create new group by entering Shortcode or product name"
                                        onSelect={async (result: ProductSearchResult | ShortCode) => {
                                            makeGroupAndAssignOpening.callback({
                                                opening: p,
                                                groupContent: result,
                                            })
                                        }}
                                        includeShortCodesForProject={project}
                                    />
                                </td>
                            </tr>
                            <tr className={p.id + "assign"}>
                                <IconCell>
                                    <IconButton2>
                                        <AssignmentIcon fontSize="inherit" />
                                    </IconButton2>
                                </IconCell>
                                <td></td>
                                <td className="no-pad">
                                    <GroupNumberLookup
                                        onAssign={async id => {
                                            try {
                                                snack.loading();

                                                await hapi.updateHardwareGroupLink({
                                                    group: id,
                                                    opening: p.id,
                                                    link: true,
                                                })

                                                snack.success("Updated");
                                                parentOnChangeRef.current();
                                            } catch(e: any) {
                                                snack.error(e.message);
                                                parentOnChangeRef.current();
                                            }
                                        }}
                                        onCreate={name => {
                                            editCtx.onCreate({
                                                id: 0,
                                                project: project,
                                                quoteAlternative: alternative,
                                                openings: [p],
                                                hardware: [],
                                                name: name,
                                            })
                                        }} />
                                </td>
                            </tr>
                        </React.Fragment>)}
                    </tbody>
                </table>)}
            </Loader>
        </div>
    )
}

export function toProductQty(content: ShortCode | ProductSearchResult, openingType?: string): EditHardwareItem[] {

    if(isShortCode(content)) {
        const bothPair = content.openingType === "Pair" && openingType === "Pair";
        const singleToPair =  content.openingType === "Single" && openingType === "Pair";
        const pairToSingle = content.openingType === "Pair" && openingType === "Single";

        return (content.items||[]).map(i => {
            let common = 0;
            let active = 0;
            let inactive = 0;

            if(i.defaultHwGroup === "common") {
                common = i.qty;
            } else if(i.defaultHwGroup === "each-door") {
                if(bothPair) {
                    active = Math.round(i.qty/2);
                    inactive = active;
                } else if(singleToPair) {
                    active = i.qty;
                    inactive = active;
                } else if(pairToSingle) {
                    active = Math.ceil(i.qty / 2);
                    inactive = 0;
                } else {
                    active = i.qty;

                    if(openingType === "Pair" || !openingType) {
                        inactive = active;
                    }
                }

            } else if(i.defaultHwGroup === "active") {
                active = i.qty;
            } else if(i.defaultHwGroup === "inactive") {
                inactive = i.qty;
            }

            return ({
                product: i.product,
                finish: i.finish,
                finishName: "",
                name: i.productName,
                manufacturer: i.manufacturer,
                manufacturerName: i.manufacturerName,
                manufacturerShortName: "",

                productCode: i.productCode,
                dimensions: "",
                productIsAutoQtyHinge: i.productIsAutoQtyHinge,

                activeQty: active,
                inactiveQty: inactive,
                commonQty: common,
                calcTotalQty: 0,
                calcByDimension: [],

                overrideHingeActiveQty: null,
                overrideHingeInActiveQty: null,

                shortcodeItem: i.id,

                customerSupplied: false,

                archived: false,
                isCustom: false,
                note: "",
            })
        })
    } else {
        const active = content.defaultHwGroup === "active" || content.defaultHwGroup === "each-door" ? 1 : 0
        const inactive = content.defaultHwGroup === "inactive" || content.defaultHwGroup === "each-door" ? 1 : 0
        const common = content.defaultHwGroup === "common" ? 1 : 0

        return [{

            product: content.id,
            finish: content.finish,
            finishName: content.finishName,
            name: content.categoryName,

            manufacturer: content.manufacturer,
            manufacturerName: content.manufacturerName,
            manufacturerShortName: "",

            productCode: content.productCode,
            dimensions: content.dimensions,
            productIsAutoQtyHinge: content.isAutoQtyHinge,

            customerSupplied: false,

            activeQty: active,
            inactiveQty: inactive,
            commonQty: common,

            calcTotalQty: 0,
            calcByDimension: [],
            overrideHingeActiveQty: null,
            overrideHingeInActiveQty: null,

            note: "",
            archived: false,
            isCustom: false,
        }]
    }
}

export function isShortCode(input: any): input is ShortCode {
    return typeof input === "object" && input.hasOwnProperty("id") && input.hasOwnProperty("value") && input.hasOwnProperty("library")
}