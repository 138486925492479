import {api} from "../../../api/API";
import {DashCardData} from "./DashCardData";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {distinct, groupByArr} from "nate-react-api-helpers";

export function DeliveriesToBuild() {
    return (
        <DashCardData
            lookup={async () => {
                let all = await getAll({cancelled: false}, offset => api.logistics.partsToDeliver({offset: offset}));
                const rows = groupByArr(all, a => a.projectName).map(g => ({
                    projectName: g.values[0].projectName,
                    manufacturers: distinct(g.values, m => m.manufacturerName),
                    itemCount: g.values.reduce((acc, v) => acc + v.qty, 0),
                }));

                return {
                    data: rows,
                    count: rows.length,
                }
            }}
            title="Deliveries to Draft"
            itemTitle={d => d.projectName}
            itemSubtext={d => d.manufacturers.length === 1 ? "From " + d.manufacturers[0] : "From multiple suppliers"}
            itemRight={d => d.itemCount + " items"}
            link="/logistics/deliveries"
        />
    )
}

